import { connect, mapStateToProps, ShowImage, SpinnerLoadFirst } from '../../helpers/Common'
import IgIcon from '../../assets/images/calendar/instagram-icon.svg'
import TwIcon from '../../assets/images/calendar/twitter-icon.svg'
import FbIcon from '../../assets/images/calendar/facebook-icon.svg'
import YtIcon from '../../assets/images/calendar/youtube-icon.svg'
import WaIcon from '../../assets/images/calendar/whatsapp-icon.svg'
import EmailIcon from '../../assets/images/calendar/email-icon.svg'
import Calendar from './Calendar'
import LogoDark from '../../assets/images/logo/dark.svg'
import { useEffect, useState } from 'react'
import headerBg1 from '../../assets/images/calendar/header-bg-kalendar-1.jpg'
import headerBg2 from '../../assets/images/calendar/header-bg-kalendar-2.jpg'
import headerBg3 from '../../assets/images/calendar/header-bg-kalendar-3.jpg'
import headerBg4 from '../../assets/images/calendar/header-bg-kalendar-4.jpg'

const sosialMedia = [
  {
    name: 'instagram',
    icon: IgIcon
  },
  {
    name: 'twitter',
    icon: TwIcon
  },
  {
    name: 'facebook',
    icon: FbIcon
  },
  {
    name: 'youtube',
    icon: YtIcon
  },
  {
    name: 'phone',
    icon: WaIcon
  },
  {
    name: 'email',
    icon: EmailIcon
  }
]

const providedHeaderBgSrcs = {
  l1: `url("${headerBg1}") center/cover no-repeat`,
  l2: `url("${headerBg2}") center/cover no-repeat`,
  l3: `url("${headerBg3}") center/cover no-repeat`,
  l4: `url("${headerBg4}") center/cover no-repeat`
}

const CalendarPaperComponent = ({ redux, control, holiday, currentYear, uploadedHeaderBgUrl, currentHeaderBgId, data }) => {
  const [bgProperties, setBgProperties] = useState({
    height: 370,
    background: currentHeaderBgId
      ? currentHeaderBgId === 'u1'
          ? `url("${uploadedHeaderBgUrl}") center/cover no-repeat` // use uploaded image
          : providedHeaderBgSrcs[currentHeaderBgId] // use lokal image
      : providedHeaderBgSrcs.l2, // l2/headerBg2 is default
    position: 'relative'
  })

  useEffect(() => {
    setBgProperties(currBgProperties => ({
      height: 370,
      background: currentHeaderBgId
        ? currentHeaderBgId === 'u1'
            ? `url("${uploadedHeaderBgUrl}") center/cover no-repeat` // use uploaded image
            : providedHeaderBgSrcs[currentHeaderBgId] // use lokal image
        : providedHeaderBgSrcs.l2, // l2/headerBg2 is default
      position: 'relative'
    }))
  }, [currentHeaderBgId, uploadedHeaderBgUrl])

  return (
    <div className='px-3 px-sm-0'>
      <div className='overflow-auto'>
        <div id='section-to-print'>
          <div className='pagePrint'>
            <div className='pagePrintContainer bg-white overflow-hidden position-relative' style={{ width: 1000 }}>
              {control.showLoad && (
                <div className='position-absolute h-100 d-flex align-items-center justify-content-center rounded p-4' style={{ width: '21cm', zIndex: 3, backgroundColor: 'rgba(52, 52, 52, 0.1)' }}>
                  {SpinnerLoadFirst}
                </div>
              )}

              <div className='pagePrintHeader p-0'>
                <div
                  style={{ ...bgProperties }}
                  className='pt-4'
                >
                  {/* company id */}
                  <div style={{ width: 367, borderTopRightRadius: '50rem', borderBottomRightRadius: '50rem' }} className='d-flex bg-white p-3 pr-4'>
                    <div className='mr-3'>
                      <ShowImage style={{ width: 50 }} url={data?.logo_url?.m} alt='logo' />
                    </div>
                    <div>
                      <h6 className='pr-1 text-dark'>{data?.name}</h6>
                      <address style={{ lineHeight: 1.3, fontSize: 10, color: '#000' }} className='m-0'>{data?.address}</address>
                    </div>
                  </div>
                  {/* social media */}
                  <div
                    style={{
                      borderTopRightRadius: 10,
                      background: 'rgba(255, 255, 255, 0.70)',
                      backdropFilter: 'blur(5px)',
                      maxWidth: 370,
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      fontSize: 8
                    }}
                  >
                    <div className='mx-3 pt-2 d-flex flex-wrap'>
                      {sosialMedia.map((sm, idx) => {
                        if (!data[sm.name]) return null

                        return (
                          <div key={idx} className='d-flex mr-3 mb-2 align-items-center'>
                            <img src={sm.icon} alt='icon' style={{ width: 12 }} className='mr-1' />
                            <span className='d-inline-block' style={{ color: '#000' }}>{data[sm.name]}</span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  {/* year */}
                  <div style={{
                    borderTopLeftRadius: 10,
                    background: 'rgba(255, 255, 255, 0.10)',
                    backdropFilter: 'blur(10px)',
                    position: 'absolute',
                    bottom: 0,
                    right: 0
                  }}
                  >
                    <div style={{ fontSize: 86, lineHeight: 1, textShadow: '4px -1px #454545, 5px -1px #ffffff' }} className='text-white mt-2 mr-2'>{currentYear}</div>
                  </div>
                </div>
              </div>

              <div className='pagePrintBody p-0'>
                <div className='my-4 p-3'>
                  <Calendar holiday={holiday} currentYear={currentYear} />
                </div>

                <div className='p-3 text-center' style={{ background: '#C8D9E1', fontSize: 10, color: '#000' }}>
                  <img src={LogoDark} style={{ maxHeight: 14 }} alt='Logo Kerjo' />
                  {' '} - Aplikasi Absensi Online
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(CalendarPaperComponent)
