import { LangApplicableTo, LangSpesificDivision, LangSpesificEmployee2, LangSpesificPosition, LangTargetType } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const ApprovalRulesTarget = ({ redux, val }) => {

  const displayType = (item) => {
    switch (true) {
      case item === 'specific_group': return LangSpesificDivision
      case item === 'specific_position': return LangSpesificPosition
      case item === 'specific_employee': return LangSpesificEmployee2
    }
  }
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle p-2'>{LangTargetType}</th>
            <th scope='col' className='text-center align-middle p-2'>{LangApplicableTo}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>{displayType(item?.type)}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>
                {item?.value_details?.group?.length !== 0 && item?.value_details?.group?.join(', ')}
                {item?.value_details?.position?.length !== 0 && item?.value_details?.position?.join(', ')}
                {item?.value_details?.name?.length !== 0 && item?.value_details?.name?.join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(ApprovalRulesTarget)
