import { useState, useEffect } from 'react'
import { LangAction, LangDelete, LangExpiredIn, LangName, Edit, LangAdd, LangAssignEmployee, LangBalance, LangClaimHistories, SelectDivision, Search, LangImportExcel, LangExportExcel, LangYes, LangNo, LangImportExcelClaimEmployee, LangEmployeesApplied, LangClaimType, LangSetting, LangClaim } from '../../constant/languange'
import { PaginationDiv, SpinnerButton, SpinnerLoadFirst, axiosCompany, connect, cookies, loadDivisionOptions, mapDispatchToProps, mapStateToProps, toRupiah } from '../../helpers/Common'
// import ReimbursementBreadcrumbs from '../../layout/breadcrumb/reimbursement'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import { faFileDownload, faFileUpload, faHistory, faPencilAlt, faSearch, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTooltip from 'react-tooltip'
import { AsyncSelectInput, ModalApp } from '../../helpers/Input'
import ReimbursementClaimHistoriesComponent from './ReimbursementClaimHistoriesComponent'
import { useParams } from 'react-router-dom'
import PersonnelItemComponent from '../personnel/PersonnelItemComponent'
import ModalDelete from '../../helpers/Modal'
import ReimbursementAddEmployeeComponent from './ReimbursementAddEmployeeComponent'
import moment from 'moment'
import ReimbursementEditEmployeeComponent from './ReimbursementEditEmployeeComponent'
import ReimbursementExportEmployeeComponent from './ReimbursementExportEmployeeComponent'
import ReimbursementImportFormatEmployeeComponent from './ReimbursementImportFormatEmployeeComponent'
import ReimbursementImportEmployeeComponent from './ReimbursementImportEmployeeComponent'

const ReimbursementEmployeesComponent = ({ redux }) => {
  const params = useParams()
  // state
  const [control, setControl] = useState({ showModal: '', showLoad: false, onSubmitDelete: false })
  const [filter, setFilter] = useState({
    page: 1,
    group_id: '',
    keyword: ''
  })
  const [data, setData] = useState({})
  const [focusData, setFocusData] = useState({})
  const [reimbursementDetail, setReimbursementDetail] = useState({})

  // effect
  useEffect(() => {
    getListService()
    // eslint-disable-next-line
  }, [])

  // funtion
  const getListService = async() => {
    setControl(currControl => ({ ...currControl, showLoad: true }))
    await getReimbursementDetail()
    await axiosCompany().get(`/reimbursements/${params.id}/personnels`, { params: filter })
      .then((res) => {
        setData(curData => res.data)
      })
      .finally(() => {
        setControl(currControl => ({ ...currControl, showLoad: false }))
      })
  }

  const getReimbursementDetail = () => {
    axiosCompany().get(`/reimbursements/${params.id}`)
      .then((res) => {
        setReimbursementDetail(res.data)
      })
  }

  const openModal = (modal, data) => {
    setControl(control => ({ ...control, showModal: modal }))
    setFocusData(currFocusData => data)
  }

  // handler
  const handleDelete = () => {
    setControl(currControl => ({ ...currControl, onSubmitDelete: true }))
    axiosCompany().delete(`/reimbursements/${params.id}/personnels/${focusData.personnel_id}`)
      .then(res => {
        getListService()
        setControl(currControl => ({ ...currControl, onSubmitDelete: false, showModal: '' }))
      })
      .catch(err => {
        setControl(currControl => ({ ...currControl, onSubmitDelete: false }))
      })
  }

  const handlePage = (page) => {
    filter.page = page
    getListService()
  }

  const handleGroupChange = (payload) => {
    filter.page = 1
    filter.group_id = payload?.value ?? ''
    getListService()
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      filter.page = 1
      getListService()
    }
  }

  const handleSearch = () => {
    filter.page = 1
    getListService()
  }

  return (
    <div>
      <div>
        {/* <ReimbursementBreadcrumbs title={LangEmployeesApplied} routeType='claim' subRoutes={['type', 'employee']} /> */}
        {
          cookies.get('menu-version') === '2' ?
            <BreadcrumbsMenu title={LangEmployeesApplied}>{[LangSetting, LangClaimType, LangEmployeesApplied]}</BreadcrumbsMenu>
            :
            <BreadcrumbsMenu title={LangEmployeesApplied}>{[LangClaim, LangClaimType, LangEmployeesApplied]}</BreadcrumbsMenu>
        }
      </div>

      <div className='container-fluid '>
        <div className='row'>
          <div className='col'>
            <div className='card'>
              <div className='card-header'>
                <h6 className='mb-3'>{LangClaimType}: {reimbursementDetail?.name}</h6>
                <div className='d-flex flex-wrap'>
                  <div className='d-flex flex-wrap mr-md-auto'>
                    <button className='btn btn-primary px-3 mr-2 mb-2' data-tip={LangAssignEmployee} onClick={() => { setControl(control => ({ ...control, showModal: 'add' })) }}><FontAwesomeIcon icon={faUsers} /></button>
                    <div style={{ width: 230 }} className='form-control p-0 border-0 mr-2 mb-2'>
                      <AsyncSelectInput
                        isLoading={false}
                        isClearable
                        placeholder={SelectDivision}
                        loadOptions={loadDivisionOptions}
                        value={filter.target}
                        onChange={(data) => { handleGroupChange(data) }}
                      />
                    </div>
                    <div className='mr-2 mb-2'>
                      <div className='input-group d-flex flex-nowrap'>
                        <input style={{ width: 189 }} type='search' className='form-control' placeholder={Search} value={filter.keyword} onKeyDown={handleKeydown} onChange={(e) => { setFilter(currFilter => ({ ...currFilter, keyword: e.target.value })) }} />
                        <div className='input-group-append' disabled={control.showLoad} id='button-addon2' onClick={handleSearch}>
                          <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>
                            {control.showLoad ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <ReactTooltip place='bottom' />
                  </div>
                  <div className='d-flex'>
                    <button className='btn btn-primary px-3 mr-2 mb-2' data-tip={LangImportExcel} onClick={() => { setControl(control => ({ ...control, showModal: 'import' })) }}><FontAwesomeIcon icon={faFileDownload} /></button>
                    <button className='btn btn-primary px-3 mr-2 mb-2' data-tip={LangExportExcel} onClick={() => { setControl(control => ({ ...control, showModal: 'export' })) }}><FontAwesomeIcon icon={faFileUpload} /></button>
                  </div>
                </div>
              </div>
              <>
                {control.showLoad
                  ? SpinnerLoadFirst
                  : (
                    <div className='table-responsive'>
                      <table class='table table-bordered table-striped'>
                        <thead>
                          <tr>
                            <th className='text-center align-middle'>{LangName}</th>
                            <th className='text-center align-middle'>{LangExpiredIn}</th>
                            <th className='text-center align-middle'>{LangBalance} (Rp)</th>
                            <th className='text-center align-middle' style={{ width: 140, minWidth: 140 }}>{LangAction}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.data?.map((item, idx) => (
                            <tr key={idx}>
                              {/* kolom nama sementara nama saja */}
                              <td scope='row'><PersonnelItemComponent data={item.personnel} key={'personnel-' + item.id} /></td>
                              <td>{item.expired_in ? moment(item.expired_in, 'YYYY-MM-DD').format('DD MMM YYYY') : 'Unlimited'}</td>
                              <td>{toRupiah(item.balance ?? '')}</td>
                              <td>
                                <a style={{ cursor: 'pointer' }} data-tip={Edit} className={`btn-icon btn-sm mr-2 mb-2 ${redux.darkModeOn ? 'btn-dark' : 'btn-light'}`} onClick={() => { openModal('edit', item) }}><FontAwesomeIcon icon={faPencilAlt} /></a>
                                <a style={{ cursor: 'pointer' }} data-tip={LangDelete} className={`btn-danger btn-icon btn-sm mr-2 mb-2`} onClick={() => { openModal('delete', item) }}><FontAwesomeIcon icon={faTrash} /></a>
                                <a style={{ cursor: 'pointer' }} data-tip={LangClaimHistories} className={`btn-icon btn-sm mr-2 mb-2 ${redux.darkModeOn ? 'btn-dark' : 'btn-light'}`} onClick={() => { openModal('claim-histories', item) }}><FontAwesomeIcon icon={faHistory} /></a>
                                <ReactTooltip place='bottom' />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>)}
              </>
              <div className='card-footer'>
                <PaginationDiv data={data} onChange={handlePage} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {(control.showModal === 'import') && (
        <ModalApp
          title={LangImportExcel}
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <ReimbursementImportEmployeeComponent
              handleDownloadFormat={() => { setControl(control => ({ ...control, showModal: 'import-format' })) }}
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
              onAfterImport={() => { getListService() }}
            />
          }
        />
      )}

      {(control.showModal === 'import-format') && (
        <ModalApp
          title={LangImportExcelClaimEmployee}
          size='md'
          handleClose={() => { setControl(control => ({ ...control, showModal: 'import' })) }}
          body={
            <ReimbursementImportFormatEmployeeComponent
              handleGroupChange={handleGroupChange}
              groupId={filter.group_id}
              handleClose={() => { setControl(control => ({ ...control, showModal: 'import' })) }}
            />
          }
        />
      )}

      {(control.showModal === 'export') && (
        <ModalApp
          title={LangExportExcel}
          size='md'
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <ReimbursementExportEmployeeComponent
              handleGroupChange={handleGroupChange}
              groupId={filter.group_id}
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
            />
          }
        />
      )}

      {(control.showModal === 'claim-histories') && (
        <ModalApp
          title={LangClaimHistories}
          size='xl'
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <ReimbursementClaimHistoriesComponent
              currentData={focusData}
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
            />
          }
        />
      )}

      {(control.showModal === 'add') && (
        <ModalApp
          title={LangAdd}
          size='md'
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <ReimbursementAddEmployeeComponent
              onAfterAdd={() => {
                setControl(control => ({ ...control, showModal: '' }))
                getListService()
              }}
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
              assignedData={data.data.map(i => ({ label: i.personnel.name + ((i.personnel.group) ? ` (${i.personnel.group.name})` : ''), value: i.personnel.id }))}
            />
          }
        />
      )}

      {(control.showModal === 'edit') && (
        <ModalApp
          title={Edit}
          size='md'
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <ReimbursementEditEmployeeComponent
              currentData={focusData}
              onAfterEdit={() => { getListService() }}
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
            />
          }
        />
      )}

      <ModalDelete
        show={control.showModal === 'delete'}
        onHide={() => setControl(control => ({ ...control, showModal: '' }))}
        closeButton={() => setControl(control => ({ ...control, showModal: '' }))}
        btn={
          <>
            <button disabled={control.onSubmitDelete} className='btn  btn-danger mr-2' type='button' onClick={handleDelete}> {(control.onSubmitDelete) ? SpinnerButton : ''} {LangYes}</button>
            <button disabled={control.onSubmitDelete} className='btn btn-primary mr-2' type='button' onClick={() => setControl(control => ({ ...control, showModal: '' }))}>{LangNo}</button>
          </>
        }
      />
    </div >

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementEmployeesComponent)
