import React, { useEffect, useState } from 'react'

import { mapStateToProps, connect, axiosCompany, SpinnerLoadFirst, useHistory, cookies } from '../../helpers/Common'
import { LangPaymentCloseModal, LangPaymentSuccess } from '../../constant/languange'
// import OrderSummaryContentComponent from './OrderSummaryContentComponent'

function OrderSummaryComponent ({ redux, data, orderText, orderType = '', setCloseModal, ...props }) {
  // eslint-disable-next-line
  const [state, setState] = useState({ total: 0, unique_number: 0, unique_total: 0 })
  // eslint-disable-next-line
  const [errors, setError] = useState({ fields: {}, message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false })
  const history = useHistory()

  useEffect(() => {
    const total = data.total

    /// **
    // * atur unix code
    // */
    // let unixCode = +redux.profile.website_id
    // if (unixCode > 1000) {
    //  /**
    //   * ambil 3 digit terakhir
    //   */
    //  const unixCodeLength = unixCode.toString().length
    //  unixCode = parseInt(unixCode.toString().substring(unixCodeLength - 3, unixCodeLength))
    // }

    /**
     * update, kode unik sepenuhnya dihandle backend
     */
    const unixCode = 0

    setState(state => ({
      ...state,
      total: (+total > unixCode) ? total - unixCode : total
    }))

    /**
     * panggil api new order
     */
    switch (orderType) {
      case 'spot-quota':
        postSpotQuotaService(total, unixCode)
        break
      case 'broadcast':
        postBroadcastQuotaService(total, unixCode)
        break
      case 'client-visit':
        postClientDistanceQuotaService(total, unixCode)
        break
      default:
        postQuotaService(total, unixCode)
        break
    }
    // eslint-disable-next-line
  }, [])

  const showSnap = (token) => {
    /**
     * close modal
     */
    setCloseModal()

    window.snap.pay(token, {
      language: cookies.get('lang') || 'id',
      onSuccess: function (result) {
        // {
        //    "status_code": "200",
        //    "status_message": "Success, Credit Card capture transaction is successful",
        //    "transaction_id": "d07d6f8a-ace7-4335-9468-0181f49c8f26",
        //    "order_id": "krj-99cf4ee5-f353-4fea-b8de-0718e5e8fc89",
        //    "gross_amount": "161249.00",
        //    "payment_type": "credit_card",
        //    "transaction_time": "2023-11-24 14:26:12",
        //    "transaction_status": "capture",
        //    "fraud_status": "accept",
        //    "bank": "bni",
        //    "masked_card": "48111111-1114",
        //    "card_type": "credit",
        //    "finish_redirect_url": "?order_id=krj-99cf4ee5-f353-4fea-b8de-0718e5e8fc89&status_code=200&transaction_status=capture"
        // }

        /* You may add your own implementation here */
        // alert('payment success!'); console.log(result)

        alert(LangPaymentSuccess)

        /**
         * refresh
         */
        history.go(0)
      },
      onPending: function (result) {
        /* You may add your own implementation here */
        // alert('wating your payment!'); console.log(result)
      },
      onError: function (result) {
        /* You may add your own implementation here */
        // alert('payment failed!'); console.log(result)
      },
      onClose: function () {
        /* You may add your own implementation here */
        alert(LangPaymentCloseModal)
      }
    })
  }

  const postSpotQuotaService = (total, unixCode) => {
    setControl({ ...control, showLoad: true })
    axiosCompany().post('/company/subscribe/additional-quota-spot', {
      ...data,
      total: (+total > unixCode) ? total - unixCode : total
    }).then(ress => {
      if (ress.data.snap_token) {
        showSnap(ress.data.snap_token)
      }

      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setState(currState => ({ ...currState, ...ress.data }))
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const postBroadcastQuotaService = (total, unixCode) => {
    setControl({ ...control, showLoad: true })
    axiosCompany().post('/company/subscribe/add-quota-broadcast', {
      ...data,
      total: (+total > unixCode) ? total - unixCode : total
    }).then(ress => {
      if (ress.data.snap_token) {
        showSnap(ress.data.snap_token)
      }

      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setState(currState => ({ ...currState, ...ress.data }))
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const postClientDistanceQuotaService = (total, unixCode) => {
    setControl({ ...control, showLoad: true })
    axiosCompany().post('company/subscribe/add-quota-distance-client-visit', {
      ...data,
      total: (+total > unixCode) ? total - unixCode : total
    }).then(ress => {
      if (ress.data.snap_token) {
        showSnap(ress.data.snap_token)
      }

      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setState(currState => ({ ...currState, ...ress.data }))
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const postQuotaService = (total, unixCode) => {
    setControl({ ...control, showLoad: true })

    axiosCompany().post('/company/subscribe/add-quota-tracking', {
      ...data,
      total: (+total > unixCode) ? total - unixCode : total
    }).then(ress => {
      if (ress.data.snap_token) {
        showSnap(ress.data.snap_token)
      }

      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setState(currState => ({ ...currState, ...ress.data }))
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  return <>{SpinnerLoadFirst}</>

  // return (
  //   <div>
  //     <OrderSummaryContentComponent data={state} name={redux.profile.name} orderText={orderText} />
  //     {(errors.message !== '') ? <div className='alert alert-danger'> <p style={{ fontSize: 18, textAlign: 'center' }}>{errors.message}</p></div> : null}
  //   </div>
  // )
}

export default connect(mapStateToProps)(OrderSummaryComponent)
