import React, { useState, useEffect } from 'react'

import CustomModal from '../../helpers/CustomModal'
import expiresInfoImage from '../../assets/images/expires-info/expire-modal-image.png'
import { connect, mapStateToProps, mapDispatchToProps, calculateTimeRemaining } from '../../helpers/Common'
import { LangExpiresModal, LangOkLater, LangRenewNow, isLangEN } from '../../constant/languange'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const ExpiresModalComponent = ({ redux }) => {
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)
  const [timeRemaining, setDayRemaining] = useState(null)

  // function
  const calulateDaysRemaining = () => {
    const expiredDate = moment(redux.expiredAt, 'YYYY-MM-DD HH:mm:ss')
    const currentDate = moment()
    return expiredDate.diff(currentDate, 'days')
  }

  // effect
  useEffect(() => {
    setDayRemaining(currTimeRemaining => {
      // contoh nilai redux.expiredAt: '2024-05-16 15:33:49'
      return calculateTimeRemaining(redux.expiredAt, isLangEN)
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShowModal((currShowModal) => {
        const daysRemaining = calulateDaysRemaining()
        const clickedDays = +window.localStorage.getItem('expiresModalClickedDays')

        const clickedBefore = daysRemaining === clickedDays
        return timeRemaining && !clickedBefore
      })
    }, 4000)
  }, [timeRemaining, redux.expiredAt])

  // handler
  const handleClose = () => {
    const daysRemaining = calulateDaysRemaining()
    window.localStorage.setItem('expiresModalClickedDays', daysRemaining)

    document.body.style.overflow = 'auto'
    setShowModal(false)
  }

  const handleClickRenew = () => {
    handleClose()
    history.push('/subscribe')
  }

  const content = (
    <div className={`${redux.darkModeOn ? 'bg-dark' : 'bg-white'} px-3 py-5 px-md-5 shadow position-relative mx-auto`} style={{ borderRadius: '20px', maxWidth: '560px', boxSizing: 'border-box !important', zIndex: '10000' }}>
      <div className='d-flex flex-column align-items-center my-md-3'>
        <img style={{ maxWidth: '333px', marginBottom: '40px' }} className='w-100' src={expiresInfoImage} alt='expires information' />
        <p className='text-center' style={{ fontSize: '20px', lineHeight: '26.4px', marginBottom: '48px' }}>{LangExpiresModal(timeRemaining, moment(redux.expiredAt, 'YYYY-MM-DD HH:mm:ss').format('dddd, D MMMM YYYY'))}</p>
        <div style={{ gap: 12 }} className='w-100 d-flex justify-content-center flex-wrap'>
          <button onClick={handleClose} style={{ fontWeight: '500', fontSize: '20px', lineHeight: '25.2px' }} className='btn rounded-pill btn-outline-primary d-flex flex-nowrap align-items-center text-nowrap px-3'>{LangOkLater}</button>
          <button onClick={handleClickRenew} style={{ fontWeight: '500', fontSize: '20px', lineHeight: '25.2px' }} className='btn rounded-pill btn-primary d-flex flex-nowrap align-items-center text-nowrap px-3'>{LangRenewNow}</button>
        </div>
      </div>
    </div>
  )

  return !redux.isTrial && showModal && <CustomModal content={content} />
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpiresModalComponent)
