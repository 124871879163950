import React from 'react'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  axiosCompany,
  axiosDownloadFile
} from '../../helpers/Common'
import {
  Division,
  LangRequestSuccess,
  DateLang,
  LangMaxRangeExport60Day,
  Process,
  Close,
  Search,
  LangKeyword
} from '../../constant/languange'
import { InputRangeDatePicker } from '../../helpers/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

class ShiftChangeScheduleExportComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      onSubmit: false,
      errors: {},
      errorMessage: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, errorMessage: '', successMessage: '' })

    const formData = new window.FormData()
    formData.append('date_from', this.props.startDate)
    formData.append('date_to', this.props.endDate)
    if (this.props.groupId) {
      formData.append('group_id', this.props.groupId)
    }
    if (this.props.status) {
      formData.append('status', this.props.status)
    }
    if (this.props.search) {
      formData.append('keyword', this.props.search)
    }

    axiosCompany().post('switch-shifts/export', formData, { responseType: 'blob' })
      .then((res) => {
        const fileName = `export-recap-shift-schedule-${moment().format('YYYYMMDDHHmmss')}`
        axiosDownloadFile(res.data, fileName, 'xlsx')

        this.setState({
          onSubmit: false,
          successMessage: LangRequestSuccess
        })
      }).catch((error) => {
        if (error.response.data.errors) {
          this.setState({ errors: error.response.data.errors })
        } else {
          this.setState({ errors: { 0: [error.response.statusText] } })
        }

        if (error.response.data.message) {
          this.setState({ errorMessage: error.response.data.message })
        }

        this.setState({ onSubmit: false })
      })
  }

  handleStatus = (e) => {
    this.setState({ status: e })
  }

  render () {
    const s = this.state
    const p = this.props
    return (
      <form onSubmit={this.handleSubmit}>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangKeyword}</label>
          <div className='col-sm-8'>
            <div className='input-group'>
              <input
                type='search' className='form-control' placeholder={Search} onKeyDown={p.handleKeyDown} value={p.search} onChange={p.handleChangeSearch} aria-label='Search'
                aria-describedby='search-addon'
              />
              <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={p.handleClickSearch}><span className={`input-group-text ${p.redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}><i> {p.loading2 ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />} </i></span></div>
            </div>
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{DateLang} <b className='text-danger'>*</b></label>
          <div className='col-sm-9'>
            <InputRangeDatePicker
              startDate={p.startDate}
              endDate={p.endDate}
              onChange={p.handleDateChange}
            />
            <div className={`alert ${p.redux.darkModeOn ? 'alert-dark' : 'alert-light'} mt-2 mb-2`}>
              {LangMaxRangeExport60Day}
            </div>

            {(s.errors.date_from) ? <div className='invalid-feedback d-block'><strong>{s.errors.date_from}</strong></div> : null}
            {(s.errors.date_to) ? <div className='invalid-feedback d-block'><strong>{s.errors.date_to}</strong></div> : null}
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{Division}</label>
          <div className='col-sm-8'>
            <select className='form-control' value={p.groupId} onChange={p.handleChangeGroup}>
              <option value=''>--{Division}--</option>
              {p.redux.groups.map((obj) => {
                if (obj.work_system === 2) {
                  return (
                    <option key={obj.id} value={obj.id}>{obj.name}</option>
                  )
                }

                return null
              })}
            </select>
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>Status</label>
          <div className='col-sm-8'>
            <select className='form-control' value={p.status} onChange={p.handleChangeStatus}>
              <option value=''>--Status--</option>
              {p.dataStatus.map((status) => {
                return (
                  <option key={status.value} value={status.value}>{status.name}</option>
                )
              })}
            </select>
          </div>
        </div>

        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            {s.errorMessage ? <div className='alert alert-danger'>{s.errorMessage}</div> : null}
            {s.successMessage ? <div className='alert alert-success'>{s.successMessage}</div> : null}
            <button disabled={s.onSubmit} type='submit' className='btn btn-primary'>{(s.onSubmit) ? SpinnerButton : null} {Process}</button>
            &nbsp;
            <button onClick={p.handleClose} disabled={s.onSubmit} type='button' className='btn btn-light'>{Close}</button>
          </div>
        </div>
      </form>
    )
  }
}
export default connect(mapStateToProps)(ShiftChangeScheduleExportComponent)
