import React from 'react'
import { Link, connect, displayDate, mapStateToProps } from '../../helpers/Common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const VisitSummaryViewDatesComponent = ({ data, id, redux }) => {
  if (!data) return null

  const formattedData = []

  // Iterate through the data object
  for (const dateKey in data) {
    const clients = data[dateKey]
    formattedData.push({ date: dateKey, clients: clients })
  }

  return (
    <>
      <div style={{ maxHeight: '200px', overflow: 'auto' }}>
        <ul className='list-group list-group-flush'>
          {formattedData.map((item, index) => (
            <Link to={`/client-visit?date=${displayDate(item.date, 'YYYY-MM-DD')}&keyword=personnel_id:${id}`} className={`list-group-item list-group-item-action ${redux.darkModeOn ? 'bg-dark text-light' : ''}`} key={index}>
              <div className='d-flex flex-row'>
                <div className='text-right mr-2' style={{ width: '20px' }}>{index + 1}.</div>
                <div>
                  {displayDate(item.date, 'ddd, DD MMM YYYY')} <br />
                  {item.clients && item.clients.length > 0 && (
                    <div className='d-flex'>
                      <label className='pr-1'>Klien:</label>
                      <div>
                        {item.clients.map((item, index) => (
                          <span className='badge badge-warning ml-0 mr-1 mb-1' key={index}>{item.client_name}</span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className='ml-auto'>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              </div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(VisitSummaryViewDatesComponent)
