import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Menu } from 'react-feather'
const Leftbar = (props) => {
  // const [levelMenu, setLevelMenu] = useState(false)
  const [sidebartoggle, setSidebartoggle] = useState(true)
  const width = useWindowSize()

  function useWindowSize () {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize () {
        setSize(window.innerWidth)
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size
  }

  useEffect(() => {
    const ignoreClick_On_Out_side_Element = document.getElementById('out_side_click')
    const ignoreClick_On_Main_Nav_Element = document.getElementById('sidebar-menu')
    document.addEventListener('click', function (event) {
      const isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(event.target)
      const isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(event.target)
      if (window.innerWidth <= 991 && !isClickOutSideElement && !isClickMainNavElement) {
        // Do something click is outside specified element
        document.querySelector('.page-header').className = 'page-header close_icon'
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon '
      }
    })
  }, [width])

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      document.querySelector('.page-header').className = 'page-header'
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper '
    } else {
      if (toggle) {
        setSidebartoggle(!toggle)
        document.querySelector('.page-header').className = 'page-header close_icon'
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon '
        document.querySelector('.mega-menu-container').classList.remove('d-block')
      } else {
        setSidebartoggle(!toggle)
        document.querySelector('.page-header').className = 'page-header'
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper '
      }
    }
  }

  return (
    <>
      <div className='header-logo-wrapper' id='out_side_click'>
        <div className='toggle-sidebar' onClick={() => responsive_openCloseSidebar(sidebartoggle)} style={window.innerWidth <= 991 ? { display: 'block' } : { display: 'none' }}>
          <Menu className='status_toggle middle sidebar-toggle' id='sidebar-toggle' />
        </div>
      </div>
      {/* <Col className='left-header horizontal-wrapper pl-0 d-none d-md-block' /> */}
    </>
  )
}

export default Leftbar
