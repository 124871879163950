import React from 'react'

import { LangNext, LangPrev } from '../../constant/languange'
import { connect, mapStateToProps, mapDispatchToProps } from '../../helpers/Common'

const NavButton = (props) => {
  if (props.type === 'next') {
    return (
      <>
        {/* <div style={{ content: ' ', display: 'block', height: '24px' }} /> */}
        <a type='button' className='btn px-2 btn-sm btn-primary rounded' style={{ cursor: props.currentStep === 5 ? 'default' : 'pointer' }} onClick={props.onClick}>{props.currentStep === (props.paths[props.redux.tourName].length - 1) ? 'Ok' : LangNext}</a>
      </>
    )
  }
  if (props.type === 'prev') {
    return (
      <>
        {/* <div style={{ content: ' ', display: 'block', height: '24px' }} /> */}
        <a type='button' className={`btn px-2 btn-sm btn-outline-primary rounded ${props.currentStep === 0 && 'invisible'}`} style={{ cursor: props.currentStep === 0 ? 'default' : 'pointer' }} onClick={props.onClick}>{LangPrev}</a>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavButton)
