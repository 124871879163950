import React from 'react'
import {
  connect,
  mapStateToProps,
  mapDispatchToProps,
  axiosCompany
} from '../helpers/Common'

class UpdateInfo extends React.Component {
  componentDidMount () {
    if (this.props.redux.setUpdateInfo) return true

    this.loadUpdateInfo()

    /**
     * ambil info secara berkala
     */
    const interval = setInterval(() => {
      this.loadUpdateInfo()
    }, 1000 * 60)

    this.props.setState({ setUpdateInfo: interval })
  }

  loadUpdateInfo () {
    axiosCompany().get('stats/update-info').then((result) => {
      this.props.setState({ notifications: result.data })
    })
  }

  componentWillUnmount () {
    clearInterval(this.props.redux.setUpdateInfo)
  }

  render () {
    return <></>
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateInfo)
