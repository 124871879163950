import React, { useState } from 'react'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { DataEmployees, Edit, Home, LangAdd, LangLateCharge, ManageEmployees, ManageDivision, LangDocuments, LangDeleted, LangManagePosition, LangPositionHierarchy, Employe } from '../../constant/languange'
import { ModalApp } from '../../helpers/Input'
import { MENUITEMS, NEWMENUITEMS } from '../sidebar/menu'
import { connect, cookies, mapStateToProps } from '../../helpers/Common'

const SubmenuBreadcrumbs = ({ redux }) => {
  const selectedMenus = cookies.get('menu-version') === '2' ? NEWMENUITEMS : MENUITEMS
  const selectedFilter = cookies.get('menu-version') === '2' ? Employe : ManageEmployees
  const routes = selectedMenus.filter(menu => menu.Items[0].title.includes(selectedFilter))[0].Items[0].children
  return (
    <ul className='list-group'>
      {
        routes.map((item, i) => (
          <Link to={item.path} key={i} className={`list-group-item list-group-item-action ${item.active ? redux.darkModeOn ? 'active bg-primary' : 'active' : ''} ${redux.darkModeOn ? 'bg-dark text-white' : ''}`}>{item.title}</Link>
        ))
      }
    </ul>
  )
}

function PersonnelBreadcrumbs ({ redux, id = '', title = '', routeType = '', subRoute = '', subRoutes = [], ...props }) {
  const history = useHistory()
  const [control, setControl] = useState({ showModal: false })

  const route = {
    personnel: {
      list: {
        name: DataEmployees,
        link: '/personnels'
      },
      create: {
        name: LangAdd,
        link: '/personnels/create'
      },
      deleted: {
        name: LangDeleted,
        link: '/personnels/deleted'
      },
      edit: {
        name: Edit,
        link: `/personnels/${id}/edit`
      },
      detail: {
        name: 'Detail',
        link: `/personnels/${id}/detail`
      },
      doc: {
        name: LangDocuments,
        link: `/personnels/${id}/document`
      },
      worktimedaily: {
        name: 'Jadwal Harian',
        link: `/personnels/${id}/worktimedaily`
      }
    },
    group: {
      list: {
        name: ManageDivision,
        link: '/manage/group'
      },
      penalty: {
        name: LangLateCharge,
        link: `/manage/group/${id}/punishment`
      }
    },
    position: {
      list: {
        name: LangManagePosition,
        link: '/manage/position'
      },
      visualization: {
        name: LangPositionHierarchy,
        link: `/manage/position/visualization`
      }
    }
  }
  return (
    <>
      <Container fluid>
        <div className='page-title'>
          <Row>
            <Col lg='7'>
              <h3>{title}</h3>
              <Breadcrumb className='breadcrumb justify-content-start mt-3'>
                <BreadcrumbItem onClick={() => { history.push('/dashboard') }}>{Home}</BreadcrumbItem>
                <BreadcrumbItem onClick={() => { setControl({ ...control, showModal: true }) }}>{ManageEmployees}</BreadcrumbItem>
                {subRoute !== ''
                  ? (
                    <BreadcrumbItem active>{subRoute}</BreadcrumbItem>
                    )
                  : (
                      subRoutes.map((item, i) => {
                        const data = route[routeType][item]
                        return (
                          <BreadcrumbItem key={i} onClick={() => { (subRoutes.length !== (i + 1)) && history.push({ pathname: data.link, state: { filter: JSON.parse(localStorage.getItem('filterPersonnel')) || '' } }) }} active={subRoutes.length === (i + 1)}>
                            {data.name}
                          </BreadcrumbItem>
                        )
                      })
                    )}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
      {control.showModal && (
        <ModalApp
          size='md'
          title={ManageEmployees}
          handleClose={() => setControl({ ...control, showModal: false })}
          body={<SubmenuBreadcrumbs redux={redux} />}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps)(PersonnelBreadcrumbs)
