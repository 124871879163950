export function checkAttributesEqualityArrayOfObject (arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false // Arrays have different lengths
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i]
    const obj2 = arr2[i]

    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false // Attribute values are different
      }
    }
  }

  return true // All attributes are equal
}

export const checkAttributesEqualityObject = (obj1, obj2) => {
  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    } else {
      return false
    }
  }
  return true
}
