import { useState, useEffect } from 'react'
import { LangRequestDate, LangReqAmount, LangDescription, LangExpenses, LangFiles, LangApprovedAt, LangLeaveRejected, LangPending, LangApproveAmount } from '../../constant/languange'
import { PaginationDiv, ShowImage, SpinnerLoadFirst, axiosCompany, connect, displayDate, download, mapDispatchToProps, mapStateToProps, toRupiah } from '../../helpers/Common'
import { useParams } from 'react-router-dom'
import { ModalApp } from '../../helpers/Input'

const ReimbursementClaimHistoriesComponent = ({ redux, currentData }) => {
  const params = useParams()
  // state
  const [control, setControl] = useState({ showModal: '', showLoad: false })
  const [filter, setFilter] = useState({
    page: 1
  })
  const [data, setData] = useState({})
  const [focusData, setFocusData] = useState({})
  console.log(data)

  // effect
  useEffect(() => {
    getListService()
  }, [])

  // function
  const getListService = () => {
    setControl(currControl => ({ ...currControl, showLoad: true }))
    axiosCompany().get(`/reimbursements/${params.id}/personnels/${currentData.personnel_id}/claim-histories`, { params: filter })
      .then((res) => {
        setData(curData => res.data)
      })
      .finally(() => {
        setControl(currControl => ({ ...currControl, showLoad: false }))
      })
  }

  const openModal = (modal, data) => {
    setControl(control => ({ ...control, showModal: modal }))
    setFocusData(currFocusData => data)
  }

  const viewFile = (fileItem) => {
    if (fileItem.file_type === "images") openModal('view-file', fileItem)
    if (fileItem.file_type === "document") download(fileItem.url, fileItem.file_client_name)
  }

  // handler
  const handlePage = (page) => {
    filter.page = page
    getListService()
  }

  return (
    <>
      {control.showLoad
        ? SpinnerLoadFirst
        : (
          <div className='table-responsive'>
            <table className={`table table-striped table-bordered ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
              <thead>
                <tr>
                  <th scope='col' style={{ minWidth: 140 }}>{LangRequestDate}</th>
                  <th scope='col' style={{ minWidth: 200 }}>{LangReqAmount} (Rp)</th>
                  <th scope='col' style={{ minWidth: 140 }}>{LangApproveAmount} (Rp)</th>
                  <th scope='col' style={{ minWidth: 160 }}>{LangDescription}</th>
                  <th scope='col' style={{ minWidth: 200 }}>{LangExpenses}</th>
                  <th scope='col'>{LangFiles}</th>
                  <th scope='col' style={{ minWidth: 140 }}>{LangApprovedAt}</th>
                </tr>
              </thead>
              <tbody>
                {data.data?.map(item => (
                  <tr>
                    <th scope='row' className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>
                      {displayDate(item.request_date, 'DD MMM YYYY')} <br />
                      {displayDate(item.request_date, '@ HH:mm')}
                    </th>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{toRupiah(item.request_amount)}</td>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{toRupiah(item.approval_amount ?? '')}</td>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item.description}</td>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>
                      <ul className='list-group'>
                        {item.expenses?.map(expItem => (
                          <li className={`list-group-item px-2 py-1 ${redux.darkModeOn ? 'bg-dark' : ''}`}>
                            {expItem.name} 
                            <br />Rp{toRupiah(expItem.nominal ?? '')}
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>
                      <ul>
                        {item.userfiles?.map((fileItem, idx) => {
                          if (fileItem === null) return
                          return (
                            <li key={idx} className='mb-3'>
                              <a style={{ cursor: 'pointer' }} onClick={() => viewFile(fileItem)} className={`btn-sm py-2 text-nowrap ${redux.darkModeOn ? 'btn-dark' : 'btn-light'}`}>
                                View file {idx + 1}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </td>
                    <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>
                      {item.status === 'pending' && (
                        <p className='badge badge-light m-0'>{LangPending}</p>
                      )}
                      {item.status === 'approved' && (
                        <>
                          {displayDate(item.approved_at, 'DD MMM YYYY')} <br />
                          {displayDate(item.approved_at, '@ HH:mm')}
                        </>
                      )}
                      {item.status === 'rejected' && (
                        <>
                          <p className='badge badge-danger m-0'>{LangLeaveRejected}</p> <br />
                          {displayDate(item.rejected_at, 'DD MMM YYYY')} <br />
                          {displayDate(item.rejected_at, '@ HH:mm')}
                        </>
                      )}
                    </td>
                  </tr>))}
              </tbody>
            </table>
            <div className='mt-4'>
              <PaginationDiv data={data} onChange={handlePage} />
            </div>
          </div>)}

      {(control.showModal === 'view-file') && (
        <ModalApp
          title={focusData.file_client_name}
          handleClose={() => setControl(control => ({ ...control, showModal: '' }))}
          size='sm'
          body={<ShowImage alt='' className='img-fluid w-100 rounded' url={focusData.url} />}
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementClaimHistoriesComponent)
