import React from 'react'
import { LangMessage, LangNotificationMessage, LangTitle } from '../../constant/languange'
// import { ContentState, EditorState } from 'draft-js'
// import htmlToDraft from 'html-to-draftjs'
import ReactHtmlParser from 'react-html-parser'

function BroadcastPreviewComponent ({ data, ...props }) {
  // const parseToDraft = (content) => {
  //   const contentBlock = htmlToDraft(content)
  //   if (contentBlock) {
  //     return ContentState.createFromBlockArray(contentBlock.contentBlocks)
  //   }
  //   return []
  // }

  // const htmlToText = (content) => {
  //   const text = EditorState.createWithContent(parseToDraft(content)).getCurrentContent()
  //   return text
  // }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-12 col-md-4'>
          <h6>{LangTitle}</h6>
        </div>
        <div className='col-12 col-lg-8'>
          <div className='d-flex mb-2'>
            <i className='flag-icon flag-icon-id mr-3' />
            <div className='flex-grow-1'>
              {data.message_title}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <i className='flag-icon flag-icon-us mr-3' />
            <div className='flex-grow-1'>
              {data.message_title_en || ''}
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-12 col-md-4'>
          <h6>{LangNotificationMessage}</h6>
        </div>
        <div className='col-12 col-lg-8'>
          <div className='d-flex mb-2'>
            <i className='flag-icon flag-icon-id mr-3' />
            <div className='flex-grow-1'>
              {data.message_content_notif}
            </div>
          </div>
          <div className='d-flex mb-2'>
            <i className='flag-icon flag-icon-us mr-3' />
            <div className='flex-grow-1'>
              {data.message_content_notif_en || ''}
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-4'>
        <div className='col-12 col-md-4'>
          <h6>{LangMessage}</h6>
        </div>
        <div className='col-12 col-lg-8'>
          <div className='row align-items-center mb-2'>
            <div className='col-1'>
              <i className='flag-icon flag-icon-id ' />
            </div>
            <div className='col-11 pl-sm-1'>
              {data.message_content ? ReactHtmlParser(data.message_content) : ''}
            </div>
          </div>
          <div className='row align-items-center mb-2'>
            <div className='col-1'>
              <i className='flag-icon flag-icon-us' />
            </div>
            <div className='col-11 pl-sm-1'>
              {data.message_content_en ? ReactHtmlParser(data.message_content_en) : ''}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BroadcastPreviewComponent
