import { LangApprovalLevel, LangApproved, LangBy, LangEmployeeDivisionSpv, LangPending, LangRejected, LangReviewer, LangStatus, LangUpdated } from '../../../../constant/languange'
import { connect, displayApproverName, mapStateToProps } from '../../../../helpers/Common'

const ApprovalSteps = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle p-2'>{LangApprovalLevel}</th>
            <th scope='col' className='text-center align-middle p-2'>{LangStatus}</th>
            <th scope='col' className='text-center align-middle p-2'>{LangReviewer}</th>
            <th scope='col' className='text-center align-middle p-2'>{LangUpdated} {LangBy}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>{item?.order}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>
                {
                  item.status === 'pending' && <span className='badge badge-warning'>{LangPending}</span>
                }
                {
                  item.status === 'approve' && <span className='badge badge-success'>{LangApproved}</span>
                }
                {
                  item.status === 'reject' && <span className='badge badge-danger'>{LangRejected}</span>
                }
              </td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>{displayApproverName(item, LangEmployeeDivisionSpv)}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>
                {
                  item?.updated_by !== null &&
                    item?.approver_type === 'spv_of_employee' ?
                    `${LangEmployeeDivisionSpv} ${item?.updated_by?.name ? `(${item?.updated_by?.name})` : ''}`
                    :
                    item?.approver_type === 'spesific_position' ?
                      item?.updated_by?.personnel?.position ? `${item?.updated_by?.personnel?.position.name} (${item?.updated_by?.name})` : `${item?.updated_by?.name}`
                      :
                      item?.updated_by?.name
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(ApprovalSteps)
