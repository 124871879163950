import { LangApprovalLevel, LangApprovalRules, LangEmployeeDivisionSpv } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const ApprovalRulesStep = ({ redux, val }) => {

  const displayApprovalStepDetail = (type, data) => {
    let text = []
    if (type === 'specific_position') {
      let manipulated = JSON.parse(JSON.stringify(data))
      const output = Object.values(manipulated.reduce((acc, ar) => {
        if (acc[ar.position])
          acc[ar.position].name += ' / ' + ar.name
        else
          acc[ar.position] = {...ar}
        return acc
      },{}))
      output?.forEach(item => {
        let temp = ''
        if(typeof item.name === 'string'){
          let nameArr = item.name.split(' / ')
          nameArr = nameArr.slice(0, 5)
          temp += ` (${nameArr.join(' / ')})`
        } else {
          temp += ` (${item.name})`
        }
        text.push(`${item.position} ${temp}`)
      })
    } else if (type === 'spv_of_employee' || type === 'specific_employee') {
      data?.forEach(item => {
        text.push(`${item.name}`)
      })
    }
    return text.join(' / ')
  }

  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle p-2'>{LangApprovalLevel}</th>
            <th scope='col' className='text-center align-middle p-2'>{LangApprovalRules}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>{item?.order}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''} p-2`}>
                {
                  item?.approver_type !== 'spv_of_employee' ?
                    <span>{displayApprovalStepDetail(item?.approver_type, item?.approver_details)}</span>
                    :
                    <span>{LangEmployeeDivisionSpv} {item?.approver_details && item?.approver_details.length !== 0 && `(${displayApprovalStepDetail(item?.approver_type, item?.approver_details)})`}</span>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(ApprovalRulesStep)
