import { DayOff, Friday, Monday, Saturday, Sunday, Thrusday, Tuesday, Wednesday, WorkDay } from './languange'

export const days = [Monday, Tuesday, Wednesday, Thrusday, Friday, Saturday, Sunday]
export const workPatterns = [WorkDay, DayOff]

export const getScheduleValues = (data) => {
  const before = data?.before ?? null
  const after = data?.after ?? null

  if (after && before) {
    let result = {}
    const afterResult = []
    const beforeResult = []
    after.forEach((item, i) => {
      if ((item.time_in !== before[i].time_in || item.time_out !== before[i].time_out || item.type_id !== before[i].type_id || item.break_range_start !== before[i].break_range_start || item.break_range_end !== before[i].break_range_end || item.break_max_minutes !== before[i].break_max_minutes)) {
        afterResult.push({ ...item, type_name: workPatterns[+item.type_id - 1], day: days[i] })
        beforeResult.push({ ...before[i], type_name: workPatterns[+before[i].type_id - 1], day: days[i] })
      }
    })
    result = { after: afterResult, before: beforeResult }
    return result
  } else {
    const result = data.map((item, i) => {
      item.day = days[i]
      item.type_name = workPatterns[+item.type_id - 1]
      return item
    })
    return result
  }
}
