import React, { useState, useEffect } from 'react'
import { Action, AdditionalField, AddOption, Address, ChooseType, Close, LangAdd, LangAllowedVisitRadius, LangClientMapLocation, LangClientVisitMustOnSpot, LangNo, LangRequired, LangTipCheckboxField, LangTipComboboxField, LangYes, Name, Options, SaveChange, Type } from '../../constant/languange'
import { axiosCompany, connect, mapStateToProps, SpinnerButton, SpinnerLoadFirst } from '../../helpers/Common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import Map from '../../helpers/Map'
import { Success } from '../../helpers/Message'

const ClientEditClientComponent = (props) => {
  // state
  const [control, setControl] = useState({ onSubmit: false, showLoad: false })
  const [form, setForm] = useState({
    name: '',
    address: '',
    additional_fields: [],
    longitude: '',
    latitude: '',
    radius: '',
    client_visit_must_on_spot: false
  })
  const [dataMap, setDataMap] = useState({
    markerPosition: {
      lat: '',
      lng: ''
    }
  })
  const [responseData, setResponseData] = useState(null)

  // effect
  useEffect(() => {
    loadData()
    // eslint-disable-next-line
  }, [])

  // function
  const loadData = () => {
    setControl({ ...control, showLoad: true })
    axiosCompany().get(`client-visit-clients/${props.currentData.id}`)
      .then((res) => {
        setControl({ ...control, showLoad: false })

        // restructure data
        if (!Array.isArray(res.data.attr)) {
          const additional_fields = [...res.data.attr.additional_fields]
          res.data.additional_fields = additional_fields
        } else {
          res.data.additional_fields = []
        }
        if (res.data.latitude && res.data.longitude) {
          setDataMap({
            markerPosition: {
              lat: Number(res.data.latitude),
              lng: Number(res.data.longitude)
            }
          })
        }

        setForm(res.data)
      })
      .catch((err) => {
        setControl({ ...control, showLoad: false })
      })
  }

  const preapareData = () => {
    const newAdditionalFields = form.additional_fields.map((field) => {
      if (field.type === 'checkbox' || field.type === 'combobox') {
        const newOptions = field.options.map((opt, idx) => {
          opt.value = idx + 1
          return opt
        })

        field.options = newOptions

        return field
      }
      return field
    })

    setForm(currForm => ({ ...currForm, additional_fields: newAdditionalFields }))
  }

  // handler
  const handleSubmit = (e) => {
    e.preventDefault()

    setControl({ ...control, onSubmit: true })
    preapareData()
    const submitForm = {
      name: form.name,
      address: form.address,
      longitude: dataMap.markerPosition.lng,
      latitude: dataMap.markerPosition.lat,
      radius: form.radius,
      client_visit_must_on_spot: +form.client_visit_must_on_spot,
      additional_fields: form.additional_fields
    }
    if (!props.currentData.latitude && !props.currentData.longitude) {
      if(!form.client_visit_must_on_spot) {
        delete submitForm.longitude
        delete submitForm.latitude
        delete submitForm.radius
      }
    } else {
      if (!submitForm.radius) delete submitForm.radius
      if (!submitForm.longitude) delete submitForm.longitude
      if (!submitForm.latitude) delete submitForm.latitude
    }
    axiosCompany().put(`client-visit-clients/${props.currentData.id}`, submitForm)
      .then((res) => {
        setControl({ ...control, onSubmit: false })
        res.data.success = true
        setResponseData(res.data)
        props.handleUpdate()
        setTimeout(() => {
          props.handleClose()
        }, 2000)
      })
      .catch((err) => {
        setControl({ ...control, onSubmit: false })
        setResponseData(err.response.data)
      })
  }

  const handleChooseType = (e) => {
    e.preventDefault()

    // preapare init data
    const additionalFieldItem = {
      type: e.target.name,
      label: '',
      required: 0,
      show_on_form: 3,
      placeholder: ''
    }
    if (e.target.name === 'checkbox' || e.target.name === 'combobox') {
      additionalFieldItem.options = []
    }

    setForm({ ...form, additional_fields: [...form.additional_fields, additionalFieldItem] })
  }

  const handleChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const handleAdditional = (e, name, index) => {
    // select the item
    const additionalField = [...form.additional_fields]
    const additionalFieldItem = additionalField[+index]

    // change the value
    if (name === 'required' || name === 'show_on_form') {
      additionalFieldItem[name] = +e.target.value
    } else {
      additionalFieldItem[name] = e.target.value
    }

    setForm({ ...form, additional_fields: additionalField })
  }

  const handleAddOption = (e, index) => {
    e.preventDefault()

    // select the item
    const additionalField = [...form.additional_fields]
    const additionalFieldItem = additionalField[+index]

    // add the fields
    const options = { label: '', value: '' }
    additionalFieldItem.options.push(options)

    setForm({ ...form, additional_fields: additionalField })
  }

  const handleChangeOptions = (e, name, index1, index2) => {
    // select the item
    const additionalField = [...form.additional_fields]
    const additionalFieldItem = additionalField[+index1]

    // select option row and change the value
    const options = additionalFieldItem.options
    options[+index2][name] = e.target.value

    setForm({ ...form, additional_fields: additionalField })
  }

  const handleDeleteAdditionalItem = (e, i) => {
    e.preventDefault()

    const additionalField = [...form.additional_fields]
    const filteredAdditionalField = additionalField.filter((item, idx) => idx !== i)

    setForm({ ...form, additional_fields: filteredAdditionalField })
  }

  const handleDeleteOptionItem = (e, i, j) => {
    e.preventDefault()

    const additionalField = [...form.additional_fields]
    const additionalFieldItem = additionalField[+i]
    const additionalFieldItemOptions = additionalFieldItem.options
    const filteredAdditionalFieldItemOptions = additionalFieldItemOptions.filter((item, idx) => idx !== j)
    additionalFieldItem.options = filteredAdditionalFieldItemOptions

    setForm({ ...form, additional_fields: additionalField })
  }

  const handleMoveItem = (e, dir, i) => {
    e.preventDefault()

    const additionalField = [...form.additional_fields]

    // remove the element from the array at the given index
    const fromIndex = i
    const element = additionalField.splice(fromIndex, 1)[0]

    // insert the element into the array at the given index
    const toIndex = dir === 'up' ? i - 1 : i + 1
    additionalField.splice(toIndex, 0, element)

    // return the updated array
    setForm({ ...form, additional_fields: additionalField })
  }

  return (
    <>
      {control.showLoad ? SpinnerLoadFirst : (
        <form>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{Name} <span className='text-danger'>*</span></label>
            <div className='col-sm-6'>
              <input type='text' className={`form-control ${responseData?.errors?.name ? 'is-invalid' : null} `} name='name' value={form.name} onKeyDown={handleKeydown} onChange={handleChangeForm} />
              {(responseData?.errors?.name) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.name}</strong></div> : null}
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{Address}</label>
            <div className='col-sm-6'>
              <textarea rows={3} className={`form-control ${responseData?.errors?.address ? 'is-invalid' : null} `} name='address' value={form.address} onChange={handleChangeForm} />
              {(responseData?.errors?.address) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.address}</strong></div> : null}
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangClientVisitMustOnSpot}</label>
            <div className='col-sm-6 d-flex align-items-center'>
              <div className='custom-control custom-switch' style={{ lineHeight: 1.8 }}>
                <input type='checkbox' className='custom-control-input' style={{ cursor: 'pointer' }} id='clientVisitMustOnSpot' checked={form.client_visit_must_on_spot} onChange={(e) => setForm(currForm => ({ ...currForm, client_visit_must_on_spot: e.target.checked }))} />
                <label className='custom-control-label' htmlFor='clientVisitMustOnSpot' style={{ cursor: 'pointer' }}>&nbsp;</label>
              </div>
              {(responseData?.errors?.client_visit_must_on_spot) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.client_visit_must_on_spot}</strong></div> : null}
            </div>
          </div>
          {
            form.latitude && form.longitude ?
              <>
                <div className='form-group row'>
                  <label className='col-sm-3 col-form-label'>{LangClientMapLocation} <span className='text-danger'>*</span></label>
                  <div className='col-sm-8'>
                    {
                      form.latitude && form.longitude ?
                        <Map searchLocation draggable lat={Number(form.latitude)} lng={Number(form.longitude)} parentCallback={(dataMap) => setDataMap(dataMap)} radius={form.radius} />
                        :
                        <Map searchLocation draggable parentCallback={(dataMap) => setDataMap(dataMap)} radius={form.radius} />
                    }
                    {(responseData?.errors?.latitude) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.latitude}</strong></div> : null}
                    {(responseData?.errors?.longitude) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.longitude}</strong></div> : null}
                  </div>
                </div>
                <div className='form-group row'>
                  <label className='col-sm-3 col-form-label'>{LangAllowedVisitRadius} (Meter) {form.client_visit_must_on_spot && <span className='text-danger'>*</span>}</label>
                  <div className='col-sm-3'>
                    <select className={`form-control ${responseData?.errors?.radius ? 'is-invalid' : ''}`} name='radius' value={form.radius} onChange={handleChangeForm}>
                      <option value=''>--Radius--</option>
                      <option value='10'>10</option>
                      <option value='20'>20</option>
                      <option value='30'>30</option>
                      <option value='40'>40</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                      <option value='200'>200</option>
                      <option value='300'>300</option>
                    </select>
                    {(responseData?.errors?.radius) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.radius}</strong></div> : null}
                  </div>
                </div>
              </>
              :
              form.client_visit_must_on_spot &&
              <>
                <div className='form-group row'>
                  <label className='col-sm-3 col-form-label'>{LangClientMapLocation} <span className='text-danger'>*</span></label>
                  <div className='col-sm-8'>
                    {
                      form.latitude && form.longitude ?
                        <Map searchLocation draggable lat={Number(form.latitude)} lng={Number(form.longitude)} parentCallback={(dataMap) => setDataMap(dataMap)} radius={form.radius} />
                        :
                        <Map searchLocation draggable parentCallback={(dataMap) => setDataMap(dataMap)} radius={form.radius} />
                    }
                    {(responseData?.errors?.latitude) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.latitude}</strong></div> : null}
                    {(responseData?.errors?.longitude) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.longitude}</strong></div> : null}
                  </div>
                </div>
                <div className='form-group row'>
                  <label className='col-sm-3 col-form-label'>{LangAllowedVisitRadius} (Meter) <span className='text-danger'>*</span></label>
                  <div className='col-sm-3'>
                    <select className={`form-control ${responseData?.errors?.radius ? 'is-invalid' : ''}`} name='radius' value={form.radius} onChange={handleChangeForm}>
                      <option value=''>--Radius--</option>
                      <option value='10'>10</option>
                      <option value='20'>20</option>
                      <option value='30'>30</option>
                      <option value='40'>40</option>
                      <option value='50'>50</option>
                      <option value='100'>100</option>
                      <option value='200'>200</option>
                      <option value='300'>300</option>
                    </select>
                    {(responseData?.errors?.radius) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.radius}</strong></div> : null}
                  </div>
                </div>
              </>
          }
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{AdditionalField}</label>
            <div className='col-sm-9'>
              <div className='dropdown mb-3'>
                <a className='btn btn-primary dropdown-toggle' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                  {LangAdd}
                </a>
                <div className={`dropdown-menu shadow ${props.redux.darkModeOn ? 'bg-dark' : ''}`}>
                  <h6 className='dropdown-header'>{ChooseType}</h6>
                  <a className='dropdown-item' name='text' onClick={handleChooseType}>Text</a>
                  <a className='dropdown-item' name='textarea' onClick={handleChooseType}>Textarea</a>
                  <a className='dropdown-item' name='checkbox' onClick={handleChooseType} data-tip={LangTipCheckboxField}>Checkbox</a>
                  <a className='dropdown-item' name='combobox' onClick={handleChooseType} data-tip={LangTipComboboxField}>Combobox</a>
                  <ReactTooltip place='right' className='w-100' />
                </div>
              </div>
              {form.additional_fields.map((item, i) => (
                <div key={i} className='table-responsive mb-3'>
                  <table className={`table table-striped table-bordered ${props.redux.darkModeOn ? 'table-dark bg-dark' : ''}`} style={{ maxWidth: 570 }}>
                    <tbody>
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}>{Type} <span className='text-danger'>*</span></th>
                        <td className='text-capitalize' style={{ minWidth: 550 }}>{item.type}</td>
                      </tr>
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}>Label <span className='text-danger'>*</span></th>
                        <td style={{ minWidth: 550 }}>
                          <input type='text' className={`form-control form-control-sm ${responseData?.errors?.[`additional_fields.${i}.label`] ? 'is-invalid' : null}`} value={item.label} onChange={(e) => handleAdditional(e, 'label', i)} />
                          {(responseData?.errors?.[`additional_fields.${i}.label`]) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.[`additional_fields.${i}.label`][0]}</strong></div> : null}
                        </td>
                      </tr>
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}>Placeholder</th>
                        <td style={{ minWidth: 550 }}><input type='text' className='form-control form-control-sm' value={item.placeholder} name='placeholder' id={i} onChange={(e) => handleAdditional(e, 'placeholder', i)} /></td>
                      </tr>
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}>{LangRequired} <span className='text-danger'>*</span></th>
                        <td style={{ minWidth: 550 }}>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' id={`required-yes-${i}`} value={1} checked={item.required === 1} onChange={(e) => handleAdditional(e, 'required', i)} />
                            <label className='form-check-label' for={`required-yes-${i}`}>{LangYes}</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' id={`required-no-${i}`} value={0} checked={item.required === 0} onChange={(e) => handleAdditional(e, 'required', i)} />
                            <label className='form-check-label' for={`required-no-${i}`}>{LangNo}</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}>Form <span className='text-danger'>*</span></th>
                        <td style={{ minWidth: 550 }}>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' id={`form-start-${i}`} value={1} checked={item.show_on_form === 1} onChange={(e) => handleAdditional(e, 'show_on_form', i)} />
                            <label className='form-check-label' for={`form-start-${i}`}>Datang</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' id={`form-end-${i}`} value={2} checked={item.show_on_form === 2} onChange={(e) => handleAdditional(e, 'show_on_form', i)} />
                            <label className='form-check-label' for={`form-end-${i}`}>Pulang</label>
                          </div>
                          <div className='form-check form-check-inline'>
                            <input className='form-check-input' type='radio' id={`form-start-end-${i}`} value={3} checked={item.show_on_form === 3} onChange={(e) => handleAdditional(e, 'show_on_form', i)} />
                            <label className='form-check-label' for={`form-start-end-${i}`}>Datang dan Pulang</label>
                          </div>
                        </td>
                      </tr>
                      {item.type === 'checkbox' || item.type === 'combobox' ? (
                        <tr>
                          <th scope='row' style={{ minWidth: 120 }}>{Options} <span className='text-danger'>*</span></th>
                          <td style={{ minWidth: 550 }}>
                            {item?.options?.length > 0 && (
                              <div className='table-responsive mb-2'>
                                <table className={`table table-striped table-bordered ${props.redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
                                  <tbody>
                                    {item?.options?.map((opt, j) => (
                                      <tr key={j}>
                                        <th className={`${props.redux.darkModeOn ? 'border-secondary' : ''}`} scope='row'>
                                          <input type='text' className={`form-control form-control-sm ${responseData?.errors?.[`additional_fields.${i}.options.${j}.label`] ? 'is-invalid' : null}`} value={opt.label} onChange={(e) => handleChangeOptions(e, 'label', i, j)} />
                                          {(responseData?.errors?.[`additional_fields.${i}.options.${j}.label`]) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.[`additional_fields.${i}.options.${j}.label`][0]}</strong></div> : null}
                                        </th>
                                        {/* <td className={`${props.redux.darkModeOn ? 'border-secondary' : ''}`}>
                                          <input type='text' className={`form-control form-control-sm ${responseData?.errors?.[`additional_fields.${i}.options.${j}.value`] ? 'is-invalid' : null}`} value={opt.value} onChange={(e) => handleChangeOptions(e, 'value', i, j)} />
                                          {(responseData?.errors?.[`additional_fields.${i}.options.${j}.value`]) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.[`additional_fields.${i}.options.${j}.value`][0]}</strong></div> : null}
                                        </td> */}
                                        <td className={`${props.redux.darkModeOn ? 'border-secondary' : ''}`}><button className={`btn ${props.redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-light mr-2 mb-2 btn-sm`} onClick={e => handleDeleteOptionItem(e, i, j)}><FontAwesomeIcon icon={faTimes} /></button></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            <a className='btn btn-primary mr-2 mb-2 btn-sm' onClick={(e) => handleAddOption(e, i)}>{AddOption}</a>
                            {(responseData?.errors?.[`additional_fields.${i}.options`]) ? <div className='invalid-feedback d-block'><strong>{responseData?.errors?.[`additional_fields.${i}.options`][0]}</strong></div> : null}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <th scope='row' style={{ minWidth: 120 }}><span className='sr-only'>{Action}</span></th>
                        <td style={{ minWidth: 550 }}>
                          {/* <button className='btn btn-primary mr-2 mb-2 btn-sm'>{SaveChange}</button> */}
                          <button className='btn btn-light mr-2 mb-2 btn-sm' onClick={e => handleMoveItem(e, 'up', i)}><FontAwesomeIcon icon={faChevronUp} /></button>
                          <button className='btn btn-light mr-2 mb-2 btn-sm' onClick={e => handleMoveItem(e, 'down', i)}><FontAwesomeIcon icon={faChevronDown} /></button>
                          <button className='btn btn-danger mr-2 mb-2 btn-sm' onClick={e => handleDeleteAdditionalItem(e, i)}><FontAwesomeIcon icon={faTrashAlt} /></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}

            </div>
          </div>

          <hr />
          <div className='form-group row'>
            <div className='col-sm-8 offset-md-3'>
              {responseData?.success ? <Success /> : null}
              {responseData?.message ? <div className='alert alert-danger'>{responseData.message}</div> : null}
              <a disabled={control.onSubmit} type='submit' className='btn btn-primary mr-2' onClick={handleSubmit}>{(control.onSubmit) ? SpinnerButton : null} {SaveChange}</a>
              <a onClick={props.handleClose} disabled={control.onSubmit} type='button' className='btn btn-light'>{Close}</a>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default connect(mapStateToProps)(ClientEditClientComponent)

