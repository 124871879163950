import React from 'react'
import {
  connect,
  mapStateToProps,
  PaginationDiv,
  SpinnerLoadFirst,
  SpinnerButton,
  httpBuildQuery,
  moment,
  axiosCompany,
  displayDate,
  checkFeatureLimitation,
  checkLimitationDate
} from '../../helpers/Common'
import { Search, Division, LangPayrollDateRangeInfo, LangShiftChangeScheduleRecap, LangApplicantemployees, LangApplicantSchedule, LangTargetemployees, LangTargetSchedule, LangDateCreated, LangApprovedBy, LangRejectedBy, LangPending, LangLeaveApproved, ExportExcel, LangRejected, LangScheduleOff, LangAttendance, LangWorkPatternDeleted } from '../../constant/languange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload, faSearch, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { InputRangeDatePicker, ModalApp } from '../../helpers/Input'
// import Breadcrumbs from '../../layout/breadcrumb'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'
import PersonnelItemComponent from '../personnel/PersonnelItemComponent'
import ShiftChangeScheduleExportComponent from './ShiftChangeScheduleExportComponent'

class ShiftChangeScheduleRecap extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      dataGroup: [],
      dataStatus: [{ name: LangPending, value: 'pending' }, { name: LangLeaveApproved, value: 'approve' }, { name: LangRejected, value: 'reject' }],
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      showAlert: '',
      groupId: '',
      status: '',
      activePage: 1,
      search: '',
      loading: false,
      loading2: false,
      onExport: false,
      dataResponse: {
        data: [],
        meta: {
          total: 0
        }
      },
      modal: {
        show: false,
        title: '',
        body: ''
      },
      showModal: ''
    }

    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleExportExcel = this.handleExportExcel.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount () {
    this.loadData()

    if (this.props.redux?.groups) {
      this.setState({
        dataGroup: this.props.redux.groups
      })
    }
  }

  handlePageChange (pageNumber) {
    this.setState({ activePage: pageNumber }, () => {
      this.loadData()
    })
  }

  handleExportExcel () {
    this.setState({ onExport: true })

    /**
     * format date input ke UTC
     */
    const filter = {
      group_id: this.state.groupId,
      date_from: this.state.startDate,
      date_to: this.state.endDate,
      keyword: this.state.search
    }

    axiosCompany()
      .get('attendances/summaries-xlsx?' + httpBuildQuery(filter), {
        responseType: 'blob'
      })
      .then((result) => {
        const url = window.URL.createObjectURL(new window.Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `attendance-summaries-${this.state.startDate}-to-${this.state.endDate}.xlsx`)
        document.body.appendChild(link)
        link.click()

        this.setState({ onExport: false })
      })
  }

  loadData = () => {
    this.setState({ loading: true })

    const filter = {
      page: this.state.activePage,
      group_id: this.state.groupId,
      keyword: this.state.search,
      date_from: this.state.startDate,
      date_to: this.state.endDate,
      status: this.state.status
    }

    axiosCompany().get('switch-shifts?' + httpBuildQuery(filter))
      .then((res) => {
        // console.log(res.data)
        this.setState({ dataResponse: res.data, loading: false, loading2: false })
      })
  }

  handleGroup = (e) => {
    this.setState({
      groupId: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleStatus = (e) => {
    this.setState({
      status: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleStartDate = (e) => {
    this.setState({
      startDate: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleEndDate = (e) => {
    this.setState({
      endDate: e,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && !this.state.loading) {
      this.setState({ loading2: true, activePage: 1 })
      this.loadData()
    }
  }

  handleDateChange (value) {
    /**
     * cek apakah lebih dari 60 hari, jika ya paksa maks 60 hari
     */
    let startDate = value.startDate
    let showAlert = ''
    const endDateSub = moment(value.endDate).subtract(2, 'month')
    if (moment(value.startDate).isBefore(endDateSub)) {
      startDate = endDateSub.format('YYYY-MM-DD')
      showAlert = 'date-range'
    }

    this.setState({
      showAlert,
      startDate,
      endDate: value.endDate,
      activePage: 1
    }, () => {
      this.loadData()
    })
  }

  handleViewDates (e, dataModal) {
    e.preventDefault()
    this.setState({ showModal: 'view-dates', dataModal })
  }

  showModalDescription (title, body) {
    this.setState({ modal: { show: true, title, body } })
  }

  render () {
    const p = this.props

    return (
      <>
        {/* <Breadcrumbs title={LangShiftChangeScheduleRecap} child={LangShiftChangeScheduleRecap} /> */}
        <BreadcrumbsMenu title={LangShiftChangeScheduleRecap}>{[LangAttendance, LangShiftChangeScheduleRecap]}</BreadcrumbsMenu>
        <div className='container-fluid '>
          <div className='row '>
            <div className='col-sm-12 '>
              <div className='card'>
                <div className='card-header'>
                  <div className='d-flex flex-column flex-xl-row flex-xls-column'>
                    <div className='d-md-flex'>
                      <div className='mr-2 mb-2' style={{ maxWidth: '330px' }}>
                        <InputRangeDatePicker
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleDateChange}
                          limitMinDate={checkLimitationDate(p.redux, 'max_day_filter_attendance_history')}
                        />
                        {this.state.showAlert === 'date-range' ? (<div className='alert alert-info outline small py-1 px-2 mt-2 mb-0'>{LangPayrollDateRangeInfo}</div>) : null}
                      </div>
                      <div className='d-sm-flex'>
                        <div className='mr-2 mb-2'>
                          <select className='form-control' style={{ maxWidth: 250 }} value={this.state.groupId} onChange={(e) => this.handleGroup(e.target.value)}>
                            <option value=''>--{Division}--</option>
                            {this.state.dataGroup.map((obj) => {
                              if (obj.work_system === 2) {
                                return (
                                  <option key={obj.id} value={obj.id}>{obj.name}</option>
                                )
                              }

                              return null
                            })}
                          </select>
                        </div>
                        <div className='mr-2 mb-2'>
                          <select className='form-control' style={{ maxWidth: 250 }} value={this.state.status} onChange={(e) => this.handleStatus(e.target.value)}>
                            <option value=''>--Status--</option>
                            {this.state.dataStatus.map((status) => {
                              return (
                                <option key={status.value} value={status.value}>{status.name}</option>
                              )
                            })}
                          </select>
                        </div>
                        <div className='mr-2 mb-2'>
                          <div className='input-group'>
                            <input
                              type='search' className='form-control' placeholder={Search} value={this.state.search} onKeyDown={this.handleKeyDown} onChange={(e) => this.setState({ search: e.target.value })} aria-label='Search'
                              aria-describedby='search-addon' style={{ maxWidth: 250 }}
                            />
                            <div className='input-group-append' style={{ cursor: 'pointer' }} onClick={() => this.setState({ loading2: true }, () => this.loadData())}><span className={`input-group-text ${p.redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}><i> {this.state.loading2 ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />} </i></span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='ml-xl-auto ml-xls-none'>
                      {checkFeatureLimitation(p.redux, 'disable_export_summary_switch_shift') && (
                        <button onClick={() => this.setState({ showModal: 'export' })} disabled={this.state.onExport || this.state.loading || this.state.loading2} type='button' className='btn btn-primary text-nowrap' style={{ height: 38 }}><FontAwesomeIcon icon={faFileDownload} />{(this.state.onExport) ? SpinnerButton : null} {ExportExcel}</button>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.loading
                  ? SpinnerLoadFirst
                  : (
                    <div className='table-responsive' style={{ maxHeight: '55vh' }}>
                      <table className='table table-striped table-bordered table-border-separate'>
                        <thead>
                          <tr>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center align-middle`} scope='col' style={{ minWidth: '317px' }}>{LangApplicantemployees}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '180px' }}>{LangApplicantSchedule}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '317px' }}>{LangTargetemployees}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '180px' }}>{LangTargetSchedule}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '180px' }}>{LangDateCreated}</th>
                            <th className={`${p.redux.darkModeOn ? 'bg-dark' : ''} text-center table-th-sticky text-center align-middle`} scope='col' style={{ minWidth: '280px' }}>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.dataResponse.data.map((item, i) => {
                            // console.log(item)
                            const oddColor = p.redux.darkModeOn ? '#2c323f' : 'white'
                            const evenColor = p.redux.darkModeOn ? '#373c49' : 'rgba(245, 247, 250, 1)'
                            return (
                              <tr key={item.id}>
                                <td style={{ backgroundColor: (i % 2 !== 0 ? oddColor : evenColor) }}>
                                  <PersonnelItemComponent data={item.shift_work_time_id_from_data.personnel} key={'personnel-' + item.shift_work_time_id_from_data.personnel.id} />
                                </td>
                                <td>
                                  <p className='mb-1'>{moment(item.shift_work_time_id_from_data.date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')}</p>
                                  {item.shift_work_time_id_from_data.is_day_off
                                    ? (<p className='d-inline bg-danger mb-0 py-1 px-2 rounded'>{LangScheduleOff}</p>)
                                    : (<p className='mb-0 py-1 px-2 d-inline text-nowrap rounded' style={{ border: item.shift_work_time_id_from_data?.shift_work_pattern ? '2px solid #333' :  '2px solid #dc3545' }}>{item.shift_work_time_id_from_data?.shift_work_pattern?.name ?? <span className='txt-danger font-italic'>{LangWorkPatternDeleted}</span>} { item.shift_work_time_id_from_data?.shift_work_pattern ? `(${moment(item.shift_work_time_id_from_data?.shift_work_pattern?.time_in, 'HH:mm:ss').format('HH:mm')} - ${moment(item.shift_work_time_id_from_data?.shift_work_pattern?.time_out, 'HH:mm:ss').format('HH:mm')})` : ''} </p>)}
                                </td>
                                <td style={{ backgroundColor: (i % 2 !== 0 ? oddColor : evenColor) }}>
                                  <PersonnelItemComponent data={item.shift_work_time_id_to_data.personnel} key={'personnel-' + item.shift_work_time_id_to_data.personnel.id} />
                                </td>
                                <td>
                                  <p className='mb-1'>{moment(item.shift_work_time_id_to_data.date, 'YYYY-MM-DD').format('dddd, D MMMM YYYY')}</p>
                                  {item.shift_work_time_id_to_data.is_day_off
                                    ? (<p className='d-inline bg-danger mb-0 py-1 px-2 rounded'>{LangScheduleOff}</p>)
                                    : (<p className='mb-0 py-1 px-2 d-inline text-nowrap rounded' style={{ border: item.shift_work_time_id_to_data?.shift_work_pattern ? '2px solid #333' :  '2px solid #dc3545' }}>{item.shift_work_time_id_to_data?.shift_work_pattern?.name ?? <span className='txt-danger font-italic'>{LangWorkPatternDeleted}</span>} { item.shift_work_time_id_to_data?.shift_work_pattern ? `(${moment(item.shift_work_time_id_to_data?.shift_work_pattern?.time_in, 'HH:mm:ss').format('HH:mm')} - ${moment(item.shift_work_time_id_to_data?.shift_work_pattern?.time_out, 'HH:mm:ss').format('HH:mm')})` : ''} </p>)}
                                </td>
                                <td><p className='text-nowrap'>{displayDate(item.created_at, 'dddd, DD MMM YYYY @ HH:mm')}, <br /> {item.reason}</p></td>
                                <td>
                                  {item.status === 'pending' && <p className={`d-inline mb-0 px-2 py-1 ${p.redux.darkModeOn ? 'bg-dark' : 'bg-light'} text-muted rounded-sm`}>{LangPending}</p>}
                                  {item.status === 'approve' && (
                                    <div className='d-flex align-items-center px-2 border border-success text-success rounded-sm'>
                                      <FontAwesomeIcon icon={faThumbsUp} className='mr-2' />
                                      <p className='mb-0'>{LangApprovedBy} {item.status_updated_by.name}, <br /> {displayDate(item.updated_at, 'dddd, DD MMM YYYY @ HH:mm')}, <br /> {item.note ? item.note : null}</p>
                                    </div>
                                  )}
                                  {item.status === 'reject' && (
                                    <div className='d-flex align-items-center px-2 border border-danger text-danger rounded-sm'>
                                      <FontAwesomeIcon icon={faThumbsDown} className='mr-2' />
                                      <p className='mb-0'>{LangRejectedBy} {item.status_updated_by.name}, <br /> {displayDate(item.updated_at, 'dddd, DD MMM YYYY @ HH:mm')}, <br /> {item.note ? item.note : null}</p>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    )}

                <div className='card-footer'>
                  <PaginationDiv data={this.state.dataResponse} onChange={this.handlePageChange} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {(this.state.showModal === 'export')
          ? (
            <ModalApp
              title={ExportExcel}
              handleClose={() => this.setState({ showModal: '' })}
              body={
                <ShiftChangeScheduleExportComponent
                  handleClose={() => this.setState({ showModal: '' })}
                  search={this.state.search}
                  handleKeyDown={this.handleKeyDown}
                  loading2={this.state.loading2}
                  handleChangeSearch={(e) => this.setState({ search: e.target.value })}
                  handleClickSearch={() => this.setState({ loading2: true }, () => this.loadData())}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  handleDateChange={this.handleDateChange}
                  groupId={this.state.groupId}
                  handleChangeGroup={(e) => this.handleGroup(e.target.value)}
                  dataStatus={this.state.dataStatus}
                  status={this.state.status}
                  handleChangeStatus={(e) => this.handleStatus(e.target.value)}
                />
            }
            />
            )
          : null}
      </>
    )
  }
}

export default connect(mapStateToProps)(ShiftChangeScheduleRecap)
