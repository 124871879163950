export const getAdditionalFieldsValues = (data) => {
  const before = data?.before ?? null
  const after = data?.after ?? null

  if (after && before) {
    let result = {}
    const afterResult = []
    const beforeResult = []
    after.forEach((item, i) => {
      if ((item?.label !== before[i]?.label || item?.placeholder !== before[i]?.placeholder || item?.required !== before[i]?.required || item?.show_on_form !== before[i]?.show_on_form || item?.options !== before[i]?.options)) {
        afterResult.push({ ...item })
        beforeResult.push({ ...before[i] })
      }
    })
    result = { after: afterResult, before: beforeResult }
    return result
  } else {
    const result = data.map((item, i) => {
      return item
    })
    return result
  }
}
