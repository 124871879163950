// Login
export const LangCompanyLogin = (lang) => (
    lang === 'EN' ? 'Company Account Login' : 'Masuk Akun Perusahaan'
)

export const LangPleaseLogin = (lang) => (
    lang === 'EN' ? 'Please log in using your company account as an admin or supervisor.' : 'Silakan masuk menggunakan akun perusahaan sebagai admin atau supervisor.'
)

export const LangAuthEmail = (lang) => (
    lang === 'EN' ? 'Email' : 'Email'
)

export const LangAuthPassword = (lang) => (
    lang === 'EN' ? 'Password' : 'Kata Sandi'
)

export const LangForgotYourPassword = (lang) => (
    lang === 'EN' ? 'Forgot your password?' : 'Lupa kata sandi?'
)

export const LangLogIn = (lang) => (
    lang === 'EN' ? 'Log In' : 'Masuk'
)

export const LangOr = (lang) => (
    lang === 'EN' ? 'Or' : 'Atau'
)

export const LangSigninWithGoogle = (lang) => (
    lang === 'EN' ? 'Sign in with Google Account' : 'Masuk dengan akun Google'
)

export const LangDontHaveCompanyAccount = (lang) => (
    lang === 'EN' ? "Don't have a company account?" : 'Belum punya akun perusahaan?'
)

export const LangSignUpNow = (lang) => (
    lang === 'EN' ? 'Sign Up Now' : 'Daftar Sekarang'
)

// Carrousel
export const LangManageEmployeePerformance = (lang) => (
    lang === 'EN' ? 'Manage Employee Performance' : 'Kelola kinerja karyawan'
)

export const LangManageEmployeePerformanceDesc = (lang) => (
    lang === 'EN' ? 'Company administration becomes more organized with facilities for storing comprehensive employee data, thus facilitating and saving the company time in managing employee data.' : 'Administrasi perusahaan akan lebih rapi karena adanya fasilitas untuk menyimpan data karyawan yang lengkap sehingga memudahkan dan menghemat waktu perusahaan dalam mengelola data karyawan.'
)

export const LangWorkReports = (lang) => (
    lang === 'EN' ? 'Work Reports' : 'Laporan Pekerjaan'
)

export const LangWorkReportsDesc = (lang) => (
    lang === 'EN' ? 'Employee performance becomes more optimal as the company can know the detailed work plans and daily work reports of employees per day.' : 'Kinerja karyawan lebih optimal karena perusahaan jadi bisa mengetahui detail rencana kerja dan laporan kerja harian karyawan per hari.'
)

export const LangPerformanceStatistics = (lang) => (
    lang === 'EN' ? 'Performance Statistics' : 'Statistik Performa'
)

export const LangPerformanceStatisticsDesc = (lang) => (
    lang === 'EN' ? 'Everything about employee performance, discipline, and effectiveness can be easily viewed and measured by the company.' : 'Segala hal tentang performa, kedisiplinan, dan efektivitas kinerja karyawan, bisa dengan mudah dilihat dan diukur oleh perusahaan.'
)

// Forgot Password
export const LangForgotPassword = (lang) => (
    lang === 'EN' ? 'Forgot Password' : 'Lupa Kata Sandi'
)

export const LangPleaseEnterYourEmail = (lang) => (
    lang === 'EN' ? 'Please enter your email address, we will send you a link to reset your password.' : 'Silakan masukkan alamat email Anda, kami akan mengirimkan link untuk mengatur ulang kata sandi Anda.'
)

export const LangYourEmailAddress = (lang) => (
    lang === 'EN' ? 'Your email address' : 'Alamat email Anda'
)

export const LangSendLink = (lang) => (
    lang === 'EN' ? 'Send Link' : 'Kirim Link'
)

// Reset Password
export const LangSetPassword = (lang) => (
    lang === 'EN' ? 'Set Password' : 'Atur Kata Sandi'
)

export const LangIncompleteParameters = (lang) => (
    lang === 'EN' ? 'Incomplete parameters.' : 'Parameter tidak lengkap.'
)

export const LangLogin = (lang) => (
    lang === 'EN' ? 'Login' : 'Masuk'
)

export const LangPleaseEnterNewPassword = (lang) => (
    lang === 'EN' ? 'Please enter your new password.' : 'Silakan masukkan kata sandi baru Anda.'
)

export const LangEmailAddress = (lang) => (
    lang === 'EN' ? 'Email Address' : 'Alamat Email'
)

export const LangNewPassword = (lang) => (
    lang === 'EN' ? 'New Password' : 'Kata Sandi Baru'
)

export const LangConfirmPassword = (lang) => (
    lang === 'EN' ? 'Confirm Password' : 'Konfirmasi Kata Sandi'
)

export const LangProcess = (lang) => (
    lang === 'EN' ? 'Process' : 'Proses'
)

// Register
export const LangContact = (lang) => (
    lang === 'EN' ? 'Contact' : 'Hubungi'
)

export const LangUs = (lang) => (
    lang === 'EN' ? 'Us' : 'Kami'
)

export const LangSchedule = (lang) => (
    lang === 'EN' ? 'Schedule' : 'Jadwalkan'
)

export const LangDemo = (lang) => (
    lang === 'EN' ? 'a Demo' : 'Demo'
)

export const LangCompanyRegistration = (lang) => (
    lang === 'EN' ? 'Company Registration' : 'Daftar Perusahaan'
)

export const LangPleaseRegister = (lang) => (
    lang === 'EN' ? 'Please register your company to start using the Kerjoo application.' : 'Silakan daftarkan perusahaan Anda untuk mulai menggunakan aplikasi Kerjoo.'
)

export const LangFreeTrial = (lang) => (
    lang === 'EN' ? 'FREE 14-Day Trial!' : 'GRATIS TRIAL 14 Hari!'
)

export const LangPleaseCheckEmail = (lang) => (
    lang === 'EN' ? 'Please check your email for login information.' : 'Silakan cek email Anda untuk informasi akun login Kerjoo.'
)

export const LangYourFullName = (lang) => (
    lang === 'EN' ? 'Your Full Name' : 'Nama lengkap Anda'
)

export const LangWhatsApp = (lang) => (
    lang === 'EN' ? 'WhatsApp' : 'WhatsApp'
)

export const LangMakeSureEmail = (lang) => (
    lang === 'EN' ? 'Make sure the email address you provide is correct.' : 'Pastikan alamat email yang Anda tulis sudah benar.'
)

export const LangCompanyName = (lang) => (
    lang === 'EN' ? 'Company Name' : 'Nama Perusahaan'
)

export const LangJobPosition = (lang) => (
    lang === 'EN' ? 'Job Position' : 'Jabatan'
)

export const LangPositionOwner = (lang) => (
    lang === 'EN' ? 'Owner' : 'Pemilik'
)

export const LangPositionHrd = (lang) => (
    lang === 'EN' ? 'HRD/Management/Finance' : 'HRD/Manajemen/Keuangan'
)

export const LangPositionEmployee = (lang) => (
    lang === 'EN' ? 'Employee' : 'Karyawan'
)

export const LangTimeZone = (lang) => (
    lang === 'EN' ? 'Time Zone' : 'Zona Waktu'
)

export const LangRegister = (lang) => (
    lang === 'EN' ? 'Register' : 'Daftar'
)

export const LangAlreadyHaveCompanyAcc = (lang) => (
    lang === 'EN' ? 'Already have a company account?' : 'Sudah punya akun perusahaan?'
)

export const LangSignInNow = (lang) => (
    lang === 'EN' ? 'Sign in Now' : 'Masuk Sekarang'
)

export const LangRegistrationSuccessful = (lang) => (
    lang === 'EN' ? 'Registration Successful' : 'Pendaftaran Berhasil'
)

export const LangHello = (lang) => (
    lang === 'EN' ? 'Hello' : 'Halo'
)

export const LangThankYouRegister = (lang) => (
    lang === 'EN' ? 'Thank you for registering with Kerjoo.' : 'Terima kasih sudah melakukan pendaftaran di Kerjoo.'
)

export const LangPleaseActivate = (lang) => (
    lang === 'EN' ? 'Please activate your account by clicking the link sent to your email' : 'Silakan lakukan aktivasi akun Anda melalui link yang sudah dikirimkan ke email'
)

export const LangPleaseActivate2 = (lang) => (
    lang === 'EN' ? "If you haven't received the activation email in more than 30 minutes, please check your spam folder or contact" : 'Jika Anda belum menerima email aktivasi lebih dari 30 menit silakan cek folder spam atau menghubungi'
)

// Register Select Role
export const LangGetStarted = (lang) => (
    lang === 'EN' ? 'Get Started with Kerjoo' : 'Mulai dengan Kerjoo'
)

export const LangChooseYourRole = (lang) => (
    lang === 'EN' ? 'Choose your role' : 'Pilih peran Anda'
)

export const LangImAnEmployee = (lang) => (
    lang === 'EN' ? 'I am an employee' : 'Saya adalah Karyawan'
)

export const LangImAnOwner = (lang) => (
    lang === 'EN' ? 'I am a business owner' : 'Saya adalah Pemilik Bisnis'
)

export const LangJoinYourCompany = (lang) => (
    lang === 'EN' ? 'Join your company\'s workspace as a team member.' : 'Bergabunglah dengan ruang kerja perusahaan Anda sebagai anggota tim.'
)

export const LangRegisterYourCompany = (lang) => (
    lang === 'EN' ? 'Register your company on Kerjoo to manage your team\'s time and attendance' : 'Daftarkan perusahaan Anda di Kerjoo untuk mengatur waktu dan kehadiran tim Anda.'
)

export const LangPleaseContact = (lang) => (
    lang === 'EN' ? <span>Please <b>contact your company admin</b> to add you as an employee.</span> : <span>Silakan <b>hubungi admin perusahaan Anda</b>, untuk menambahkan Anda sebagai Karyawan.</span>
)

export const LangMakeSure = (lang) => (
    lang === 'EN' ? 'Make sure your company has used Kerjoo attendance application.' : 'Pastikan perusahaan Anda sudah menggunakan aplikasi absensi Kerjoo.'
)

export const LangContinue = (lang) => (
    lang === 'EN' ? 'Continue' : 'Lanjut'
)
