import React from 'react'
import { LangEmpty, LangNotSetYet } from '../../constant/languange'

function HistoryItemFieldComponent ({ item, ...props }) {
  const itemValue = (value, children) => {
    return value == null || typeof value === 'string' || typeof value === 'number'
      ? <u>{children}</u>
      : <span>{children}</span>
  }

  return (
    <div>
      {item.value
        ? (
          <p className='mb-0'>
            <b>{item?.label}: </b>
            {itemValue(
              item?.value,
              <>
                {/* {item?.label === AdditionalField
                  ? <>{item?.label === AdditionalField && item?.value ? item.value : LangNotSetYet}</>
                  : <>{item?.value !== undefined
                    ? item?.value ? typeof item?.value !== 'object' ? item?.value : LangNotSetYet : LangEmpty
                    : LangNotSetYet} */}
                {item.value
                  ? item?.value !== undefined
                      ? item?.value ?? LangEmpty
                      : LangNotSetYet
                  : LangNotSetYet}
              </>
            )}
          </p>
          )
        : null}
    </div>
  )
}

export default HistoryItemFieldComponent
