import React from 'react'
import { AsyncSelectInput } from '../../helpers/Input'
import { connect, mapStateToProps, SpinnerButton, axiosCompany, loadPersonnelOptions } from '../../helpers/Common'
import { Division, Close, LangSpesificEmployee, Process, LangImportShiftHelp1 } from '../../constant/languange'

class ClientImportFormatComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      personnels: [],
      groupId: '',
      errors: {},
      onSubmit: false,
      errorMessage: ''
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, errorMessage: '' })

    const formData = new window.FormData()
    if (this.state.groupId) {
      formData.append('group_id', this.state.groupId)
    } else if (this.state.personnels.length) {
      this.state.personnels.forEach((item) => {
        formData.append('personnel_id[]', item.value)
      })
    }

    axiosCompany().post('client-visit-clients/format-import-excel', formData, { responseType: 'blob' }).then((result) => {
      const url = window.URL.createObjectURL(new window.Blob([result.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'kerjoo-format-import-client.xlsx')
      document.body.appendChild(link)
      link.click()

      this.setState({
        onSubmit: false
      })
    }).catch((error) => {
      const reader = new window.FileReader()

      reader.addEventListener('loadend', (e) => {
        const text = e.srcElement.result
        const data = JSON.parse(text)

        if (data.errors) {
          this.setState({ errors: data.errors })
        }

        if (data.message) {
          this.setState({ errorMessage: data.message })
        }

        this.setState({ onSubmit: false })
      })

      reader.readAsText(error.response.data)
    })
  }

  render () {
    const s = this.state
    const p = this.props

    return (
      <>
        <div className={`alert ${p.redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
          {LangImportShiftHelp1}
        </div>

        <form onSubmit={(e) => this.handleSubmit(e)}>
          {(s.personnels.length <= 0)
            ? (
              <div className='form-group row'>
                <label className='col-sm-3 col-form-label text-lg-right'>{Division}</label>
                <div className='col-sm-8'>
                  <select className='form-control' onChange={(e) => this.setState({ groupId: e.target.value })}>
                    <option value=''>--{Division}--</option>
                    {p.groups.map((obj) => {
                      return (
                        <option key={obj.id} value={obj.id}>{obj.name}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              )
            : null}

          {(!s.groupId)
            ? (
              <div className='form-group row'>
                <label className='col-sm-3 col-form-label text-lg-right'>{LangSpesificEmployee}</label>
                <div className='col-sm-8'>
                  <AsyncSelectInput
                    isLoading={false}
                    isClearable
                    isMulti
                    loadOptions={loadPersonnelOptions}
                    value={s.personnels}
                    onChange={(value) => this.setState({ personnels: value })}
                  />
                  {(s.errors.personnel_id) ? <div className='invalid-feedback d-block'><strong>{s.errors.personnel_id}</strong></div> : null}
                </div>
              </div>
              )
            : null}

          <hr />
          <div className='form-group row'>
            <div className='col-sm-8 offset-md-3'>
              {s.errorMessage ? <div className='alert alert-danger'>{s.errorMessage}</div> : null}
              <button disabled={s.onSubmit} type='submit' className='btn btn-primary'>{(s.onSubmit) ? SpinnerButton : null} {Process}</button>
              &nbsp;
              <button onClick={p.handleClose} disabled={s.onSubmit} type='button' className='btn btn-light'>{Close}</button>
            </div>
          </div>
        </form>
      </>
    )
  }
}

export default connect(mapStateToProps)(ClientImportFormatComponent)
