import React from 'react'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  axiosCompany
} from '../../helpers/Common'
import {
  Division,
  LangRequestSuccess,
  DateLang,
  LangMaxRangeExport60Day,
  LangExportExcelSendToTime,
  LangExportExcelSendToHelp,
  LangExportExcelSendTo,
  Process,
  Close
} from '../../constant/languange'
import { InputRangeDatePicker } from '../../helpers/Input'

class VisitSummaryExportComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      groupId: props.groupId,
      startDate: props.startDate,
      endDate: props.endDate,
      sendTo: props.redux.profile.email,
      onSubmit: false,
      errors: {},
      errorMessage: '',
      formatId: 1
    }

    this.handleSubmitRange = this.handleSubmitRange.bind(this)
  }

  handleSubmitRange (e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, errorMessage: '', successMessage: '' })

    const formData = new window.FormData()
    formData.append('date_from', this.state.startDate)
    formData.append('date_to', this.state.endDate)
    formData.append('format_id', this.state.formatId)
    if (this.state.groupId) {
      formData.append('group_id', this.state.groupId)
    }

    if (this.state.sendTo) {
      this.state.sendTo.split(',').forEach((item) => {
        formData.append('send_to[]', item.trim())
      })
    } else {
      formData.append('send_to[]', this.props.redux.profile.email)
      this.setState({ sendTo: this.props.redux.profile.email })
    }

    axiosCompany().post('client-visits/summaries-xlsx', formData).then(() => {
      this.setState({
        onSubmit: false,
        successMessage: LangRequestSuccess
      })
    }).catch((error) => {
      if (error.response.data.errors) {
        this.setState({ errors: error.response.data.errors }, () => {
          Object.keys(this.state.errors).forEach((key) => {
            if (key.indexOf('send_to.') !== -1) {
              this.setState({ errors: Object.assign({}, this.state.errors, { send_to: this.state.errors[key] }) })
            }
          })
        })
      } else {
        this.setState({ errors: { 0: [error.response.statusText] } })
      }

      if (error.response.data.message) {
        this.setState({ errorMessage: error.response.data.message })
      }

      this.setState({ onSubmit: false, saveToDraft: false })
    })
  }

  render () {
    const s = this.state
    const p = this.props
    return (
      <form onSubmit={this.handleSubmitRange}>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{DateLang} <b className='text-danger'>*</b></label>
          <div className='col-sm-9'>
            <InputRangeDatePicker
              startDate={s.startDate}
              endDate={s.endDate}
              onChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
            />
            <div className={`alert ${p.redux.darkModeOn ? 'alert-dark' : 'alert-light'} mt-2 mb-2`}>
              {LangMaxRangeExport60Day}
            </div>

            {(s.errors.date_from) ? <div className='invalid-feedback d-block'><strong>{s.errors.date_from}</strong></div> : null}
            {(s.errors.date_to) ? <div className='invalid-feedback d-block'><strong>{s.errors.date_to}</strong></div> : null}
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{Division}</label>
          <div className='col-sm-8'>
            <select className='form-control' value={s.groupId} onChange={(e) => this.setState({ groupId: e.target.value })}>
              <option value=''>--{Division}--</option>
              {p.groups.map((obj) => {
                return (
                  <option key={obj.id} value={obj.id}>{obj.name}</option>
                )
              })}
            </select>
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangExportExcelSendTo} <b className='text-danger'>*</b></label>
          <div className='col-sm-9'>
            <input onChange={(e) => this.setState({ sendTo: e.target.value })} value={s.sendTo} type='text' className='form-control' />
            <small className='form-text text-muted'>{LangExportExcelSendToHelp}</small>
            {(s.errors.send_to) ? <div className='invalid-feedback d-block'><strong>{s.errors.send_to}</strong></div> : null}
          </div>
        </div>

        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>Format</label>
          <div className='col-sm-9'>
            <select className='form-control' onChange={(e) => this.setState({ formatId: e.target.value })} style={{ maxWidth: 300 }}>
              <option value='1'>Lengkap dengan detail tanggal</option>
              <option value='2'>Lengkap tanpa detail tanggal</option>
              <option value='3'>Hanya detail tanggal</option>
            </select>
          </div>
        </div>

        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            <div className='alert alert-primary'>
              {LangExportExcelSendToTime}
            </div>
          </div>
        </div>

        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            {s.errorMessage ? <div className='alert alert-danger'>{s.errorMessage}</div> : null}
            {s.successMessage ? <div className='alert alert-success'>{s.successMessage}</div> : null}
            <button disabled={s.onSubmit} type='submit' className='btn btn-primary'>{(s.onSubmit) ? SpinnerButton : null} {Process}</button>
            &nbsp;
            <button onClick={p.handleClose} disabled={s.onSubmit} type='button' className='btn btn-light'>{Close}</button>
          </div>
        </div>
      </form>
    )
  }
}
export default connect(mapStateToProps)(VisitSummaryExportComponent)
