import React from 'react'
import { LangSteps61 } from '../../../constant/languange'
import Content from '../Content'

const Sixth1 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps61],
    button: 'Mulai Lengkapi Data Perusahaan',
    nextStep: 7,
    skipTo: '/dashboard',
    isDone: props.stats.is_complete_profile
  }

  return <Content {...props} data={data} />
}

export default Sixth1
