import { checkAttributesEqualityArrayOfObject, checkAttributesEqualityObject } from './helper'

export function checkAttrValue (data, attrName, formData) {
  if (data?.data?.attr?.after[attrName] && data?.data?.attr?.before[attrName]) {
    const afterData = data?.data?.attr?.after[attrName]
    const beforeData = data?.data?.attr?.before[attrName]

    if (formData === 'arrayofobject') {
      if (checkAttributesEqualityArrayOfObject(afterData, beforeData)) {
        delete data.data[attrName]
      }
    }

    if (formData === 'object') {
      if (checkAttributesEqualityObject(afterData, beforeData)) {
        delete data.data.auto_reload_annual_leave
      }
    }
  }
}
