import React from 'react'
import { useHistory } from 'react-router-dom'

import check from '../../assets/images/tour-guide/check.png'
import arrow from '../../assets/images/tour-guide/arrow.png'
import { connect, mapStateToProps, mapDispatchToProps, cookies } from '../../helpers/Common'

const TaskItem = (props) => {
  const history = useHistory()

  const doTask = () => {
    props.setState({ isTourOpen: true, openIndicator: false, tourName: props.task.codeName })
    cookies.set(`showAlertProgress-${props.redux.profile.id}`, 1)

    history.push(props.task.link)

    // cookies.set(`openTourIndicator-${props.redux.profile.id}`, 0)
  }

  if (props.task.type === 'done') {
    return (
      <li className='d-flex align-items-center' style={{ padding: '16px 20px', background: '#E9F4FF', borderRadius: '8px', marginBottom: '12px', cursor: 'default' }}>
        <img src={check} alt='checked' width={24} style={{ marginRight: '20px' }} />
        <p className='mr-auto mb-0 text-dark' style={{ fontSize: '16px', fontWeight: '500' }}>{props.task.name}</p>
      </li>
    )
  }

  if (props.task.type === 'todo') {
    return (
      <li onClick={doTask} className='d-flex align-items-center' style={{ padding: '16px 20px', background: '#F3F3F3', borderRadius: '8px', marginBottom: '12px', cursor: 'pointer' }}>
        <div className='rounded-circle' style={{ border: '1px solid #A0A0A0', width: '24px', height: '24px', marginRight: '20px' }} />
        <p className='mr-auto mb-0 text-dark' style={{ fontSize: '16px', fontWeight: '500' }}>{props.task.name}</p>
        <img src={arrow} alt='arrow' width={20} />
      </li>
    )
  }

  if (props.task.type === 'muted') {
    return (
      <li className='d-flex align-items-center' style={{ padding: '16px 20px', background: '#F3F3F3', borderRadius: '8px', marginBottom: '12px', filter: 'opacity(40%)', cursor: 'default' }}>
        <div className='rounded-circle' style={{ border: '1px solid #A0A0A0', width: '24px', height: '24px', marginRight: '20px' }} />
        <p className='mr-auto mb-0 text-muted' style={{ fontSize: '16px', fontWeight: '500' }}>{props.task.name}</p>
      </li>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskItem)
