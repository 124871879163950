import React, { useEffect, useRef } from 'react'
import { Row } from 'reactstrap'
import LeftHeader from './leftbar'
import RightHeader from './rightbar'
import { connect, mapDispatchToProps, mapStateToProps } from '../../helpers/Common'

const Header = (props) => {
  const headerRef = useRef(null)

  useEffect(() => {
    props.setState({ headerHeight: headerRef.current.offsetHeight })
    // eslint-disable-next-line
  }, [headerRef?.current?.offsetHeight])

  return (
    <>
      <div ref={headerRef} className='page-header'>
        <Row className='header-wrapper m-0'>
          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
