import React from 'react'
import Content from './Content'
import { LangTitleTour1 } from '../../constant/languange'

const First = (props) => {
  const data = {
    title: LangTitleTour1,
    steps: [
      (<>Klik menu Kelola Karyawan &rarr; Divisi</>),
      'Klik tombol tambah divisi'
    ],
    button: 'Mulai Atur Divisi',
    nextStep: 2,
    skipTo: '/work/day',
    isDone: props.stats.groups_count
    // link: '/manage/group'
  }

  return <Content {...props} data={data} />
}

export default First
