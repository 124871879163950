import { faMoneyCheck, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { connect } from 'react-redux'
import { LangAddEmployee, LangBPJSEmployee, LangUpdateEmployee } from '../../constant/languange'
import { mapStateToProps } from '../../helpers/Common'

function PersonnelImportComponent ({ redux, setControl }) {
  return (
    <>
      <div className='d-flex w-100'>
        <button className='m-1 btn btn-light d-flex flex-column align-items-center py-3' style={{ flex: 1 }} onClick={() => { setControl(control => ({ ...control, showModal: 'import-create' })) }}>
          <FontAwesomeIcon icon={faUserPlus} size='3x' />
          <span className='mt-2'>{LangAddEmployee}</span>
        </button>
        <button className='m-1 btn btn-light d-flex flex-column align-items-center py-3' style={{ flex: 1 }} onClick={() => { setControl(control => ({ ...control, showModal: 'import-update' })) }}>
          <FontAwesomeIcon icon={faUserEdit} size='3x' />
          <span className='mt-2'>{LangUpdateEmployee}</span>
        </button>
        <button className='m-1 btn btn-light d-flex flex-column align-items-center py-3' style={{ flex: 1 }} onClick={() => { setControl(control => ({ ...control, showModal: 'import-bpjs' })) }}>
          <FontAwesomeIcon icon={faMoneyCheck} size='3x' />
          <span className='mt-2'>{LangBPJSEmployee}</span>
        </button>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(PersonnelImportComponent)
