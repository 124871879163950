import React, { useState } from 'react'
import { Close, CompanyAddress, CompanyName, LangAppearance, LangChangeFile, LangChoose, LangPBgCalendarMaxSize, LangPictureMaxSize, LangPrint, LangSocialMedia, LangYear, Save } from '../../constant/languange'
import { ShowImage, SpinnerButton, axiosCompany, cookies, objectToFormData } from '../../helpers/Common'
import ReactToPrint from 'react-to-print'
import { InputFileNew } from '../../helpers/Input'
import headerBg1 from '../../assets/images/calendar/header-bg-kalendar-1.jpg'
import headerBg2 from '../../assets/images/calendar/header-bg-kalendar-2.jpg'
import headerBg3 from '../../assets/images/calendar/header-bg-kalendar-3.jpg'
import headerBg4 from '../../assets/images/calendar/header-bg-kalendar-4.jpg'

const providedHeaderBgOptions = [
  { value: 'l1', image: headerBg1 },
  { value: 'l2', image: headerBg2 },
  { value: 'l3', image: headerBg3 },
  { value: 'l4', image: headerBg4 }
]

function EditInfoPrintComponent ({ initData, currentYear, setCurrentYear, currentHeaderBgId, setCurrentHeaderBgId, uploadedHeaderBgUrl, handleClose, onSave, calenderComponentRef, ...props }) {
  // state
  const [showLoad, setShowLoad] = useState({ save: false, savePrint: false })
  const [form, setForm] = useState({
    _method: 'PUT',
    template_id: initData?.template_id || 1,
    logo: '',
    image_header: '',
    name: initData?.name || '',
    phone: initData?.phone || '',
    address: initData?.address || '',
    email: initData?.email || '',
    twitter: initData?.twitter || '',
    youtube: initData?.youtube || '',
    instagram: initData?.instagram || '',
    facebook: initData?.facebook || ''
  })
  const [year, setYear] = useState(currentYear)
  const [headerBgId, setHeaderBgId] = useState(currentHeaderBgId)
  const [responseData, setResponseData] = useState({})

  // handler
  const handleChangeText = (e) => {
    if (e.target.name === 'year') {
      setYear(currYear => +e.target.value)
      cookies.set('calendar-year', +e.target.value)
      return
    }

    setForm(currForm => ({ ...currForm, [e.target.name]: e.target.value }))
  }

  const handleChooseBg = (e) => {
    setHeaderBgId(e.target.value)
    cookies.set('calendar-header-bg-id', e.target.value)
  }

  const handleSubmit = async (cb, type) => {
    setShowLoad(currShowLoad => ({ ...currShowLoad, [type]: true }))
    const payload = objectToFormData(form)
    let successPaintedPaper = false
    await axiosCompany().post('company/calendar-setting', payload)
      .then(res => {
        setResponseData(currResponseData => res.data)
        setCurrentYear(year)
        if (form.image_header) {
          setCurrentHeaderBgId('u1')
        } else {
          setCurrentHeaderBgId(headerBgId)
        }
        cb(res.data)
        successPaintedPaper = true
      })
      .catch(err => {
        setResponseData(currResponseData => err.response.data)
      })
      .finally(() => {
        setShowLoad(currShowLoad => ({ ...currShowLoad, [type]: false }))
      })

    if (type === 'savePrint') return successPaintedPaper
  }

  return (
    <>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Logo</label>
        <div className='col-sm-9'>
          <div>
            {initData?.logo_url
              ? (
                <div className='col-md-6 mb-3 pl-0'>
                  <ShowImage url={initData.logo_url.m} className='img-thumbnail full-width' alt='Personnel ID' style={{ marginBottom: 10 }} defaultpictureonload={1} />
                  <br />
                  {LangChangeFile}:
                </div>
                )
              : null}
          </div>

          <InputFileNew
            help={LangPictureMaxSize}
            value={form?.logo}
            onChange={(logo) => { setForm(currForm => ({ ...currForm, logo })) }}
            error={responseData?.errors?.logo?.[0]}
            accept='image/png,image/jpeg'
          />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{CompanyName}</label>
        <div className='col-sm-9'>
          <input type='text' name='name' value={form.name} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{CompanyAddress}</label>
        <div className='col-sm-9'>
          <input type='text' name='address' value={form.address} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Telp</label>
        <div className='col-sm-9'>
          <input type='text' name='phone' value={form.phone} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Email</label>
        <div className='col-sm-9'>
          <input type='email' name='email' value={form.email} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      {/* field year khusus buat filter holidays */}
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangYear}</label>
        <div className='col-sm-9'>
          <input type='text' name='year' value={year} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div>
        <hr className='mb-2 mt-4' />
        <p className='text-muted'>{LangSocialMedia}</p>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Instagram</label>
        <div className='col-sm-9'>
          <input type='text' name='instagram' value={form.instagram} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Youtube</label>
        <div className='col-sm-9'>
          <input type='text' name='youtube' value={form.youtube} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Twitter</label>
        <div className='col-sm-9'>
          <input type='text' name='twitter' value={form.twitter} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Facebook</label>
        <div className='col-sm-9'>
          <input type='text' name='facebook' value={form.facebook} onChange={handleChangeText} className='form-control' />
        </div>
      </div>
      <div>
        <hr className='mb-2 mt-4' />
        <p className='text-muted'>{LangAppearance}</p>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>Background Header</label>
        <div className='col-sm-9'>
          <div>
            <ul class='nav nav-tabs' id='bgHeaderMenu' role='tablist'>
              <li class='nav-item'><a class='nav-link active' id='choose-tab' data-toggle='tab' href='#choose' role='tab' aria-controls='choose' aria-selected='true'>{LangChoose}</a></li>
              <li class='nav-item'><a class='nav-link' id='upload-tab' data-toggle='tab' href='#upload' role='tab' aria-controls='upload' aria-selected='false'>Upload</a></li>
            </ul>
            <div class='tab-content' id='bgHeaderMenuContent'>
              <div class='tab-pane fade show active' id='choose' role='tabpanel' aria-labelledby='choose-tab'>
                <div className='form-group m-checkbox-inline mb-0 ml-2 row align-items-start'>
                  {/* bg header yang pernah diupload */}
                  {uploadedHeaderBgUrl && (
                    <div className='col-md-6'>
                      <div className='radio radio-primary'>
                        <input id='u1' type='radio' value='u1' onChange={handleChooseBg} checked={headerBgId === 'u1'} />
                        <label className='mb-0' for='u1'>
                          <img src={uploadedHeaderBgUrl} alt='header bg options u1' style={{ width: '100%' }} />
                        </label>
                      </div>
                    </div>
                  )}
                  {/* bg header yang tersedia */}
                  {providedHeaderBgOptions.map((item, i) => (
                    <div key={item.value} className='col-md-6'>
                      <div className='radio radio-primary'>
                        <input id={item.value} type='radio' value={item.value} onChange={handleChooseBg} checked={headerBgId === item.value} />
                        <label className='mb-0' for={item.value}>
                          <img src={item.image} alt={`header bg options ${item.value}`} style={{ width: '100%' }} />
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class='tab-pane fade' id='upload' role='tabpanel' aria-labelledby='upload-tab'>
                <div className='mt-3'>
                  <div>
                    {initData?.image_header_url
                      ? (
                        <div className='col-md-6 mb-3 pl-0'>
                          <ShowImage url={initData.image_header_url.l} className='img-thumbnail full-width' alt='Personnel ID' style={{ marginBottom: 10 }} defaultpictureonload={1} />
                          <br />
                          {LangChangeFile}:
                        </div>
                        )
                      : null}
                  </div>

                  <InputFileNew
                    help={LangPBgCalendarMaxSize}
                    value={form?.image_header}
                    onChange={(image) => { setForm(currForm => ({ ...currForm, image_header: image })) }}
                    error={responseData?.errors?.image_header?.[0]}
                    accept='image/png,image/jpeg'
                  />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* error message */}
      <div className='form-group row'>
        <div className='offset-3 col-sm-9'>
          {responseData.message && (
            <div class='alert alert-danger my-2 w-100'>
              {responseData.message}
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='col-sm-12'>
          <div className='d-flex flex-column flex-md-row justify-content-between'>
            <div className='d-flex flex-column flex-md-row'>
              <button disabled={showLoad.save || showLoad.savePrint} className='btn btn-primary mb-2 mr-md-2' type='button' onClick={() => { handleSubmit(onSave, 'save') }}>{showLoad.save && SpinnerButton} {Save}</button>
              <button className='btn btn-light mb-2' type='button' onClick={() => { handleClose() }}>{Close}</button>
            </div>
            <ReactToPrint
              trigger={() => <button disabled={showLoad.save || showLoad.savePrint} className='btn btn-secondary mb-2' type='button'>{showLoad.savePrint && SpinnerButton} {Save} & {LangPrint}</button>}
              onBeforeGetContent={() => {
                const saveAndPrint = async (resolve, reject) => {
                  const success = await handleSubmit(onSave, 'savePrint')
                  setTimeout(() => {
                    if (success) {
                      resolve()
                    } else {
                      reject()
                    }
                  }, 1200)
                }

                return new Promise((resolve, reject) => {
                  saveAndPrint(resolve, reject)
                })
              }}
              content={() => calenderComponentRef.current}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default EditInfoPrintComponent
