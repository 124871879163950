import React, { useState } from 'react'
import { broadcastQuotaPrice } from '../../constant/BroadcastConstant'
import { LangOrderQuota, LangQuota } from '../../constant/languange'
import { toRupiah, SpinnerButton, mapStateToProps } from '../../helpers/Common'
import { connect } from 'react-redux'
import OrderAgreement from '../common/OrderAgreement'

function BroadcastQuotaComponent ({ redux, onUpdate, ...props }) {
  const initData = {
    quota: broadcastQuotaPrice[0].value,
    price: broadcastQuotaPrice[0].price,
    total: broadcastQuotaPrice[0].price
  }
  // const [errors, setError] = useState({ fields: {}, message: '' })
  const [control] = useState({ showSuccessAlert: false, showLoad: false })
  const [data] = useState(initData)
  const [activeSelect, setActiveSelect] = useState(0)

  const handleSubmit = () => {
    onUpdate(data)
  }

  const handleSelect = (index, currentData) => {
    data.quota = currentData.value
    data.price = +currentData.price
    data.total = +currentData.price
    setActiveSelect(index)
  }

  // const handleInput = (e) => {
  //   const value = e.target.value
  //   let price = 0
  //   const index = e.nativeEvent.target.selectedIndex
  //   price = broadcastQuotaPrice[index].price

  //   setData(data => ({ ...data, quota: value, price: price, total: price }))
  // }

  return (
    <div>
      <div className='form-group row justify-content-center'>
        <label className='col-sm-8 col-form-label font-weight-bold'>{LangQuota}</label>
        <div className='col-sm-8'>
          <div className='row'>
            {broadcastQuotaPrice.map((item, i) => (
              <div onClick={() => { handleSelect(i, item) }} key={i} className='col-12 col-sm-6 col-lg-4 pb-3'>
                <div style={{ cursor: 'pointer' }} className={`card mb-0 rounded border ${i === activeSelect ? 'border-success' : redux.darkModeOn ? 'bg-dark' : 'bg-light'}`}>
                  <div className='card-body text-center p-2'>
                    <p className={`mb-0 ${redux.darkModeOn ? 'text-light' : 'text-dark'}`}>{item.label}</p>
                    <p className='mb-0 text-muted'>Rp{toRupiah(item.price)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='form-group row justify-content-center'>
        <label className='col-sm-3 col-form-label font-weight-bold'>{LangQuota}</label>
        <div className='col-sm-5'>
          <Form.Control className={errors.fields.quota ? 'is-invalid' : ''} required as='select' onChange={(e) => { handleInput(e) }} value={data.quota}>
            {
              broadcastQuotaPrice.map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))
            }
          </Form.Control>
          {errors.fields.quota ? (<p className='mb-0' style={{ color: 'red' }}>{errors.fields.quota[0]}</p>) : (null)}
        </div>
      </div>
      <div className='form-group row justify-content-center'>
        <label className='col-sm-3 col-form-label font-weight-bold'>{LangPrice} (Rp)</label>
        <div className='col-sm-5'>
          <div className='form-control'>{toRupiah(data.price)}</div>
        </div>
      </div> */}
      <div className='form-group row justify-content-center'>
        <label className='col-sm-3 col-form-label font-weight-bold'>Total (Rp)</label>
        <div className='col-sm-5'>
          <h3 className='mb-0 mt-0'><code>{toRupiah(data.total)}</code></h3>
        </div>
      </div>
      <hr style={{ borderTopWidth: '2px', borderTopStyle: 'dashed' }} />
      <div className='form-group row'>
        <div className='col-md-7 offset-sm-5 mt-3'>
          {/* {control.showSuccessAlert && <Alert variant='success'>{LangSuccessBuyQuota}</Alert>} */}
          <button disabled={control.showLoad} onClick={handleSubmit} className='btn btn-lg btn-primary' type='submit'>{(control.showLoad) ? SpinnerButton : ''} {LangOrderQuota} &rarr;</button>
          <br />
          <OrderAgreement />
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(BroadcastQuotaComponent)
