import React from 'react'
import { LangSteps12 } from '../../../constant/languange'
import Content from '../Content'

const First2 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps12],
    button: 'Mulai Atur Divisi',
    nextStep: 2,
    skipTo: '/work/day',
    isDone: props.stats.groups_count
    // link: '/manage/group'
  }

  return <Content {...props} data={data} />
}

export default First2
