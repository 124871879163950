import { connect, mapStateToProps, toRupiah } from '../../../../helpers/Common'

const Deductions = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle w-50'>Label</th>
            <th scope='col' className='text-center align-middle w-50'>Nominal</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.label}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{toRupiah(item?.value)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(Deductions)
