import First1 from './content/add-group/First1'
import First2 from './content/add-group/First2'
import First3 from './content/add-group/First3'
import Third1 from './content/add-personnel/Third1'
import Third2 from './content/add-personnel/Third2'
import Third3 from './content/add-personnel/Third3'
import Third4 from './content/add-personnel/Third4'
import Fifth1 from './content/add-spots/Fifth1'
import Fifth2 from './content/add-spots/Fifth2'
import Fifth3 from './content/add-spots/Fifth3'
import Sixth1 from './content/company-setting/Sixth1'
import Sixth2 from './content/company-setting/Sixth2'
import Fifth from './content/Fifth'
import First from './content/First'
import Fourth from './content/Fourth'
import Second from './content/Second'
import Sixth from './content/Sixth'
import Third from './content/Third'
import Second1 from './content/work-shift-pattern/Second1'
import Second2 from './content/work-shift-pattern/Second2'
import Second3 from './content/work-shift-pattern/Second3'
import Second4 from './content/work-shift-pattern/Second4'
import Second5 from './content/work-shift-pattern/Second5'
import Fourth1 from './content/work-shift-times/Fourth1'
import Fourth2 from './content/work-shift-times/Fourth2'
import Fourth3 from './content/work-shift-times/Fourth3'

const steps = (closeTour, stats, name) => {
  const content = {
    common: [
      {
        selector: '.first-step',
        content: ({ goTo, inDOM }) => <First stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.first-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.second-step',
        content: ({ goTo, inDOM }) => <Second stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.second-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.third-step',
        content: ({ goTo, inDOM }) => <Third stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.third-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.fourth-step',
        content: ({ goTo, inDOM }) => <Fourth stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.fourth-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.fifth-step',
        content: ({ goTo, inDOM }) => <Fifth stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.fifth-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.sixth-step',
        content: ({ goTo, inDOM }) => <Sixth stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.sixth-step'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    groups_count: [
      {
        selector: '.highlight-menu-employee',
        content: ({ goTo, inDOM }) => <First1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-grup',
        content: ({ goTo, inDOM }) => <First2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-grup'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-btn-add-group',
        content: ({ goTo, inDOM }) => <First3 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-btn-add-group'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    work_and_shift_patterns_count: [
      {
        selector: '.highlight-menu-work-pattern',
        content: ({ goTo, inDOM }) => <Second1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-work-pattern'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-work-day',
        content: ({ goTo, inDOM }) => <Second2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-work-day'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-btn-add-wdp',
        content: ({ goTo, inDOM }) => <Second3 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-btn-add-wdp'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-work-shift',
        content: ({ goTo, inDOM }) => <Second4 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-work-shift'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-btn-add-wds',
        content: ({ goTo, inDOM }) => <Second5 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-btn-add-wds'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    personnels_count: [
      {
        selector: '.highlight-menu-employee',
        content: ({ goTo, inDOM }) => <Third1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-employee',
        content: ({ goTo, inDOM }) => <Third2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-btn-add-employee',
        content: ({ goTo, inDOM }) => <Third3 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-btn-add-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-import-employee',
        content: ({ goTo, inDOM }) => <Third4 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-import-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    spots_count: [
      {
        selector: '.highlight-menu-employee',
        content: ({ goTo, inDOM }) => <Fifth1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-spot-attdc',
        content: ({ goTo, inDOM }) => <Fifth2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-spot-attdc'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-btn-add-spot-attdc',
        content: ({ goTo, inDOM }) => <Fifth3 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-btn-add-spot-attdc'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    work_and_shift_times_count: [
      {
        selector: '.highlight-menu-employee',
        content: ({ goTo, inDOM }) => <Fourth1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-employee'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-wdt',
        content: ({ goTo, inDOM }) => <Fourth2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-wdt'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-wst',
        content: ({ goTo, inDOM }) => <Fourth3 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-wst'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ],
    is_complete_profile: [
      {
        selector: '.highlight-menu-settings',
        content: ({ goTo, inDOM }) => <Sixth1 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-menu-settings'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      },
      {
        selector: '.highlight-submenu-company',
        content: ({ goTo, inDOM }) => <Sixth2 stats={stats} closeTour={closeTour} goTo={goTo} />,
        highlightedSelectors: ['.highlight-submenu-company'],
        observe: '.observer-tour',
        mutationObservables: '.observer-tour',
        resizeObservables: ['.observer-tour']
      }
    ]
  }

  return content[name]
}

export default steps
