import { AfterBreak, Break, Day, LangAttendIn, LangAttendOut, LangEmpty, Type, maxBreak } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const Schedules = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle'>{Day}</th>
            <th scope='col' className='text-center align-middle'>{Type}</th>
            <th scope='col' className='text-center align-middle'>{LangAttendIn}</th>
            <th scope='col' className='text-center align-middle'>{LangAttendOut}</th>
            <th scope='col' className='text-center align-middle'>{Break}</th>
            <th scope='col' className='text-center align-middle'>{AfterBreak}</th>
            <th scope='col' className='text-center align-middle'>{maxBreak}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.day}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.type_name || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.time_in || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.time_out || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.break_range_start || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.break_range_end || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.break_max_minutes || LangEmpty}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(Schedules)
