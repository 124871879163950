import { LangAll, LangCanceled, LangCertainDivision, LangCertainEmployee, LangDone, LangPaused, LangQuotaRunsOut, LangSending, LangSetup } from './languange'

export const categoryReciver = [
  { value: 'all', label: LangAll },
  { value: 'groups', label: LangCertainDivision },
  { value: 'personnels', label: LangCertainEmployee }
]

export const statusBroadcast = {
  all: {
    name: LangAll,
    color: 'light',
    value: ''
  },
  setup: {
    name: LangSetup,
    color: 'warning',
    value: 'setup'
  },
  sending: {
    name: LangSending,
    color: 'primary',
    value: 'sending'
  },
  canceled: {
    name: LangCanceled,
    color: 'danger',
    value: 'canceled'
  },
  quota_runs_out: {
    name: LangQuotaRunsOut,
    color: 'danger',
    value: 'quota_runs_out'
  },
  paused: {
    name: LangPaused,
    color: 'info',
    value: 'paused'
  },
  done: {
    name: LangDone,
    color: 'success',
    value: 'done'
  }
}

export const broadcastQuotaPrice = [
  { price: 25000, label: 500, value: 500 },
  { price: 50000, label: 1000, value: 1000 },
  { price: 250000, label: 5000, value: 5000 }
]

export const broadcastDataDummy = [
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Semua',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Selesai',
      color: 'success'
    }
  },
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Divisi Tertentu',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Kuota Habis',
      color: 'danger'
    }
  },
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Divisi Tertentu',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Tertunda',
      color: 'warning'
    }
  },
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Karyawan Tertentu',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Mengirim',
      color: 'primary'
    }
  },
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Divisi Tertentu',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Konsep',
      color: 'warning'
    }
  },
  {
    title: 'Some Title',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum',
    target: 'Semua',
    created_by: 'Some Admin',
    created_at: '31 Nov 2021',
    status: {
      name: 'Batal',
      color: 'danger'
    }
  }
]
