import React from 'react'
import { LangSteps52 } from '../../../constant/languange'
import Content from '../Content'

const Fifth2 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps52],
    button: 'Mulai Atur Lokasi Kehadiran',
    nextStep: 6,
    skipTo: '/company/setting',
    isDone: props.stats.spots_count
  }

  return <Content {...props} data={data} />
}

export default Fifth2
