import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect, mapStateToProps, mapDispatchToProps, cookies } from './Common'
import { LangHello, LangPleaseActivate, LangPleaseActivate2, LangThankYouRegister } from '../constant/AuthConstant'

class AdminSuccess extends React.Component {
  render () {
    return (
      cookies.get('lang') === 'EN'
        ? (
          <div className='alert alert-success'> <p style={{ fontSize: 18, textAlign: 'center' }}>Data Saved Successfully! Login information sent to email {this.props.RegEmail}</p></div>
        ) : (
          <div className='alert alert-success'> <p style={{ fontSize: 18, textAlign: 'center' }}>Data Berhasil Disimpan! Informasi login dikirim ke email {this.props.RegEmail}</p></div>
        )
    )
  }
}

class Success extends React.Component {
  render () {
    return (
      cookies.get('lang') === 'EN'
        ? (
          <div className='alert alert-success'> <p style={{ fontSize: 18, textAlign: 'center' }}>Data Saved Successfully!</p></div>
        ) : (
          <div className='alert alert-success'> <p style={{ fontSize: 18, textAlign: 'center' }}>Data Berhasil Disimpan! </p></div>
        )
    )
  }
}

class ModalHeader extends React.Component {
  render () {
    return (
      cookies.get('lang') === 'EN'
        ? (
          <Modal.Title>Are you sure you want to permanently delete this data ?</Modal.Title>
        ) : (
          <Modal.Title>Anda yakin ingin menghapus data ini secara permanen?</Modal.Title>
        )
    )
  }
}

class RegSuccess extends React.Component {
  render () {
    return (
      <div style={{ alignSelf: 'center', padding: 20 }}>
        <p>
          {LangHello(this.props.lang)} {this.props.redux.register.name}, {LangThankYouRegister(this.props.lang)}
{LangPleaseActivate(this.props.lang)} {this.props.redux.register.email}. {LangPleaseActivate2(this.props.lang)}:
        </p>
      </div>
    )
  }
}

const RegisterSuccess = connect(mapStateToProps, mapDispatchToProps)(RegSuccess)

export {
  Success,
  ModalHeader,
  AdminSuccess,
  RegisterSuccess
}
