import React, { useState, useEffect, useLayoutEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { LangEn, LangId, Hi, LangActivity, LangLogout, LangSetting, AttendanceReview, LangAttendanceRevision, LangAttendanceApproval, LangNoNotification, LangNotification, LangPaidLeaveRequest, LangUnpaidLeaveRequest, LangEmployeeDocsExpired, LangEmployeeDocsInReminder, LangClaimRequest, LangPintuKerjooAttendanceApproval, LangDeviceApproval, LangLightMode, LangDarkMode, LangAutoMode, LangProfilePhotoApproval } from '../../constant/languange'
import { mapStateToProps, connect, axiosCompany, mapDispatchToProps, cookies, SpinnerButton, displayTimezone, handleDarkMode } from '../../helpers/Common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faHistory, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { ModalApp } from '../../helpers/Input'
import { useHistory } from 'react-router-dom'
import { COOKIES_PERMISSION_APPROVAL } from '../../constant/CommonConstant'

const Rightbar = (props) => {
  const history = useHistory()

  const [logout, setLogout] = useState(false)
  const [onLogout, setOnLogout] = useState(false)
  const propsName = props.redux.profile.name
  const limitName = propsName.split('')
  const name = limitName.slice(0, 8).join('')
  const [widthWindow, setWidthWindow] = useState(null)
  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false, showModalGroup: false, onLoadSwitchLang: false })
  const [notificationCount, setNotificationCount] = useState(0)
  const [lang] = useState(cookies.get('lang'))

  const updateDimensions = () => {
    setWidthWindow(window.innerWidth)
  }

  const removeCookies = () => {
    const coockiesName = ['annual_label_id', 'annual_label_en', 'token', 'session_id', 'payroll_pass', 'dismiss_alert_payroll_password', COOKIES_PERMISSION_APPROVAL]
    coockiesName.forEach(item => cookies.remove(item))
    // cookies.remove('annual_label_id')
    // cookies.remove('annual_label_en')
    // cookies.remove('token')
    // cookies.remove('sesion_id')
    window.localStorage.removeItem('filterPersonnel')
  }

  useLayoutEffect(() => {
    const mode = cookies.get('mode')
    handleDarkMode(mode, props)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (logout) {
      setOnLogout(true)
      axiosCompany().post('auth/logout').then(() => {
        removeCookies()
        window.location.reload()
      })
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => window.removeEventListener('resize', updateDimensions)
  }, [logout, widthWindow])

  useEffect(() => {
    let count = 0
    for (const [key, value] of Object.entries(props.redux.notifications)) {
      if (key !== 'personnel_document') {
        count += value
      }
    }
    setNotificationCount(count)
  }, [props.redux.notifications])

  const postLanguageUserService = (lang) => {
    setControl({ ...control, onLoadSwitchLang: true })

    axiosCompany().post('language', { lang: lang }).then(ress => {
      lang === 'en' ? LangEn() : LangId()
      setError({ fields: [], message: '' })
      // setControl({ ...control, showSuccessAlert: true, showLoad: false })
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  return (
    <>
      <div className='d-none d-md-block nav-left col left-header p-0'>
        <ul className='nav-menus w-100'>
          <li className='onhover-dropdown border rounded'>
            <div className='d-flex align-items-center'>
              {control.onLoadSwitchLang ? SpinnerButton : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 92.91"><path d="M20.15,83.63,31.63,73.4a2.89,2.89,0,0,1,1.91-.73h27.8a.92.92,0,0,0,.93-.93V65.9H68v5.84a6.71,6.71,0,0,1-6.68,6.68H34.62L19.3,92.07a2.87,2.87,0,0,1-4.9-2V78.42H6.69A6.71,6.71,0,0,1,0,71.74V28.59a6.76,6.76,0,0,1,6.69-6.68H43.35v5.75H6.69a1,1,0,0,0-.94.93V71.74a.91.91,0,0,0,.28.65,1,1,0,0,0,.66.28H17.27a2.88,2.88,0,0,1,2.88,2.88v8.08Zm.21-19.48L29.6,36.24h8.83l9.24,27.91H40.35L38.8,59.07H29.15l-1.51,5.08ZM30.79,53.24h6.37L34,41.81,30.79,53.24ZM76.63,13.35h8.7V11.11a.69.69,0,0,1,.69-.69h4.65a.68.68,0,0,1,.68.69v2.24h9.76a.68.68,0,0,1,.68.69V18.5a.68.68,0,0,1-.68.68H99.56a26.3,26.3,0,0,1-.91,3.88l0,.06a26.07,26.07,0,0,1-1.74,4.15,32.34,32.34,0,0,1-2.14,3.43c-.67,1-1.41,1.9-2.2,2.83a35.78,35.78,0,0,0,3.68,3.83,41.43,41.43,0,0,0,5.09,3.74.68.68,0,0,1,.21.94l-2.39,3.73a.69.69,0,0,1-1,.2,45.88,45.88,0,0,1-5.58-4.08l0,0a41.42,41.42,0,0,1-4-4.1C87.3,38.93,86.15,40,85,41l0,0c-1.36,1.12-2.79,2.2-4.47,3.36a.69.69,0,0,1-1-.17L77,40.53a.69.69,0,0,1,.17-1c1.66-1.14,3-2.19,4.36-3.28,1.16-1,2.28-2,3.49-3.16a44.82,44.82,0,0,1-2.77-4.45A28.84,28.84,0,0,1,80,22.9a.68.68,0,0,1,.47-.84l4.27-1.19a.68.68,0,0,1,.84.47A22.62,22.62,0,0,0,89,28.7L90.27,27a26.33,26.33,0,0,0,1.51-2.47l0,0A19.43,19.43,0,0,0,93,21.62a24,24,0,0,0,.66-2.44h-17a.69.69,0,0,1-.69-.68V14a.69.69,0,0,1,.69-.69Zm27,56.82L88.26,56.51H61.54a6.73,6.73,0,0,1-6.69-6.68V6.69a6.71,6.71,0,0,1,2-4.72l.2-.18A6.67,6.67,0,0,1,61.54,0h54.65a6.69,6.69,0,0,1,4.71,2l.19.2a6.69,6.69,0,0,1,1.79,4.51V49.83a6.73,6.73,0,0,1-6.69,6.68h-7.7V68.13a2.88,2.88,0,0,1-4.91,2ZM91.26,51.49l11.47,10.23V53.64a2.88,2.88,0,0,1,2.88-2.88h10.58a.92.92,0,0,0,.65-.28.91.91,0,0,0,.29-.65V6.69a1,1,0,0,0-.22-.58L116.84,6a1,1,0,0,0-.65-.29H61.54A.94.94,0,0,0,61,6L60.89,6a.92.92,0,0,0-.28.65V49.83a.92.92,0,0,0,.93.93H89.35a2.86,2.86,0,0,1,1.91.73Z" /></svg>}
              <span className='ml-2 text-nowrap'>{lang === 'ID' ? 'Bahasa Indonesia' : 'English'}</span>
              <i className='middle fa fa-angle-down ml-2' />
            </div>
            <ul className='notification-dropdown onhover-show-div active' style={{ top: 38, left: 0, width: 180 }}>
              <li className='p-0'></li>
              <li onClick={() => { lang === 'EN' && postLanguageUserService('id') }}>
                <p className={`link ${lang === 'ID' ? 'active' : ''}`}>Bahasa Indonesia</p>
              </li>
              <li onClick={() => { lang === 'ID' && postLanguageUserService('en') }}>
                <p className={`link ${lang === 'EN' ? 'active' : ''}`}>English</p>
              </li>
              <li className='p-0'></li>
            </ul>
          </li>

          <li className='border rounded ml-0 ml-xl-2' style={{ height: 35 }}>
            <div className='d-flex align-items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 70"><path d="M56.51,36.91A25.24,25.24,0,0,0,57,32a24.9,24.9,0,0,0-6.38-16.66.47.47,0,0,1-.06-.09h0a25,25,0,0,0-37.08,0h0a.47.47,0,0,1-.06.09,24.94,24.94,0,0,0,0,33.33l0,.07,0,0A25,25,0,0,0,32,57a25.24,25.24,0,0,0,4.91-.49,14,14,0,1,0,19.6-19.6ZM48,34a14.08,14.08,0,0,0-2.06.17c0-.39,0-.78,0-1.17h9a24.38,24.38,0,0,1-.27,2.71A13.9,13.9,0,0,0,48,34ZM14.16,46.49A22.77,22.77,0,0,1,9,33h9a43.15,43.15,0,0,0,1.24,9.5A25.38,25.38,0,0,0,14.16,46.49Zm0-29a25.38,25.38,0,0,0,5.09,4A43.15,43.15,0,0,0,18,31H9A22.77,22.77,0,0,1,14.16,17.51Zm35.68,0A22.77,22.77,0,0,1,55,31H46a43.15,43.15,0,0,0-1.24-9.5A25.38,25.38,0,0,0,49.84,17.51ZM44,31H33V25a25.09,25.09,0,0,0,9.92-2.49A41.17,41.17,0,0,1,44,31ZM33,23V9.09c4,.63,7.47,5.07,9.39,11.42A22.93,22.93,0,0,1,33,23ZM31,9.09V23a22.93,22.93,0,0,1-9.39-2.46C23.53,14.16,27,9.72,31,9.09ZM31,25v6H20a41.17,41.17,0,0,1,1.07-8.52A25.09,25.09,0,0,0,31,25ZM20,33H31v6a25.09,25.09,0,0,0-9.92,2.49A41.17,41.17,0,0,1,20,33Zm11,8V54.91c-4-.63-7.47-5.07-9.39-11.42A22.93,22.93,0,0,1,31,41Zm2,13.89V41a24.38,24.38,0,0,1,2.71.27A13.93,13.93,0,0,0,35.38,54,7.21,7.21,0,0,1,33,54.92Zm3.91-15.43A25.6,25.6,0,0,0,33,39V33H44c0,.54,0,1.08-.07,1.61A14,14,0,0,0,36.91,39.49ZM48.51,16a23.33,23.33,0,0,1-4.33,3.49A22.7,22.7,0,0,0,38.77,10,23,23,0,0,1,48.51,16ZM25.23,10a22.7,22.7,0,0,0-5.41,9.48A23.33,23.33,0,0,1,15.49,16,23,23,0,0,1,25.23,10ZM15.49,48a23.33,23.33,0,0,1,4.33-3.49A22.7,22.7,0,0,0,25.23,54,23,23,0,0,1,15.49,48ZM48,60A12,12,0,1,1,60,48,12,12,0,0,1,48,60Z" /><path d="M48,38a1,1,0,0,0-1,1v8.52l-4.62,3.7a1,1,0,0,0-.16,1.4,1,1,0,0,0,1.41.16l5-4A1,1,0,0,0,49,48V39A1,1,0,0,0,48,38Z" /></svg>
              <span className='ml-2 text-nowrap'>{displayTimezone(props.redux.profile.attr.timezone)}</span>
              {/* <i className='middle fa fa-angle-down ml-2' /> */}
            </div>
            {/* <ul className='notification-dropdown onhover-show-div active' style={{ top: 46, left: 0, width: 180 }}>
              <li className='p-0'></li>
              <li onClick={() => { timezone !== 'wib' && editTimezone('wib') }}>
                <p className={`link ${timezone === 'wib' ? 'active' : ''}`}>WIB (UTC+7)</p>
              </li>
              <li onClick={() => { timezone !== 'wita' && editTimezone('wita') }}>
                <p className={`link ${timezone === 'wita' ? 'active' : ''}`}>WITA (UTC+8)</p>
              </li>
              <li onClick={() => { timezone !== 'wit' && editTimezone('wit') }}>
                <p className={`link ${timezone === 'wit' ? 'active' : ''}`}>WIT (UTC+9)</p>
              </li>
              <li className='p-0'></li>
            </ul> */}
          </li>

          <li className='onhover-dropdown border rounded ml-0 ml-xl-2'>
            <div className='d-flex align-items-center'>
              <span>
                {
                  props.redux.mode === 'light' &&
                  <svg style={{ width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                    <path d="M30,13.21A3.93,3.93,0,1,1,36.8,9.27L41.86,18A3.94,3.94,0,1,1,35.05,22L30,13.21Zm31.45,13A35.23,35.23,0,1,1,36.52,36.52,35.13,35.13,0,0,1,61.44,26.2ZM58.31,4A3.95,3.95,0,1,1,66.2,4V14.06a3.95,3.95,0,1,1-7.89,0V4ZM87.49,10.1A3.93,3.93,0,1,1,94.3,14l-5.06,8.76a3.93,3.93,0,1,1-6.81-3.92l5.06-8.75ZM109.67,30a3.93,3.93,0,1,1,3.94,6.81l-8.75,5.06a3.94,3.94,0,1,1-4-6.81L109.67,30Zm9.26,28.32a3.95,3.95,0,1,1,0,7.89H108.82a3.95,3.95,0,1,1,0-7.89Zm-6.15,29.18a3.93,3.93,0,1,1-3.91,6.81l-8.76-5.06A3.93,3.93,0,1,1,104,82.43l8.75,5.06ZM92.89,109.67a3.93,3.93,0,1,1-6.81,3.94L81,104.86a3.94,3.94,0,0,1,6.81-4l5.06,8.76Zm-28.32,9.26a3.95,3.95,0,1,1-7.89,0V108.82a3.95,3.95,0,1,1,7.89,0v10.11Zm-29.18-6.15a3.93,3.93,0,0,1-6.81-3.91l5.06-8.76A3.93,3.93,0,1,1,40.45,104l-5.06,8.75ZM13.21,92.89a3.93,3.93,0,1,1-3.94-6.81L18,81A3.94,3.94,0,1,1,22,87.83l-8.76,5.06ZM4,64.57a3.95,3.95,0,1,1,0-7.89H14.06a3.95,3.95,0,1,1,0,7.89ZM10.1,35.39A3.93,3.93,0,1,1,14,28.58l8.76,5.06a3.93,3.93,0,1,1-3.92,6.81L10.1,35.39Z" />
                  </svg>
                }
                {
                  props.redux.mode === 'dark-only' &&
                  <svg style={{ fillRule: 'evenodd', width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.56 122.88">
                    <path d="M121.85,87.3A64.31,64.31,0,1,1,36.88.4c2.94-1.37,5.92.91,4.47,4.47a56.29,56.29,0,0,0,75.75,77.4l.49-.27a3.41,3.41,0,0,1,4.61,4.61l-.35.69ZM92.46,74.67H92A16.11,16.11,0,0,0,76.2,58.93v-.52a15.08,15.08,0,0,0,11-4.72,15.19,15.19,0,0,0,4.72-11h.51a15.12,15.12,0,0,0,4.72,11,15.12,15.12,0,0,0,11,4.72v.51A16.13,16.13,0,0,0,92.46,74.67Zm10.09-46.59h-.27a7.94,7.94,0,0,0-2.49-5.81A7.94,7.94,0,0,0,94,19.78v-.27A7.94,7.94,0,0,0,99.79,17a8,8,0,0,0,2.49-5.8h.27A8,8,0,0,0,105,17a8,8,0,0,0,5.81,2.49v.27A8,8,0,0,0,105,22.27a7.94,7.94,0,0,0-2.49,5.81Zm-41.5,8h-.41a12.06,12.06,0,0,0-3.78-8.82A12.06,12.06,0,0,0,48,23.5v-.41a12.07,12.07,0,0,0,8.82-3.78,12.09,12.09,0,0,0,3.78-8.82h.41a12.08,12.08,0,0,0,3.77,8.82,12.09,12.09,0,0,0,8.83,3.78v.41a12.09,12.09,0,0,0-8.83,3.78,12.08,12.08,0,0,0-3.77,8.82Z" />
                  </svg>
                }
                {
                  props.redux.mode === 'auto' &&
                  <svg style={{ width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                    <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 508.47"><path fill-rule="nonzero" d="M254.23 508.47c-3.94 0-7.87-.1-11.77-.28h-1.54v-.07c-64.9-3.34-123.37-31.04-166.45-74.12C28.46 387.99 0 324.42 0 254.23c0-70.19 28.46-133.75 74.47-179.76C117.55 31.39 176.03 3.69 240.92.34V.27h1.54c3.9-.18 7.83-.27 11.77-.27l3.46.02.08-.02c70.19 0 133.75 28.46 179.76 74.47 46 46.01 74.47 109.57 74.47 179.76S483.53 387.99 437.53 434c-46.01 46.01-109.57 74.47-179.76 74.47l-.08-.03-3.46.03zm-13.31-30.56V30.56C184.33 33.87 133.4 58.17 95.79 95.79c-40.55 40.54-65.62 96.56-65.62 158.44 0 61.89 25.07 117.91 65.62 158.45 37.61 37.61 88.54 61.91 145.13 65.23z"/></svg>
                  </svg>
                }
              </span>
              <i className='middle fa fa-angle-down ml-2' />
            </div>
            <ul className='notification-dropdown onhover-show-div active' style={{ top: 38, left: 0, width: 150 }}>
              <li className='p-0'></li>
              <li onClick={() => handleDarkMode('light', props)}>
                <p className={`link ${props.redux.mode === 'light' ? 'active' : ''} d-flex`} style={{ gap: 12 }}>
                  <svg style={{ width: 18 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                    <path d="M30,13.21A3.93,3.93,0,1,1,36.8,9.27L41.86,18A3.94,3.94,0,1,1,35.05,22L30,13.21Zm31.45,13A35.23,35.23,0,1,1,36.52,36.52,35.13,35.13,0,0,1,61.44,26.2ZM58.31,4A3.95,3.95,0,1,1,66.2,4V14.06a3.95,3.95,0,1,1-7.89,0V4ZM87.49,10.1A3.93,3.93,0,1,1,94.3,14l-5.06,8.76a3.93,3.93,0,1,1-6.81-3.92l5.06-8.75ZM109.67,30a3.93,3.93,0,1,1,3.94,6.81l-8.75,5.06a3.94,3.94,0,1,1-4-6.81L109.67,30Zm9.26,28.32a3.95,3.95,0,1,1,0,7.89H108.82a3.95,3.95,0,1,1,0-7.89Zm-6.15,29.18a3.93,3.93,0,1,1-3.91,6.81l-8.76-5.06A3.93,3.93,0,1,1,104,82.43l8.75,5.06ZM92.89,109.67a3.93,3.93,0,1,1-6.81,3.94L81,104.86a3.94,3.94,0,0,1,6.81-4l5.06,8.76Zm-28.32,9.26a3.95,3.95,0,1,1-7.89,0V108.82a3.95,3.95,0,1,1,7.89,0v10.11Zm-29.18-6.15a3.93,3.93,0,0,1-6.81-3.91l5.06-8.76A3.93,3.93,0,1,1,40.45,104l-5.06,8.75ZM13.21,92.89a3.93,3.93,0,1,1-3.94-6.81L18,81A3.94,3.94,0,1,1,22,87.83l-8.76,5.06ZM4,64.57a3.95,3.95,0,1,1,0-7.89H14.06a3.95,3.95,0,1,1,0,7.89ZM10.1,35.39A3.93,3.93,0,1,1,14,28.58l8.76,5.06a3.93,3.93,0,1,1-3.92,6.81L10.1,35.39Z" />
                  </svg>
                  {LangLightMode}
                </p>
              </li>
              <li onClick={() => handleDarkMode('dark-only', props)}>
                <p className={`link ${props.redux.mode === 'dark-only' ? 'active' : ''} d-flex`} style={{ gap: 12 }}>
                  <svg style={{ width: 18 }} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" viewBox="0 0 122.56 122.88">
                    <path d="M121.85,87.3A64.31,64.31,0,1,1,36.88.4c2.94-1.37,5.92.91,4.47,4.47a56.29,56.29,0,0,0,75.75,77.4l.49-.27a3.41,3.41,0,0,1,4.61,4.61l-.35.69ZM92.46,74.67H92A16.11,16.11,0,0,0,76.2,58.93v-.52a15.08,15.08,0,0,0,11-4.72,15.19,15.19,0,0,0,4.72-11h.51a15.12,15.12,0,0,0,4.72,11,15.12,15.12,0,0,0,11,4.72v.51A16.13,16.13,0,0,0,92.46,74.67Zm10.09-46.59h-.27a7.94,7.94,0,0,0-2.49-5.81A7.94,7.94,0,0,0,94,19.78v-.27A7.94,7.94,0,0,0,99.79,17a8,8,0,0,0,2.49-5.8h.27A8,8,0,0,0,105,17a8,8,0,0,0,5.81,2.49v.27A8,8,0,0,0,105,22.27a7.94,7.94,0,0,0-2.49,5.81Zm-41.5,8h-.41a12.06,12.06,0,0,0-3.78-8.82A12.06,12.06,0,0,0,48,23.5v-.41a12.07,12.07,0,0,0,8.82-3.78,12.09,12.09,0,0,0,3.78-8.82h.41a12.08,12.08,0,0,0,3.77,8.82,12.09,12.09,0,0,0,8.83,3.78v.41a12.09,12.09,0,0,0-8.83,3.78,12.08,12.08,0,0,0-3.77,8.82Z" />
                  </svg>
                  {LangDarkMode}
                </p>
              </li>
              <li onClick={() => handleDarkMode('auto', props)}>
                <p className={`link ${props.redux.mode === 'auto' ? 'active' : ''} d-flex`} style={{ gap: 12 }}>
                  <svg style={{ width: 18 }} xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 508.47"><path fill-rule="nonzero" d="M254.23 508.47c-3.94 0-7.87-.1-11.77-.28h-1.54v-.07c-64.9-3.34-123.37-31.04-166.45-74.12C28.46 387.99 0 324.42 0 254.23c0-70.19 28.46-133.75 74.47-179.76C117.55 31.39 176.03 3.69 240.92.34V.27h1.54c3.9-.18 7.83-.27 11.77-.27l3.46.02.08-.02c70.19 0 133.75 28.46 179.76 74.47 46 46.01 74.47 109.57 74.47 179.76S483.53 387.99 437.53 434c-46.01 46.01-109.57 74.47-179.76 74.47l-.08-.03-3.46.03zm-13.31-30.56V30.56C184.33 33.87 133.4 58.17 95.79 95.79c-40.55 40.54-65.62 96.56-65.62 158.44 0 61.89 25.07 117.91 65.62 158.45 37.61 37.61 88.54 61.91 145.13 65.23z"/></svg>
                  {LangAutoMode}
                </p>
              </li>
              <li className='p-0'></li>
            </ul>
          </li>
        </ul>
      </div>
      <div className='nav-right col col-md-auto right-header p-0'>
        <ul className='nav-menus w-100'>
          {/*
          <li className='p-0'>
            <img className='rounded-circle' src={process.env.PUBLIC_URL + 'assets/images/default/profile.png'} alt='Logo' style={{ width: 35, height: 35 }} />
          </li>
          */}

          <li className="onhover-dropdown">
            <div className={`notification-box ${notificationCount !== 0 && 'vibrate'} mt-1 mt-md-0`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
              {
                notificationCount !== 0 &&
                <span className="badge badge-pill badge-secondary" style={{ right: `${notificationCount > 9 ? notificationCount > 99 ? '-12px' : '-10px' : '-5px'}` }}>{notificationCount > 99 ? '99+' : notificationCount}</span>
              }
            </div>
            <ul className="notification-dropdown onhover-show-div active">
              <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
                <h6 className="f-18 mb-0">{LangNotification}</h6>
              </li>
              {
                props.redux.notifications.attendance_review !== 0 &&
                <li onClick={() => { history.push('/attendance/review') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-primary"> </i>{AttendanceReview} <span className="pull-right">{props.redux.notifications.attendance_review}</span></p>
                </li>
              }
              {
                props.redux.notifications.attendance_edit_request !== 0 &&
                <li onClick={() => { history.push('/attendance/revision') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-success"></i>{LangAttendanceRevision}<span className="pull-right">{props.redux.notifications.attendance_edit_request}</span></p>
                </li>
              }
              {
                props.redux.notifications.unlock_limit_attenance_request !== 0 &&
                <li onClick={() => { history.push('/attendance-approval') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-info"></i>{LangAttendanceApproval}<span className="pull-right">{props.redux.notifications.unlock_limit_attenance_request}</span></p>
                </li>
              }
              {
                props.redux.notifications.update_profile_picture !== 0 &&
                <li onClick={() => { history.push('/attendance/profile-picture-approval') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-warning"></i>{LangProfilePhotoApproval}<span className="pull-right">{props.redux.notifications.update_profile_picture}</span></p>
                </li>
              }
              {
                props.redux.notifications.whitelist_device !== 0 &&
                <li onClick={() => { history.push('/device-approval') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-primary"></i>{LangDeviceApproval}<span className="pull-right">{props.redux.notifications.whitelist_device}</span></p>
                </li>
              }
              {
                props.redux.notifications.leave_request !== 0 &&
                <li onClick={() => { history.push('/leave/request') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-danger"></i>{LangPaidLeaveRequest}<span className="pull-right">{props.redux.notifications.leave_request}</span></p>
                </li>
              }
              {
                props.redux.notifications.permit_request !== 0 &&
                <li onClick={() => { history.push('/permit/request') }}>
                  <p className='link'><i className="fa fa-circle-o mr-3 font-primary"></i>{LangUnpaidLeaveRequest}<span className="pull-right">{props.redux.notifications.permit_request}</span></p>
                </li>
              }
              {
                props.redux.notifications.personnel_document_expired && props.redux.notifications.personnel_document_expired !== 0 ?
                  <li onClick={() => { history.push('/personnels') }}>
                    <p className='link'><i className="fa fa-circle-o mr-3 font-success"></i>{LangEmployeeDocsExpired}<span className="pull-right">{props.redux.notifications.personnel_document_expired}</span></p>
                  </li> : ''
              }
              {
                props.redux.notifications.personnel_document_in_reminder && props.redux.notifications.personnel_document_in_reminder !== 0 ?
                  <li onClick={() => { history.push('/personnels') }}>
                    <p className='link' data-tip={LangEmployeeDocsInReminder}><i className="fa fa-circle-o mr-3 font-info"></i>{cookies.get('lang') === 'EN' ? LangEmployeeDocsInReminder : widthWindow >= 375 && widthWindow <= 425 ? LangEmployeeDocsInReminder.substring(0, 32) : `${LangEmployeeDocsInReminder.substring(0, 23)}...`}<span className="pull-right">{props.redux.notifications.personnel_document_in_reminder}</span></p>
                  </li> : ''
              }
              {
                props.redux.notifications.claim_request && props.redux.notifications.claim_request !== 0 ?
                  <li onClick={() => { history.push('/claim/request') }}>
                    <p className='link'><i className="fa fa-circle-o mr-3 font-danger"></i>{LangClaimRequest}<span className="pull-right">{props.redux.notifications.claim_request}</span></p>
                  </li> : ''
              }
              {
                props.redux.notifications.pintu_kerjoo_sync_error && props.redux.notifications.pintu_kerjoo_sync_error !== 0 ?
                  <li onClick={() => { history.push('/pintu-kerjoo/attendance-approval') }}>
                    <p className='link' data-tip={LangPintuKerjooAttendanceApproval}><i className="fa fa-circle-o mr-3 font-primary"></i>{widthWindow >= 375 && widthWindow <= 425 ? `${LangPintuKerjooAttendanceApproval.substring(0, 36)}` : `${LangPintuKerjooAttendanceApproval.substring(0, 26)}...`}<span className="pull-right">{props.redux.notifications.pintu_kerjoo_sync_error}</span></p>
                  </li> : ''
              }
              <li className={`${notificationCount !== 0 && 'p-0'}`}>
                {
                  notificationCount === 0 && <p>{LangNoNotification}</p>
                }
              </li>
            </ul>
            <ReactTooltip place='bottom' />
          </li>

          <li className='onhover-dropdown border rounded ml-3 px-2'>
            <div className='d-flex align-items-center'>
              <span style={{ fontSize: 16, marginLeft: 5, fontWeight: 'bold' }}><span className=''>{Hi}, </span>
                <span style={{ fontWeight: 'normal' }}>
                  {
                    widthWindow >= 1043 ?
                      (
                        propsName && propsName.length > 18 ?
                          <span data-tip={propsName}>{propsName.slice(0, 18) + '...'}</span>
                          : propsName || ''
                      )
                      :
                      widthWindow >= 992 && widthWindow <= 1042 ?
                        (
                          propsName && propsName.length > 9 ?
                            <span data-tip={propsName}>{propsName.slice(0, 9) + '...'}</span>
                            : propsName || ''
                        )
                        :
                        widthWindow >= 796 && widthWindow <= 991 ?
                          (
                            propsName && propsName.length > 18 ?
                              <span data-tip={propsName}>{propsName.slice(0, 18) + '...'}</span>
                              : propsName || ''
                          )
                          :
                          widthWindow >= 425 && widthWindow <= 795 ?
                            (
                              propsName && propsName.length > 9 ?
                                <span data-tip={propsName}>{propsName.slice(0, 9) + '...'}</span>
                                : propsName || ''
                            )
                            :
                            widthWindow >= 375 ?
                              limitName.length > 9 ?
                                `${name.substring(0, 8)}...` || ''
                                :
                                name || ''
                              :
                              limitName.length > 9 ?
                                `${name.substring(0, 8)}...` || ''
                                :
                                name || ''
                  }
                </span>
              </span>
              <i className='middle fa fa-angle-down ml-2' />
            </div>
            <ul className='profile-dropdown onhover-show-div active' style={{ top: 38 }}>
              <li><a onClick={() => { history.push('/admin/activity-logs') }} className='d-flex align-items-center'><FontAwesomeIcon icon={faHistory} /> <span>{LangActivity}</span></a></li>
              {/* <li><a onClick={() => { history.push('/admin/daily-report') }} className='d-flex align-items-center'><FontAwesomeIcon icon={faCalendarDay} /> <span>{LangDailyReport}</span></a></li> */}
              <li><a onClick={() => { history.push('/admin/setting') }} className='d-flex align-items-center'><FontAwesomeIcon icon={faCog} /> <span>{LangSetting}</span></a></li>
              <li>
                <a onClick={() => !onLogout && setLogout(true)} className='text-danger d-flex align-items-center'>
                  {onLogout ? <div className='mr-2'>{SpinnerButton}</div> : <FontAwesomeIcon icon={faSignOutAlt} />} <span className='text-danger'>{LangLogout}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      {errors.message !== '' && (
        <ModalApp
          title={<span className='text-danger'>Error!</span>}
          handleClose={() => { setError(error => ({ ...error, message: '' })) }}
          body={<p className='text-danger h6 font-weight-normal'>{errors.message}</p>}
        />
      )}
    </>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Rightbar)
