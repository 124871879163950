import React, { useState, useEffect } from 'react'

import { connect, mapStateToProps, mapDispatchToProps, cookies } from '../../helpers/Common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faPlay } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'
import { LangAlertTR, LangAlertTR2, LangStart, LangViewStatus, LangFillIn } from '../../constant/languange'

const TourGuideAlert = ({ redux, setState, type = 'common' }) => {
  const location = useLocation()

  // state
  const [progress, setProgress] = useState(0)
  const [showAlert, setShowAlert] = useState(false)

  // effect
  useEffect(() => {
    setTimeout(() => {
      setShowAlert(!redux.isStopTour)
    }, 2000)
  }, [redux.isStopTour])

  useEffect(() => {
    if (redux.stats) {
      const statValues = Object.values(redux.stats)

      // progress bar
      setProgress(statValues.filter(value => value > 0).length)
    }
  }, [redux.stats])

  // handler
  const setOpenIndicator = () => {
    setState({ openIndicator: true })
  }

  return (
    <>
      {showAlert && !redux.profile.supervisor_groups && !+cookies.get(`clickFinish-${redux.profile.id}`)
        ? (
          <>
            {type === 'common' && location.pathname !== '/dashboard' && !!+cookies.get(`showAlertProgress-${redux.profile.id}`) && !redux.isTemporary && (
              <div className='container-fluid pt-4'>
                <div style={{ background: '#FFE8BA', borderRadius: '14px' }} className='p-3 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center'>
                  <p style={{ fontWeight: '500', fontSize: '18px', lineHeight: '25.2px' }} className='text-dark mb-0 mr-4 mb-2 mb-md-0'>{LangAlertTR2} {Math.ceil(progress / 6 * 100)}%</p>
                  <button onClick={() => { setOpenIndicator() }} style={{ fontWeight: '500', fontSize: '18px', lineHeight: '25.2px' }} className='btn rounded-pill btn-primary d-flex flex-nowrap align-items-center text-white'><FontAwesomeIcon className='mr-2' icon={faClipboardList} /> {progress === 6 ? LangViewStatus : LangFillIn}</button>
                </div>
              </div>
            )}

            {type === 'dashboard' && progress < 6  && !redux.isTemporary && (
              <div className='mb-4'>
                <div style={{ background: '#FFE8BA', borderRadius: '14px' }} className='p-3 d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center'>
                  <div className='d-flex flex-column align-items-start flex-md-row align-items-md-center'>
                    <div className='loader-box' style={{ height: 'auto' }}>
                      <div className='loader-9 bg-danger' style={{ width: 18, height: 18 }} />
                    </div>
                    <p style={{ fontWeight: '500', fontSize: '18px', lineHeight: '25.2px' }} className='text-dark ml-0 ml-md-2 mb-0 mr-4 mb-2 mb-md-0'>
                      {LangAlertTR}
                    </p>
                  </div>
                  <button onClick={() => { setOpenIndicator() }} style={{ fontWeight: '500', fontSize: '18px', lineHeight: '25.2px' }} className='btn rounded-pill btn-primary d-flex flex-nowrap align-items-center text-white'><FontAwesomeIcon className='mr-2' icon={faPlay} /> {LangStart}</button>
                </div>
              </div>
            )}
          </>
          )
        : ''}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TourGuideAlert)
