import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Link,
  HashRouter,
  Switch,
  Route,
  Redirect,
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom'
import { createStore } from 'redux'
import { Provider, connect } from 'react-redux'
import Cookies from 'universal-cookie'
import {
  SpinnerDiv,
  SpinnerButton,
  SpinnerLoadFirst,
  SpinnerLoadPages
} from './Spinner'
import Pagination from 'react-js-pagination'
import httpBuildQuery from 'http-build-query'
import moment from 'moment-timezone'
import 'moment/locale/id'
import defaultPicture from '../assets/images/default/profile.png'
import logoDark from '../assets/images/logo/dark.svg'
import logoLight from '../assets/images/logo/light.svg'
import fileDownload from 'js-file-download'

const cookies = new Cookies()
const myStorage = window.sessionStorage

const apiUrl = process.env.REACT_APP_API_URL

function toRupiah(angka) {
  let rupiah = ''
  const angkarev = angka.toString().split('').reverse().join('')
  for (let i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.'
  return rupiah.split('', rupiah.length - 1).reverse().join('')
}

/**
 * setup moment locale
 */
moment.locale((cookies.get('lang') === 'EN') ? 'en' : 'id')

/**
 * setup redux
 */

const mapStateToProps = (state) => {
  return {
    redux: state,
    getStateModule
  }

  function getStateModule(module, key) {
    if (state.modules && state.modules[module]) {
      // jika ada key, returnkan sesuai key
      if (key) {
        return state.modules[module][key] || null
      } else {
        return state.modules[module]
      }
    } else {
      return null
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setState: (change) => dispatch({ type: 'SET_STATE', change }),
    setStateModule: (module, change) => dispatch({ type: 'SET_STATE_MODULE', module, change })
  }
}

const initialState = {
  authed: false,
  serviceWorkerUpdated: false,
  annualLeave: '',
  profile: {},
  company: {},
  modules: {},
  notifications: {},
  isTourOpen: false, // state untuk component reacttour
  openIndicator: false, // state modal tasklist tour guide
  tourName: '', // nama task tour guide yang sedang dikerjakan
  isUpdateStats: 0,
  stats: null,
  isStopTour: false, // fitur tour guide enable atau tidak
  notFirstLogin: 0,
  featureLimitation: {}
}

/**
 * create store redux
 */
const store = createStore((state = initialState, action) => {
  let stateModule = null

  switch (action.type) {
    case 'SET_STATE':
      return Object.assign({}, state, action.change)

    case 'SET_STATE_MODULE':
      stateModule = Object.assign({}, state.modules)
      if (stateModule[action.module]) {
        if (action.change === null) {
          delete stateModule[action.module]
        } else {
          stateModule[action.module] = Object.assign({}, stateModule[action.module], action.change)
        }
      } else {
        stateModule[action.module] = action.change
      }

      return Object.assign({}, state, { modules: stateModule })

    default:
      return state
  }
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

/**
 * setup instance axios yang sudah ke url company dan auth token cookie
 */
const axiosCompany = () => {
  return axios.create({
    baseURL: store.getState().profile.company_api_url,
    headers: {
      Authorization: `Bearer ${cookies.cookies.token}`
    }
  })
}

export const axiosCompanyFile = () => {
  return axios.create({
    baseURL: store.getState().profile.company_api_url,
    headers: {
      Authorization: `Bearer ${cookies.cookies.token}`
    },
    responseType: 'blob'
  })
}

/**
 * displayDate untuk menampilkan tgl dari UTC ke timezone user
 *
 * @param sring dateUTC Y-m-d, atau Y-m-d H:i:s
 * @param string displayFormat https://momentjs.com/docs/#/displaying/format/
 */
function displayDate(dateUTC, displayFormat) {
  return moment.tz(dateUTC, 'UTC').tz(store.getState().profile.timeZone).format(displayFormat || 'DD MM YYYY')
}

function displayScheduleTime(strTime) {
  const a = strTime.split(':')
  return `${a[0]}:${a[1]}`
}

function isBeforeNow(strDate) {
  return moment(strDate).isBefore(moment(), 'day')
}

/**
 * sendDate untuk mengirim tgl local dan diformat ke UTC
 *
 * @param string dateLocal Y-m-d
 */
function sendDate(dateLocal) {
  const dateParam = moment(dateLocal + ' ' + moment().format('HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')

  /**
   * konversi ke utc
   */
  return dateParam.tz('UTC').format('YYYY-MM-DD')
}

class PaginationComponent extends React.Component {
  render() {
    return (
      <>
        <Pagination
          itemClass='page-item'
          linkClass='page-link'
          innerClass='pagination mb-0'
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.itemsCountPerPage}
          totalItemsCount={this.props.totalItemsCount}
          pageRangeDisplayed={this.props.pageRangeDisplayed}
          onChange={this.props.onChange}
        />
      </>

    )
  }
}

const PaginationDiv = (props) => {
  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0,
      current_page: 1
    }
  })

  useEffect(() => {
    if (props.data?.data) {
      setData(props.data)
    }
  }, [props.data])

  return (
    <>
      {
        props.noInfo ?
          <div className='d-flex flex-wrap justify-content-end'>
            <div className='mb-2'>
              <Pagination
                activePage={data.meta.current_page}
                itemsCountPerPage={data.meta.per_page}
                totalItemsCount={data.meta.total}
                pageRangeDisplayed={props.range ? props.range : 7}
                onChange={props.onChange}
                itemClass='page-item'
                linkClass='page-link'
                innerClass='pagination pagination-sm mb-0'
              />
            </div>
          </div>
          :
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='mb-2'>
              {(cookies.get('lang') === 'EN')
                ? (
                  <b>Showing {toRupiah(data.data.length)} from {toRupiah(data.meta.total)} entries</b>
                )
                : (
                  <b>Menampilkan {toRupiah(data.data.length)} dari {toRupiah(data.meta.total)} total data</b>
                )}
            </div>
            <div className='mb-2'>
              <Pagination
                activePage={data.meta.current_page}
                itemsCountPerPage={data.meta.per_page}
                totalItemsCount={data.meta.total}
                pageRangeDisplayed={props.range ? props.range : 7}
                onChange={props.onChange}
                itemClass='page-item'
                linkClass='page-link'
                innerClass='pagination pagination-sm mb-0'
              />
            </div>
          </div>
      }
    </>


    // <div className='row'>
    //   <div className='col-lg-6 mb-2'>
    //     {(cookies.get('lang') === 'EN')
    //       ? (
    //         <b>Showing {toRupiah(data.data.length)} from {toRupiah(data.meta.total)} entries</b>
    //         )
    //       : (
    //         <b>Menampilkan {toRupiah(data.data.length)} dari {toRupiah(data.meta.total)} total data</b>
    //         )}
    //   </div>
    //   <div className='col-lg-6'>
    //     <div className='d-flex flex-column flex-lg-row justify-content-lg-end'>
    //       <div className='mb-2 mr-lg-2'>
    //         <Pagination
    //           activePage={data.meta.current_page}
    //           itemsCountPerPage={data.meta.per_page}
    //           totalItemsCount={data.meta.total}
    //           pageRangeDisplayed={7}
    //           onChange={props.onChange}
    //           itemClass='page-item'
    //           linkClass='page-link'
    //           innerClass='pagination pagination-sm mb-0'
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export const supervisorCanView = (item, checkField) => {
  let group = null
  const isSupervisor = store.getState().profile.supervisor_groups
  if (isSupervisor) group = store.getState().groups.find(group => group.id === item.personnel.group_id)

  switch (checkField) {
    case 'spv_can_view_map':
      return isSupervisor ? +group.attr.spv_can_view_map : true
    case 'spv_can_view_selfie':
      return isSupervisor ? (item?.attr?.selfie && +group.attr.spv_can_view_selfie) : item?.attr?.selfie
    case 'spv_can_view_device_info':
      return isSupervisor ? (item?.attr?.device_info && +group.attr.spv_can_view_device_info) : item?.attr?.device_info
    case 'spv_can_edit_attendance':
      return isSupervisor ? +group.attr.spv_can_edit_attendance : true
    case 'spv_can_edit_document':
      return isSupervisor ? +group.attr.spv_can_edit_document : true
    default:
      return true
  }
}

export const setAnnualLeaveLabel = (input, lang = 'id') => {
  const key = lang === 'id' ? 'annual_label_id' : 'annual_label_en'
  const annualLang = lang === 'id' ? 'Cuti' : 'Annual Leave'
  cookies.set(key, input || annualLang)
}

const axiosDownloadFile = (blobData, fileName = '', fileType = '') => {
  const url = window.URL.createObjectURL(new window.Blob([blobData]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}.${fileType}`)
  document.body.appendChild(link)
  link.click()
}

const ShowImageSpesific = ({ picture, size = 's', ...props }) => {
  const [url, setUrl] = useState(null)
  const [control, setControl] = useState({ showLoad: false })

  const getImage = (path) => {
    setControl({ ...control, showLoad: true })
    axios.get(path, {
      headers: {
        Authorization: `Bearer ${cookies.cookies.token}`
      },
      responseType: 'blob'
    }).then((res) => {
      const blob = new window.Blob([res.data], {
        type: res.headers['content-type']
      })
      const objectURL = URL.createObjectURL(blob)
      setUrl(objectURL)
    }).finally(() => {
      setControl({ ...control, showLoad: false })
    })
  }

  useEffect(() => {
    Object.keys(picture).length !== 0 ? getImage(picture[size]) : setUrl(defaultPicture)
    // eslint-disable-next-line
  }, [picture])

  return (
    control.showLoad
      ? <center>{SpinnerButton}</center>
      : (
        <img
          loading='lazy'
          src={url}
          alt=''
          {...props}
        />
      )
  )
}

export const getImageSrc = async (url) => {
  let src
  await axios.get(url, {
    headers: {
      Authorization: `Bearer ${cookies.cookies.token}`
    },
    responseType: 'blob'
  })
    .then((res) => {
      const blob = new window.Blob([res.data], {
        type: res.headers['content-type']
      })

      const objectURL = URL.createObjectURL(blob)

      src = objectURL
    })

  return src
}

class ShowImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadFirst: true,
      imgSrc: null
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.loadImage()
    }
  }

  componentDidMount() {
    if (this.props.url) {
      this.loadImage()
    } else {
      this.setState({ loadFirst: false })
    }
  }

  loadImage() {
    axios
      .get(this.props.url, {
        headers: {
          Authorization: `Bearer ${cookies.cookies.token}`
        },
        responseType: 'blob'
      })
      .then((res) => {
        const blob = new window.Blob([res.data], {
          type: res.headers['content-type']
        })

        const objectURL = URL.createObjectURL(blob)

        this.setState({ imgSrc: objectURL })
      })
      .finally(() => {
        this.setState({ loadFirst: false })
      })
  }

  render() {
    const s = this.state
    if (s.loadFirst) {
      if (this.props.defaultpictureonload) {
        return (
          <img
            src={defaultPicture}
            alt=''
            {...this.props}
          />
        )
      } else {
        return <center>{SpinnerButton}</center>
      }
    }

    if (!s.imgSrc) {
      return (
        <img
          src={defaultPicture}
          alt=''
          {...this.props}
        />
      )
    }

    return (
      <img
        src={s.imgSrc}
        alt=''
        {...this.props}
      />
    )
  }
}

const Logout = () => {
  const config = {
    method: 'post',
    url: `${apiUrl}/auth/logout`,
    headers: {
      Authorization: `Bearer ${cookies.cookies.token}`
    }
  }

  axios(config)
    .then(() => {
      window.location.reload()
    })
}

const convertToHoursMins = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = Math.round((totalMinutes % 60))

  return {
    hours,
    minutes
  }
}

const durationFromSeconds = (totalSeconds, styleId) => {
  if (!totalSeconds) return ''

  /**
   * tujuan akhir menampilkan:
   * n jam n menit n detik
   */

  /**
   * detik dibagi 60 = menit
   */
  const menit = Math.floor(totalSeconds / 60)

  /**
   * dapatkan sisa detik
   */
  const sisaDetik = Math.floor(totalSeconds - (menit * 60))

  /**
   * dapatkan jam dari total menit
   */
  const jam = Math.floor(menit / 60)

  /**
   * dapatkan sisa menit
   */
  const sisaMenit = menit - (jam * 60)

  const isLangEN = cookies.get('lang') === 'EN'

  const c = []

  if (styleId === 3) {
    if (jam) {
      c.push(jam + (isLangEN ? 'hr' : 'jm'))
    }
    if (sisaMenit) {
      c.push(sisaMenit + (isLangEN ? 'min' : 'mn'))
    }
    if (sisaDetik) {
      c.push(sisaDetik + (isLangEN ? 'sec' : 'dt'))
    }
  } else if (styleId === 2) {
    if (jam) {
      c.push(jam + ' ' + (isLangEN ? 'hour' : 'jam'))
    }
    if (sisaMenit) {
      c.push(sisaMenit + ' ' + (isLangEN ? 'minute' : 'menit'))
    }
    if (sisaDetik) {
      c.push(sisaDetik + ' ' + (isLangEN ? 'seconds' : 'detik'))
    }
  } else {
    if (jam) {
      c.push(jam + (isLangEN ? 'hr' : 'jm'))
    }
    if (sisaMenit) {
      c.push(sisaMenit + (isLangEN ? 'min' : 'mn'))
    }
    /**
     * detik ditampilkan jika jam & menit kosong
     */
    if (sisaDetik && jam <= 0 && sisaMenit <= 0) {
      c.push(sisaDetik + (isLangEN ? 'sec' : 'dt'))
    }
  }

  return c.join(' ')
}

function convertToSlug(Text) {
  return Text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

function swClearCache(reload) {
  if ('serviceWorker' in navigator && 'caches' in window) {
    window.caches.keys()
      .then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          window.caches.delete(cacheName)
        })
      })
      .then(() => {
        if (typeof reload === 'function') {
          reload()
        } else {
          if (reload) {
            window.location.reload(true)
          }
        }
      })
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function loadPersonnelOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`personnels?page=null&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          if (item.group) {
            label += ' - ' + item.group.name
          }

          return { label, value: item.id }
        }))
      })
  })
}

export function loadPersonnelClientVisitOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`personnels?page=null&is_active_client_visit=1&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          if (item.group) {
            label += ' - ' + item.group.name
          }

          return { label, value: item.id }
        }))
      })
  })
}

export function loadClientOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`client-visit-clients?page=null&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          const label = item.name

          return { label, value: item.id }
        }))
      })
  })
}

export function loadAttendanceSpots(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`spots?page=null&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          return { label: item.name, value: item.id }
        }))
      })
  })
}

export function loadAdminOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`admins?page=null&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          if (item.group) {
            label += ' - ' + item.group.name
          }
          return { label, value: item.id }
        }))
      })
  })
}

function loadDivisionOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`groups?page=null&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          const label = item.name
          return { label, value: item.id }
        }))
      })
  })
}

export function loadDivisionClientVisitOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`groups?page=null&is_active_client_visit=1&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          const label = item.name
          return { label, value: item.id }
        }))
      })
  })
}

export function getDivisionClientVisit() {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`groups?page=null&is_active_client_visit=1`)
      .then((result) => {
        resolve(result.data.map((item) => {
          return item
        }))
      })
  })
}

function loadPositionOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`positions?keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          const label = item.name_with_arrow_parent
          return { label, value: item.id }
        }))
      })
  })
}

function loadPersonnelShiftOptions(inputValue) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`personnels?page=null&is_shift_group=1&keyword=${inputValue}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          if (item.group) {
            label += ' - ' + item.group.name
          }

          return { label, value: item.id }
        }))
      })
  })
}

export function loadProjectOptions(inputValue, personnel_id) {
  return new Promise((resolve) => {
    axiosCompany()
      .get(`projects?page=null&keyword=${inputValue ?? ''}${personnel_id ? `&personnel_id=${personnel_id}` : ''}`)
      .then((result) => {
        resolve(result.data.map((item) => {
          let label = item.name
          return { label, value: item.id }
        }))
      })
  })
}

const MODULES_NAMES = {
  SHIFT_WORK_PATTERN: 'shift_work_pattern'
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function download(url, fileName, cb) {
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${cookies.cookies.token}`
      },
      responseType: 'blob'
    })
    .then((res) => {
      if (typeof cb === 'function') cb()
      fileDownload(res.data, fileName)
    })
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function pushDataLayer(data) {
  const ok = (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_URL.indexOf('api.kerjoo.com') !== -1)
  if (!ok) {
    console.log('ignore push dataLayer', data)
    return true
  }

  window.dataLayer.push(data)
}

export const limitStr = (str, limit) => {
  if (str.length > limit) {
    str = str.substr(0, limit) + '...'
  }

  return str
}

/**
 * ini untuk replace data lang, misal:
 * Available for a minimum quota of :quota employees
 *
 * replace :quota dengan yang kita inginkan
 */
export const lang = (strReplace, data) => {
  if (data) {
    Object.keys(data).forEach((key) => {
      strReplace = strReplace.replace(`:${key}`, data[key])
    })
  }

  return strReplace
}

export const parseToOptions = (options = []) => {
  return options.map((item, i) => {
    let label = item.name
    if (item.group) {
      label += ' - ' + item.group.name
    }
    return { label, value: item.id }
  })
}

export const takeOptionsValue = (options = []) => {
  return options.map((item, i) => {
    return item.value
  })
}

export const getGroupOptions = (groupId = []) => {
  const groups = store.getState().groups
  const result = groups.filter(item => groupId.includes(item.id))
  return parseToOptions(result)
}

export const getGroups = (groupId = []) => {
  const groups = store.getState().groups
  return groups.filter(item => groupId.includes(item.id))
}

/**
 * Function to search difference between two object
 * @param {array} masterKeys : a list of key that will used as reference to differentiation
 * @param {object} after : targeted object after
 * @param {object} before : targeted object before
 * @param {string} parentKey : used to a parent key in result key
 * @returns format object {after: value, before: value}
 */
export const handleObjectDiff = (masterKeys = [], after = {}, before = {}, parentKey = null) => {
  const diffResult = {}
  masterKeys.forEach((key, i) => {
    if (after[key] || before[key]) { // Checking if value is not undefined or null
      if (after[key] !== before[key]) { // Compare differences of each value
        diffResult[key] = { after: after[key], before: before[key] }
      }
    }
  })

  // console.log('bf', before)
  // console.log('af', after)
  // console.log('result', diffResult)
  return JSON.stringify(diffResult) !== '{}' ? diffResult : null
}

/**
 * Function to destructuring object with format {after, before}
 * @param {object} data : targeted destructuring object
 * @param {array} objectFields : list of object fields want to show.
 * fields format {val: api field, index: used to slicing from master key}
 * @returns
 */
export const destructuringObjectFields = (data = null, masterKeys = {}) => {
  if (data) {
    const after = data?.after ?? {}
    const before = data?.before ?? {}
    let parseData = {}
    Object.keys(masterKeys).forEach((key, i) => {
      if (after[key] || before[key]) {
        if (after[key] && typeof after[key] === 'object') {
          // console.log('after', after[key])
          // console.log('after', data)
          // console.log('obj', masterKeys[key])

          parseData = {
            ...parseData,
            ...handleObjectDiff(
              masterKeys[key],
              after[key] ?? {},
              before[key] ?? {}
            )
          }
        } else {
          if (after[key] !== before[key]) {
            parseData[key] = { before: before[key], after: after[key] }
          }
        }
      }
    })
    // console.log('parse', parseData)
    return parseData
  }
}
// export const destructuringObjectFields = (data = null, masterKey = [], fields = []) => {
//   if (data) {
//     const result = fields.map((item, i) => {
//       return data?.after[item?.val]
//         ? handleObjectDiff(
//             Object.keys(masterKey).slice(...item.index),
//             data?.after ? data?.after[item?.val] ?? {} : {},
//             data?.before ? data?.before[item?.val] ?? {} : {}
//           )
//         : null
//     }).reduce((acc, item) => ({ ...acc, ...item }), {})
//     return result
//   }
// }

/**
 *
 * @param {any} data : targeted variable (only format one child)
 * @param {fn()} formatedFn : a call back function to format targeted variable
 * @returns object
 */
export const formatFieldValue = (data, formatedFn) => {
  if (typeof data === 'object' && !Array.isArray(data)) {
    const result = Object.keys({ ...data }).reduce((curr, key) => {
      data[key] = (data[key] != null) ? formatedFn(data[key]) : data[key]
      return { ...curr, ...data }
    }, {})
    return result
  } else { return data != null ? formatedFn(data) : data }
}

// to check is tour guide completed
export const checkIsCompleted = (stats) => {
  if (stats) {
    const isCompleted = +stats.groups_count > 0 && +stats.work_and_shift_patterns_count > 0 && +stats.personnels_count > 0 && +stats.work_and_shift_times_count > 0 && +stats.spots_count > 0 && +stats.is_complete_profile > 0
    return isCompleted
  }
}

// convert object to dataform
export function objectToFormData(obj, formData = new FormData(), parentKey = '') {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const formKey = parentKey ? `${parentKey}[${key}]` : key

      if (value instanceof File) {
        // Handle files
        formData.append(formKey, value)
      } else if (value instanceof Date) {
        // Handle dates
        formData.append(formKey, value.toISOString())
      } else if (Array.isArray(value)) {
        // Handle arrays
        value.forEach((arrayValue, index) => {
          const tempFormKey = `${formKey}[${index}]`
          objectToFormData({ [tempFormKey]: arrayValue }, formData)
        })
      } else if (typeof value === 'object' && value !== null) {
        // Handle nested objects
        objectToFormData(value, formData, formKey)
      } else {
        // Handle other data types
        formData.append(formKey, value)
      }
    }
  }
  return formData
}

// check feature limitation based on active package
// if return true we can use the feature and instead
// currentValue is optional and it can be number of data length or boolean
export const checkFeatureLimitation = (redux, featureName, currentValue) => {
  // if trial
  if (redux.isTrial) return true

  // check package
  const subscriptionPackage = redux.featureLimitation[redux.packageName]
  if (!subscriptionPackage) return true

  // if have package
  const value = subscriptionPackage[featureName]
  if (typeof value === 'number' && typeof currentValue === 'number') {
    return currentValue < value
  }
  if (typeof value === 'number' && typeof currentValue === 'boolean') {
    return currentValue
  }

  if (typeof value === 'boolean') return !value
}

// will return limitation date based on active package
export const checkLimitationDate = (redux, featureName) => {
  // if trial
  if (redux.isTrial) return ''

  // check package
  const subscriptionPackage = redux.featureLimitation[redux.packageName]
  if (!subscriptionPackage) return ''

  // if have package
  const limitMinDays = subscriptionPackage[featureName]
  return moment().subtract(limitMinDays, 'days').format('YYYY-MM-DD')
}

export const displayTimezone = (timezone) => {
  let display = ''
  switch (timezone) {
    case 'wib': {
      display = 'WIB (UTC+7)'
      break
    }
    case 'wita': {
      display = 'WITA (UTC+8)'
      break
    }
    case 'wit': {
      display = 'WIT (UTC+9)'
      break
    }
    default: {
      display = ''
      break
    }
  }
  return display
}

/**
 * ini untuk mendapatkan data lang yang digunakan user: (sesuai urutan)
 * 1. Cek parameter query url, misal ?lang=en jika ada maka return { value: 'en', origin: 'query' }.
 *    - jika value query url selain ID/EN misal ?lang=ru -> maka cek cookie lang, jika cookie lang tidak ada maka defaultnya EN
 * 2. Jika query url tidak ada maka cek cookie lang, jika ada maka return { value: '<sesuai value cookie lang>', origin: 'cookies' }.
 * 3. Jika query url tidak ada dan cookie lang tidak ada maka cek bahasa browsernya dan return { value: '<sesuai bahasa browser>', origin: 'browserLanguage' }
 * 4. Jika value yang didapat dari 3 step diatas hasilnya selain ID/EN maka defaultnya EN
 */
export const getLangPublicPage = () => {
  let value = 'EN'
  let origin = ''
  const queryPosition = window.location.hash.search(/lang/i)
  const langQuery = queryPosition !== -1 ? window.location.hash.substring(queryPosition) : ''
  if (langQuery) {
    value = langQuery.substring(5).toUpperCase()
    origin = 'query'
    if (['ID', 'EN'].indexOf(value) === -1) {
      if (cookies.get('lang')) {
        value = cookies.get('lang').toUpperCase()
        origin = 'cookies'
      } else {
        value = 'EN'
        origin = 'query'
      }
    }
  } else if (cookies.get('lang')) {
    value = cookies.get('lang').toUpperCase()
    origin = 'cookies'
  } else if (navigator.language || navigator.userLanguage) {
    value = navigator.language.substring(0, 2).toUpperCase() || navigator.userLanguage.substring(0, 2).toUpperCase()
    origin = 'browserLanguage'
  }
  if (['ID', 'EN'].indexOf(value) === -1) {
    value = 'EN'
  }
  return {
    value,
    origin
  }
}

/**
 * Function untuk extract data nama dan jabatannya dari setiap approver pada approval steps
 * 
 * Digunakan pada page /leave/request, /permit/request, dan component activity log approval steps
 */
export const displayApproverName = (approvalStep, langSpvOfEmployee) => {
  let text = []
  if (approvalStep.approver_details) {
    const rawData = JSON.parse(JSON.stringify(approvalStep.approver_details))
    let manipulatedData = rawData
    if (approvalStep.approver_type === 'specific_position') {
      manipulatedData = Object.values(rawData.reduce((acc, ar) => {
        if (acc[ar.position])
          acc[ar.position].name += ' / ' + ar.name
        else
          acc[ar.position] = { ...ar }
        return acc
      }, {}))
    }
    switch (true) {
      case (approvalStep.status === 'pending' || approvalStep.is_updated_by_admin === 1): {
        manipulatedData?.forEach(item => {
          let temp = ''
          if (approvalStep.approver_type === 'specific_position') {
            temp += item.position ?? ''
            if (temp && item.name) {
              if (typeof item.name === 'string') {
                let nameArr = item.name.split(' / ')
                nameArr = nameArr.slice(0, 5)
                temp += ` (${nameArr.join(' / ')})`
              } else {
                temp += ` (${item.name})`
              }
            }
            text.push(temp)
          } else {
            temp += item.name ?? ''
            text.push(temp)
          }
        })
        break
      }
      case (approvalStep.status === 'approve' || approvalStep.status === 'reject'): {
        let temp = ''
        if (approvalStep.approver_type === 'specific_position') {
          temp += approvalStep.updated_by?.personnel?.position?.name ?? ''
          if (temp && approvalStep.updated_by?.name) {
            temp += ` (${approvalStep.updated_by?.name})`
          }
          text.push(temp)
        } else {
          temp += approvalStep.updated_by?.name ?? ''
          text.push(temp)
        }
        break
      }
      default: return ''
    }
  }
  if (approvalStep.approver_type === 'spv_of_employee') {
    return `${langSpvOfEmployee ?? ''} ${text.length !== 0 ? `(${text.join(' / ')})` : ''}`
  } else {
    return text.join(' / ')
  }
}

// function untuk hitung waktu tersisa sebelum expire
export const calculateTimeRemaining = (expiredAt, isLangEN) => {
  const expiredDate = moment(expiredAt, 'YYYY-MM-DD HH:mm:ss')
  const currentDate = moment()

  // hitung sisa hari
  const days = expiredDate.diff(currentDate, 'days')
  const hour = expiredDate.diff(currentDate, 'hour')
  if (days >= 0 && days <= 7 && hour > 0) {
    if (days === 0) {
      const timeDiff = moment.duration(expiredDate.diff(currentDate))

      const hours = timeDiff.hours()
      const minutes = timeDiff.minutes()
      const seconds = timeDiff.seconds()
      return isLangEN ? `${hours}h ${minutes}m ${seconds}s` : `${hours}j ${minutes}m ${seconds}d`
    } else {
      return isLangEN ? `${days} days` : `${days} hari`
    }
  } else {
    return false
  }
}

// function untuk listen perubahan tema OS user (dipakai ketika mode yang dipilih auto)
const colorSchemeChangeListener = (props) => {
  const mql = window.matchMedia('(prefers-color-scheme: dark)')
  if (cookies.get('mode') === 'auto') {
    if (mql.matches) {
      document.body.classList.add('dark-only')
      cookies.set('darkModeOn', 1)
      props && props.setState({ mode: 'auto', darkModeOn: 1 })
    } else {
      document.body.classList.remove('dark-only')
      cookies.set('darkModeOn', 0)
      props && props.setState({ mode: 'auto', darkModeOn: 0 })
    }
    mql.onchange = (e) => {
      if (cookies.get('mode') === 'auto') {
        if (e.matches) {
          document.body.classList.add('dark-only')
          cookies.set('darkModeOn', 1)
          props && props.setState({ mode: 'auto', darkModeOn: 1 })
        } else {
          document.body.classList.remove('dark-only')
          cookies.set('darkModeOn', 0)
          props && props.setState({ mode: 'auto', darkModeOn: 0 })
        }
      }
    }
  }
}

// function untuk handle switch appearance (light mode, dark mode, auto)
export const handleDarkMode = (mode, props) => {
  switch (true) {
    case mode === 'dark-only':
      cookies.set('mode', 'dark-only')
      document.body.classList.add('dark-only')
      props.setState({ mode: 'dark-only', darkModeOn: 1 })
      break
    case mode === 'light':
      cookies.set('mode', 'light')
      document.body.classList.remove('dark-only')
      props.setState({ mode: 'light', darkModeOn: 0 })
      break
    case mode === 'auto':
      // mode auto: mengikuti tema operasi sistem user
      cookies.set('mode', 'auto')
      colorSchemeChangeListener(props)
      break
    default:
      cookies.set('mode', 'light')
      document.body.classList.remove('dark-only')
      props.setState({ mode: 'light', darkModeOn: 0 })
  }
}

export const handleDarkModePublicPage = (mode) => {
  switch (true) {
    case mode === 'dark-only':
      document.body.classList.add('dark-only')
      break
    case mode === 'light':
      document.body.classList.remove('dark-only')
      break
    case mode === 'auto':
      // mode auto: mengikuti tema operasi sistem user
      colorSchemeChangeListener()
      break
    default:
      cookies.set('mode', 'auto')
      colorSchemeChangeListener()
  }
}

export const displayDateFormat = (number) => {
  return number < 10 ? `0${number}` : number
}

export const displayTotalDistance = (distance) => {
  return distance >= 1000 ? `${(distance / 1000).toFixed(2)} Kilometer` : `${distance} Meter`
}

export {
  /**
   * function / var
   */
  pushDataLayer,
  mapStateToProps,
  mapDispatchToProps,
  store,
  connect,
  apiUrl,
  Logout,
  toRupiah,
  axiosCompany,
  displayDate,
  sendDate,
  httpBuildQuery,
  defaultPicture,
  logoDark,
  logoLight,
  useHistory,
  useLocation,
  convertToHoursMins,
  convertToSlug,
  swClearCache,
  capitalizeFirstLetter,
  MODULES_NAMES,
  loadPersonnelOptions,
  loadPersonnelShiftOptions,
  loadDivisionOptions,
  loadPositionOptions,
  formatBytes,
  download,
  useQuery,
  durationFromSeconds,
  displayScheduleTime,
  isBeforeNow,

  /**
   * class
   */
  Redirect,
  Link,
  HashRouter,
  Switch,
  Route,
  Provider,
  SpinnerDiv,
  SpinnerButton,
  SpinnerLoadFirst,
  SpinnerLoadPages,
  axios,
  cookies,
  PaginationComponent,
  moment,
  useParams,
  myStorage,
  PaginationDiv,
  ShowImage,
  ShowImageSpesific,
  axiosDownloadFile
}
