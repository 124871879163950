import React, { useEffect, useState } from 'react'
import { LangNew } from '../../constant/languange'
import { Spinner9 } from '../../helpers/Spinner'
import { moment } from '../../helpers/Common'

/**
 *
 * @param releaseDate date:'YYYY-MM-DD'
 * @param DefaultBadge component
 * @returns component
 */
function Badges ({ type = 'link', releaseDate = '', DefaultBadge = null, ...props }) {
  const [control, setControl] = useState({ newBadgeExpired: true })
  useEffect(() => {
    setControl({ ...control, newBadgeExpired: releaseDate !== '' ? moment().isSameOrAfter(moment(releaseDate).add(1, 'month'), 'day') : true })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!control.newBadgeExpired
        ? <div style={{ fontSize: '9px' }} className={`badge badge-secondary ${type === 'sub' && 'mr-3'} position-static py-1 d-flex align-items-center mr-1`}>{Spinner9({ size: 9 })} <span className='font-weight-bold' style={{ color: 'inherit' }}>{LangNew.toUpperCase()}</span></div>
        : DefaultBadge}
    </>
  )
}

export default Badges
