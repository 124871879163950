import React, { useState, useEffect } from 'react'
import CustomModal from '../../helpers/CustomModal'
import welcome from '../../assets/images/tour-guide/welcome.png'
import hand from '../../assets/images/tour-guide/hand.png'
import { connect, mapStateToProps, mapDispatchToProps, cookies } from '../../helpers/Common'
import { LangDefaultDataTrial1, LangDefaultDataTrial2, LangOkGotIt, LangWelcomeTo } from '../../constant/languange'

const DefaultDataInfoComponent = ({ redux }) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true)
    }, 4000)
  }, [])

  const handleClose = () => {
    cookies.set(`notFirstLogin-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
    document.body.style.overflow = 'auto'
    setShowModal(false)
  }

  const content = (
    <div className={`${redux.darkModeOn ? 'bg-dark' : 'bg-white'} px-3 py-5 px-md-3 shadow position-relative mx-auto`} style={{ borderRadius: '20px', maxWidth: '560px', boxSizing: 'border-box !important', zIndex: '10000' }}>
      <div className='d-flex flex-column align-items-center'>
        <img style={{ maxWidth: '368px', marginBottom: '40px' }} src={welcome} alt='welcome!' />
        <div className='d-flex align-items-center' style={{ marginBottom: '20px' }}>
          <p className='mb-0 mr-1 text-center' style={{ fontSize: '24px', fontWeight: '700', lineHeight: '34px' }}>{LangWelcomeTo} <span style={{ color: '#25A091' }}>Kerjoo!</span> <img src={hand} width={24} alt='hand' /></p>
        </div>
        <p className='text-center mb-1' style={{ fontSize: '16px', maxWidth: '460px', lineHeight: '22.4px' }}>
          {LangDefaultDataTrial1}
        </p>
        <p className='text-center mb-4' style={{ fontSize: '16px', maxWidth: '420px', lineHeight: '22.4px' }}>{LangDefaultDataTrial2}</p>
        <button onClick={handleClose}
          className='btn text-white rounded-pill'
          style={{ background: '#25A091', padding: '12px 80px', fontSize: '16px' }}
        >{LangOkGotIt}</button>
      </div>
    </div>
  )

  return showModal && !cookies.get(`notFirstLogin-${redux.profile.id}`) && redux.isTemporary && redux.profile.supervisor_groups === null && <CustomModal content={content} />
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultDataInfoComponent)
