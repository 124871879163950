import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { axiosCompany, displayDate, mapDispatchToProps, mapStateToProps, SpinnerLoadFirst } from '../../helpers/Common'
import { LangAmountReqLeave, LangBalanceReqLeave, LangDataEmpty, LangDescription, LangTransactionDate } from '../../constant/languange'

function PersonnelLeaveHistoryComponent ({ redux, currentData }) {
  const initData = { data: [], links: {}, meta: {} }
  const [data, setData] = useState(initData)
  const [loadData, setLoadData] = useState(false)

  useEffect(() => {
    getDataService()
    // eslint-disable-next-line
  }, [])

  const getDataService = () => {
    setLoadData(true)
    axiosCompany().get(`/personnels/${currentData.id}/annual-leave-transactions`)
      .then(res => {
        setLoadData(false)
        setData({ ...res.data })
      })
      .catch(() => {
        setLoadData(false)
      })
  }

  return (
    <>
      {loadData
        ? SpinnerLoadFirst
        : (
          <>
            <div class='table-responsive'>
              <table className={`table table-striped table-bordered ${redux.darkModeOn ? 'bg-dark' : ''}`}>
                <thead>
                  <tr>
                    <th scope='col' style={{ minWidth: 180 }}>{LangTransactionDate}</th>
                    <th scope='col'>{LangDescription}</th>
                    <th scope='col'>{LangAmountReqLeave}</th>
                    <th scope='col'>{LangBalanceReqLeave}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.data.map((item) => (
                    <tr key={item.id}>
                      <td>{displayDate(item.created_at, 'DD MMM YYYY @ HH:mm')}</td>
                      <td>{item.resource}</td>
                      <td>{item.amount >= 0 ? '+' : null}{item.amount}</td>
                      <td>{item.saldo}</td>
                    </tr>
                  ))}
                  {data.data.length === 0
                    ? (
                      <tr><td colSpan={4} className='font-weight-bold text-center'>{LangDataEmpty}</td></tr>
                      )
                    : null}
                </tbody>
              </table>
            </div>
          </>
          )}
    </>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelLeaveHistoryComponent)
