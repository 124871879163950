import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Close, LangProcess, LangRequestSuccess, SelectDivision } from '../../constant/languange'
import { axiosCompany, loadDivisionOptions, mapStateToProps } from '../../helpers/Common'
import { AsyncSelectInput } from '../../helpers/Input'
import { SpinnerButton } from '../../helpers/Spinner'
import { useParams } from 'react-router-dom'

const ReimbursementExportEmployeeComponent = ({ redux, groupId, handleClose }) => {
  const params = useParams()
  // state
  const [form, setForm] = useState({ grub_id: groupId })
  const [control, setControl] = useState({ onSubmit: false })
  const [responseData, setResponseData] = useState({})

  const handleSubmit = (e) => {
    setControl(control => ({ ...control, onSubmit: true }))
    axiosCompany().post(`/reimbursements/${params.id}/export-excel`, form, { responseType: 'blob' })
      .then(res => {
        /**
         * pengaturan nama file
         */
        let namePrefix = ''
        if (form.group_id) {
          const group = redux.groups.find((item) => +item.id === +form.group_id)
          namePrefix += `-${group.name}`
        }

        const url = window.URL.createObjectURL(new window.Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `claim-employees${namePrefix}.xlsx`)
        document.body.appendChild(link)
        link.click()

        setResponseData(currResponseData => ({ success: true }))
        setControl(control => ({ ...control, onSubmit: false }))
      })
      .catch(err => {
        setResponseData(currResponseData => err.response.data)
      })
  }

  const handleGroupChange = (payload) => {
    setForm(currForm => ({ ...currForm, group_id: payload?.value ?? '', target: payload }))
  }

  return (
    <>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{SelectDivision}</label>
        <div className='col-sm-9'>
          <div style={{ width: '100%' }} className='form-control p-0 border-0 mr-2'>
            <AsyncSelectInput
              isLoading={false}
              isClearable
              placeholder={SelectDivision}
              loadOptions={loadDivisionOptions}
              value={form.target}
              onChange={(data) => { handleGroupChange(data) }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='col-9 offset-3'>
          {responseData.message && <div className='alert alert-danger'> <p className='my-0'>{responseData.message}</p></div>}
          {responseData.success && <div className='alert alert-success'> <p className='mb-0'>{LangRequestSuccess}</p></div>}

          <button disabled={control.showLoad} className='btn btn-primary mr-2' onClick={handleSubmit}> {(control.showLoad) ? SpinnerButton : ''} {LangProcess}</button>
          <button disabled={control.showLoad} className='btn btn-light' type='button' onClick={handleClose}>{Close}</button>
        </div>
      </div>
    </>
  )
}

export default connect(mapStateToProps)(ReimbursementExportEmployeeComponent)
