const paths = {
  common: ['/manage/group', '/work/day', '/personnels', '/personnel/work/time', '/attendance/spot', '/company/setting'],
  groups_count: ['/manage/group', '/manage/group', '/manage/group'],
  work_and_shift_patterns_count: ['/work/day', '/work/day', '/work/day', '/work/shift', '/work/shift'],
  personnels_count: ['/personnels', '/personnels', '/personnels', '/personnels'],
  spots_count: ['/attendance/spot', '/attendance/spot', '/attendance/spot'],
  work_and_shift_times_count: ['/personnel/work/time', '/personnel/work/time', '/personnel/work/shift'],
  is_complete_profile: ['/company/setting', '/company/setting']
}

export default paths
