import React from 'react'
import Content from './Content'
import { LangTitleTour3 } from '../../constant/languange'

const Third = (props) => {
  const data = {
    title: LangTitleTour3,
    steps: [
      <>Klik menu Kelola Karyawan &rarr; Data Karyawan</>,
      'Klik tombol Tambah dan tentukan divisi karyawan',
      'Impor Excel untuk alternatif cara tambah karyawan'
    ],
    button: 'Mulai Tambah Karyawan',
    nextStep: 4,
    skipTo: '/personnel/work/time',
    isDone: props.stats.personnels_count
  }

  return <Content {...props} data={data} />
}

export default Third
