import React, { useState, useEffect } from 'react'
import { mapStateToProps, connect, axiosCompany, SpinnerLoadFirst, SpinnerButton } from '../../helpers/Common'
import { LangSetting, LangOvertimeCalculation, LangOvertimeHours, WorkDay, LangH5WorkingDays, LangH6WorkingDays, Hours, LangNumberShiftWorkingDays, LangEnableOvertimeCalculation, LangChoose, LangYes, LangNo, Save, LangRequestSuccess, LangWageRates } from '../../constant/languange'
import BreadcrumbsMenu from '../../layout/breadcrumb/breadcrumbMenu'

const OvertimeCalculationComponent = ({ redux }) => {
  // state
  const [control, setControl] = useState({ onLoad: false, onSubmit: false })
  const [data, setData] = useState({
    _method: 'PUT',
    overtime_setting_default_shift_work_day: '',
    overtime_setting_active: '',
    overtime_settings: []
  })
  const [responseData, setResponseData] = useState({})

  // effect
  useEffect(() => {
    getData()
  }, [])

  // function
  const getData = () => {
    setControl(currControl => ({ ...currControl, onLoad: true }))
    axiosCompany().get('/company/overtime-setting')
      .then(res => {
        setControl(currControl => ({ ...currControl, onLoad: false }))
        setData(currData => res.data)
      })
  }

  const saveData = () => {
    setControl(currControl => ({ ...currControl, onSubmit: true }))
    axiosCompany().put('/company/overtime-setting', data)
      .then(res => {
        setResponseData(currResponseData => ({ success: true }))
      })
      .catch(err => {
        setResponseData(currResponseData => err.response.data)
      })
      .finally(() => {
        setControl(currControl => ({ ...currControl, onSubmit: false }))
      })
  }

  // handler
  const handleChangeText = (e, idx) => {
    const newOvertimeSettings = [...data.overtime_settings]
    newOvertimeSettings[idx][e.target.name] = e.target.value

    setData(currData => {
      return { ...currData, overtime_settings: newOvertimeSettings }
    })
  }

  const handleSelect = (e) => {
    setData(currData => ({ ...currData, [e.target.name]: +e.target.value }))
  }

  const handleSave = () => {
    saveData()
  }

  return (
    <>
      <BreadcrumbsMenu title={LangOvertimeCalculation}>{[LangSetting, LangOvertimeCalculation]}</BreadcrumbsMenu>

      {control.onLoad
        ? SpinnerLoadFirst
        : (
          <div className='container-fluid '>
            <div className='row'>
              <div className='col-sm-12 '>
                <div className='card'>
                  <div className='card-body'>
                    <div className='row py-3'>
                      <div className='col-sm-4 pt-2'>{LangWageRates}</div>
                      <div className='col-sm-8'>   
                        <div className='table-responsive'>
                          <table className='table table-striped table-bordered'>
                            <thead>
                              <tr>
                                <th className='text-center align-middle' scope='col' style={{ minWidth: 120 }}>{LangOvertimeHours}</th>
                                <th className='text-center align-middle' scope='col'>{WorkDay}</th>
                                <th className='text-center align-middle' scope='col'>{LangH5WorkingDays}</th>
                                <th className='text-center align-middle' scope='col'>{LangH6WorkingDays}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.overtime_settings && data.overtime_settings.map((item, idx) => (
                                <tr key={idx}>
                                  <td>{item.hours} {Hours.toLowerCase()}</td>
                                  <td>
                                    <div className='input-group' style={{ minWidth: 100, maxWidth: 180 }}>
                                      <input type='text' className={`form-control ${responseData?.errors?.[`overtime_settings.${idx}.work_day`] ? 'is-invalid' : ''}`} name='work_day' value={item.work_day} onChange={(e) => handleChangeText(e, idx)} />
                                      <div className='input-group-append'>
                                        <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>x</span>
                                      </div>
                                      <div className='invalid-feedback'>
                                        {responseData?.errors?.[`overtime_settings.${idx}.work_day`]}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='input-group' style={{ minWidth: 100, maxWidth: 180 }}>
                                      <input type='text' className={`form-control ${responseData?.errors?.[`overtime_settings.${idx}.work_day_5`] ? 'is-invalid' : ''}`} name='work_day_5' value={item.work_day_5} onChange={(e) => handleChangeText(e, idx)} />
                                      <div className='input-group-append'>
                                        <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>x</span>
                                      </div>
                                      <div className='invalid-feedback'>
                                        {responseData?.errors?.[`overtime_settings.${idx}.work_day_5`]}
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className='input-group' style={{ minWidth: 100, maxWidth: 180 }}>
                                      <input type='text' className={`form-control ${responseData?.errors?.[`overtime_settings.${idx}.work_day_6`] ? 'is-invalid' : ''}`} name='work_day_6' value={item.work_day_6} onChange={(e) => handleChangeText(e, idx)} />
                                      <div className='input-group-append'>
                                        <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>x</span>
                                      </div>
                                      <div className='invalid-feedback'>
                                        {responseData?.errors?.[`overtime_settings.${idx}.work_day_6`]}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className='row py-3 align-items-center'>
                      <div className='col-sm-4'>{LangNumberShiftWorkingDays}</div>
                      <div className='col-sm-2'>
                        <div className='form-group m-0'>
                          <select className='form-control' name='overtime_setting_default_shift_work_day' value={+data.overtime_setting_default_shift_work_day} onChange={handleSelect}>
                            <option value=''>--{LangChoose}--</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='row py-3'>
                      <div className='col-sm-4'>{LangEnableOvertimeCalculation}</div>
                      <div className='col-sm-4'>
                        <div className='form-check form-check-inline'>
                          <input className='form-check-input' type='checkbox' id='overtime_setting_active_1' name='overtime_setting_active' value={1} checked={+data.overtime_setting_active === 1} onChange={handleSelect} />
                          <label className='form-check-label' htmlFor='overtime_setting_active_1'>{LangYes.toUpperCase()}</label>
                        </div>
                        <div className='form-check form-check-inline'>
                          <input className='form-check-input' type='checkbox' id='overtime_setting_active_0' name='overtime_setting_active' value={0} checked={+data.overtime_setting_active === 0} onChange={handleSelect} />
                          <label className='form-check-label' htmlFor='overtime_setting_active_0'>{LangNo.toUpperCase()}</label>
                        </div>
                      </div>
                    </div>
                    <div className='row py-3'>
                      <div className='offset-sm-4 col-sm-8'>
                        {responseData.success && <div className='alert alert-success'>{LangRequestSuccess}</div>}
                        {responseData.message && <div className='alert alert-danger'>{responseData.message}</div>}
                        <button className='btn btn-primary' type='button' onClick={handleSave} disabled={control.onSubmit}>{control.onSubmit && SpinnerButton} {Save}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )}
    </>
  )
}

export default connect(mapStateToProps)(OvertimeCalculationComponent)
