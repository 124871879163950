import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Close, LangReceiver, LangSend, LangSendMessageSuccess } from '../../constant/languange'
import { axiosCompany, loadPersonnelOptions, SpinnerButton } from '../../helpers/Common'
import { AsyncSelectInput } from '../../helpers/Input'

function BroadcastTestSendComponent ({ id, onClose, ...props }) {
  const [errors, setError] = useState({ fields: {}, message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false })
  const [data, setData] = useState({ personnel: null, personnel_id: '' })

  const postBroadcastTestService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().post(`broadcasts/${id}/test`, data).then(() => {
      setControl(control => ({ ...control, showLoad: false, showSuccessAlert: true }))
      setError({ fields: [], message: '' })
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const handleSubmit = () => {
    postBroadcastTestService()
  }

  return (
    <div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangReceiver}</label>
        <div className='col-sm-8'>
          <AsyncSelectInput
            isLoading={false}
            isClearable
            loadOptions={loadPersonnelOptions}
            value={data.personnel}
            onChange={(item) => setData(data => ({ ...data, personnel_id: item?.value ? item?.value : '', personnel: item }))}
          />
          {errors.fields.personnel_id ? (<p className='mb-0' style={{ color: 'red' }}>{errors.fields.personnel_id[0]}</p>) : (null)}
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='col-12 col-sm-9 offset-sm-3'>
          {control.showSuccessAlert && <Alert variant='success'>{LangSendMessageSuccess}</Alert>}
          {(errors.message !== '') && <Alert variant='danger'>{errors.message}</Alert>}
          <button disabled={control.showLoad} onClick={handleSubmit} className='btn btn-primary mr-2' type='submit'>{(control.showLoad) ? SpinnerButton : ''} {LangSend}</button>
          <button disabled={control.showLoad} className='btn  btn-light' type='button' onClick={() => { onClose() }}>{Close}</button>
        </div>
      </div>
    </div>
  )
}

export default BroadcastTestSendComponent
