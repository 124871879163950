import React, { lazy } from 'react'
import { Route } from 'react-router'
import ReimbursementEmployeesComponent from '../components/reimbursement/ReimbursementEmployeesComponent'

const ReimbursementRequestComponent = lazy(() => import('../components/reimbursement/ReimbursementRequestComponent'))
const ReimbursementSettingComponent = lazy(() => import('../components/reimbursement/ReimbursementSettingComponent'))

const PersonnelRoute = () => {
  return (
    <>
      <Route exact path='/claim/request' component={ReimbursementRequestComponent} />
      <Route exact path='/claim/type' component={ReimbursementSettingComponent} />
      <Route exact path='/claim/type/:id/employees' component={ReimbursementEmployeesComponent} />
    </>
  )
}

export default PersonnelRoute
