import React, { useState, useEffect } from 'react'
import {
  connect,
  mapStateToProps
} from '../../helpers/Common'

export default connect(mapStateToProps)(({ redux }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (Object.keys(redux.notifications).length) {
      setCount(redux.notifications.attendance_review)
    }
    // eslint-disable-next-line
  }, [redux.notifications.attendance_review])

  if (count <= 0) return <></>

  return (
    <label className='badge badge-warning position-static m-0 py-1' style={{ fontSize: '9px' }}>{count}</label>
  )
})
