import React, { useState, useEffect } from 'react'

import tasklist from '../tasklist'
import TaskItem from './TaskItem'

const TaskList = ({ stats }) => {
  const [taskList, setTaskList] = useState(null)

  useEffect(() => {
    if (stats) {
      const statValues = Object.values(stats)
      const statKeys = Object.keys(stats)

      // tasklist state done/todo/muted
      statKeys.forEach((sKey) => {
        setTaskList((tls) => {
          const newTls = tasklist.map(tl => {
            if (sKey === tl.codeName) {
              if (stats[sKey] > 0) {
                tl.type = 'done'
              } else {
                const indexTodo = statValues.findIndex((value) => value === 0)
                const codeNameTodo = statKeys[indexTodo]

                if (sKey === codeNameTodo) {
                  tl.type = 'todo'
                } else {
                  tl.type = 'muted'
                }
              }
            }

            return tl
          })

          return newTls
        })
      })
    }

    return () => setTaskList(tasklist)
  }, [stats])

  return (
    <ul style={{ width: '100%' }}>
      {stats && taskList ? taskList.map((task, i) => <TaskItem key={i} task={task} />) : <div className='d-flex justify-content-center'><div className='spinner-border text-primary' role='status'><span className='sr-only'>Loading...</span></div></div>}
    </ul>
  )
}

export default TaskList
