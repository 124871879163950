import React, { useState, useEffect } from 'react'
import Tour from 'reactour'
import { useHistory } from 'react-router-dom'

import { connect, mapStateToProps, mapDispatchToProps } from '../helpers/Common'
import steps from './steps'
import NavButton from './components/NavButton'
import paths from './paths'
import './tour.scss'
import { LangOf } from '../constant/languange'

const TourProvider = ({ redux, setState }) => {
  const history = useHistory()

  // state
  const [currentStep, setCurrentStep] = useState(0)

  // effect
  useEffect(() => {
    setState({ isUpdateStats: redux.isUpdateStats + 1 })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!redux.isTourOpen) {
      setCurrentStep(0)
    }
  }, [redux.isTourOpen])

  const closeTour = () => {
    setState({ isTourOpen: false })
    document.body.style.overflow = 'auto'
  }

  // handler
  const handleNextStep = () => {
    history.push(paths[redux.tourName][currentStep + 1])
  }

  const handlePrevStep = () => {
    history.push(paths[redux.tourName][currentStep - 1])
  }

  return (
    <div className='position-relative'>
      <Tour
        className='rounded'
        steps={steps(closeTour, redux.stats, redux.tourName)}
        isOpen={redux.isTourOpen}
        onRequestClose={closeTour}
        badgeContent={(curr, tot) => `Step ${curr} ${LangOf} ${tot}`}
        showButtons
        nextButton={<NavButton type='next' onClick={handleNextStep} currentStep={currentStep} paths={paths} />}
        prevButton={<NavButton type='prev' onClick={handlePrevStep} currentStep={currentStep} paths={paths} />}
        lastStepNextButton={<NavButton type='next' onClick={() => closeTour()} currentStep={currentStep} paths={paths} />}
        getCurrentStep={(curr) => setCurrentStep(curr)}
        showNavigation={false}
        disableInteraction
        startAt={0}
      />
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TourProvider)
