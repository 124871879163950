import React from 'react'
import Content from './Content'
import { LangTitleTour4 } from '../../constant/languange'

const Fourth = (props) => {
  const data = {
    title: LangTitleTour4,
    steps: [
      <>Klik menu Kelola Karyawan &rarr; Jadwal Hari Kerja untuk pengaturan jadwal karyawan yang bukan shift.</>,
      'Klik Jadwal Shift untuk pengaturan jadwal karyawan shift.'
    ],
    button: 'Mulai Atur Jadwal Kerja',
    nextStep: 5,
    skipTo: '/attendance/spot',
    isDone: props.stats.work_and_shift_times_count
  }

  return <Content {...props} data={data} />
}

export default Fourth
