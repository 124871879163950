import React from 'react'

/**
 * Spinner
 */
const SpinnerDiv = <div className='spinner-border text-primary' role='status'><span className='sr-only'>Loading...</span></div>
const SpinnerButton = <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'><span className='sr-only'>Loading...</span></span>
const SpinnerLoadFirst = <center>{SpinnerDiv}</center>
const SpinnerLoadPages = <div className='w-100 text-center'><div className='mt-4 spinner-border text-primary' /></div>
export const Spinner9 = ({ color = 'light', size = 13, ...props }) => (
  <div className='loader-box d-inline-block' style={{ height: 'auto' }}>
    <div className={`loader-9 bg-${color}`} style={{ width: size, height: size }} />
  </div>
)

export {
  SpinnerDiv,
  SpinnerButton,
  SpinnerLoadFirst,
  SpinnerLoadPages
}
