import { connect } from 'react-redux'
import { mapStateToProps } from '../../../../helpers/Common'
import { LangEmpty, LangNo, LangRequired, LangYes, Options } from '../../../../constant/languange'

const additionalFields = ({ redux, val }) => {
  const showOnForm = ['Datang', 'Pulang', 'Datang dan Pulang']
  return (
    <div className='table-responsive my-3 text-center'>
      <table className='table table-sm'>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>Label</th>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>Placeholder</th>
            <th scope='col' className='text-center align-middle'>{LangRequired}</th>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>Form</th>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 200 }}>{Options}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.label}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.placeholder || LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.required ? LangYes : LangNo}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.show_on_form ? showOnForm[+item.show_on_form - 1] : LangEmpty}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.options?.length > 0
                ? <ul className='list-group small'>{item.options.map((item, i) => <li key={i} className={`list-group-item p-1 ${redux.darkModeOn ? 'bg-dark' : ''}`}>{item.label}</li>)}</ul>
                : LangEmpty}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(additionalFields)
