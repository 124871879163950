import React from 'react'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Home } from '../../constant/languange'
import { Link } from 'react-router-dom'

const Breadcrumbs = (props) => {
  return (
    <>
      <Container fluid>
        <div className='page-title'>
          <Row>
            <Col lg='7'>
              <h3>{props.title}</h3>
              <Breadcrumb className='breadcrumb justify-content-start mt-3'>
                <BreadcrumbItem><Link to='/dashboard'>{Home}</Link></BreadcrumbItem>
                <BreadcrumbItem active>{props.child}</BreadcrumbItem>
                {props.subChild ? <BreadcrumbItem active>{props.title}</BreadcrumbItem> : null}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default Breadcrumbs
