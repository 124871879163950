import React from 'react'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  axiosCompany
} from '../../helpers/Common'
import {
  LangProcess,
  LangImportant,
  LangChooseFile,
  LangMaxSize,
  LangImportCancel,
  LangImportDone,
  LangImportErrorMsg1,
  LangImportShiftHelp4,
  LangImportShiftHelp5,
  LangImportShiftHelp6,
  // LangImportShiftHelp7,
  LangImportClientVisitHelp3
} from '../../constant/languange'

class VisitScheduleImportComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      file: '',
      replaceExisting: 0,
      errors: {},
      onSubmit: false,
      errorMessage: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
  }

  handleDownloadFormat (e) {
    e.preventDefault()
    this.props.handleDownloadFormat()
  }

  handleSubmit (e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, importResult: null, errorMessage: '' })

    const formData = new window.FormData()
    formData.append('file', this.state.file)
    // formData.append('replace_existing', (this.state.replaceExisting) ? '1' : '0')

    axiosCompany()
      .post('client-visit-schedules/import-excel', formData)
      .then((result) => {
        this.setState({
          onSubmit: false,
          errorMessage: '',
          importResult: result.data
        }, () => {
          if (result.data.success) {
            this.props.handleUpdate()
          }
        })
      }).catch((error) => {
        if (error.response.data.errors) {
          this.setState({ errors: error.response.data.errors })
        } else {
          this.setState({ errors: { 0: [error.response.statusText] } })
        }

        if (error.response.data.message) {
          this.setState({ errorMessage: error.response.data.message })
        }

        this.setState({ onSubmit: false })
      })
  }

  handleChangeFile (e) {
    if (e.target.files[0]) {
      this.setState({ file: e.target.files[0] })
    }
  }

  render () {
    const s = this.state
    const p = this.props

    return (
      <div>
        <div className={`alert ${p.redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
          <b>{LangImportant.toUpperCase()}!</b>
          <ul style={{ listStyleType: 'circle', paddingLeft: '40px' }}>
            <li>{LangImportClientVisitHelp3}. <a onClick={(e) => this.handleDownloadFormat(e)} href='#download-format'>{LangImportShiftHelp4}.</a></li>
            <li>{LangImportShiftHelp5}.</li>
            <li>{LangImportShiftHelp6}.</li>
          </ul>
        </div>

        <form onSubmit={this.handleSubmit}>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-lg-right'>{LangChooseFile} <b className='text-danger'>*</b></label>
            <div className='col-sm-4'>
              <input
                type='file'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={this.handleChangeFile}
              />
              <div className='help'>{LangMaxSize}: 500 KB</div>
              {(s.errors.file) ? <div className='invalid-feedback d-block'><strong>{s.errors.file}</strong></div> : null}
            </div>
          </div>
          {/* <div className='form-group row'>
            <label className='col-sm-3 col-form-label text-lg-right' />
            <div className='col-sm-9'>
              <div className='form-check'>
                <input className='form-check-input' type='checkbox' id='replace-existing' value='1' onChange={(e) => this.setState({ replaceExisting: e.target.checked })} />
                <label className='form-check-label' htmlFor='replace-existing'>
                  {LangImportShiftHelp7}.
                </label>
              </div>
            </div>
          </div> */}
          <hr />
          <div className='form-group row'>
            <div className='col-sm-9 offset-md-3'>
              {s.errorMessage ? <div className='alert alert-danger'>{s.errorMessage}</div> : null}
              {s.importResult
                ? (
                  <div>
                    {(!s.importResult.success)
                      ? (
                        <div className='alert alert-primary'>
                          {(s.importResult.date_errors)
                            ? (
                              <div className='mb-2'>
                                <b>Error Tanggal</b>: <br />
                                <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                                  {s.importResult.date_errors.map((msg, index) => {
                                    return (
                                      <li key={index}>{msg}</li>
                                    )
                                  })}
                                </ol>
                              </div>
                              )
                            : null}

                          {(s.importResult.pattern_errors)
                            ? (
                              <div className='mb-2'>
                                <b>Error Pola</b>: <br />
                                <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                                  {s.importResult.pattern_errors.map((msg, index) => {
                                    return (
                                      <li key={index}>{msg}</li>
                                    )
                                  })}
                                </ol>
                              </div>
                              )
                            : null}

                          {(s.importResult.personnel_errors)
                            ? (
                              <div className='mb-2'>
                                <b>Error Karyawan</b>: <br />
                                <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                                  {s.importResult.personnel_errors.map((msg, index) => {
                                    return (
                                      <li key={index}>{msg}</li>
                                    )
                                  })}
                                </ol>
                              </div>
                              )
                            : null}

                          {(s.importResult.schedule_errors)
                            ? (
                              <div className='mb-2'>
                                <b>Error Jadwal</b>: <br />
                                <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                                  {s.importResult.schedule_errors.map((msg, index) => {
                                    return (
                                      <li key={index}>{msg}</li>
                                    )
                                  })}
                                </ol>
                              </div>
                              )
                            : null}

                          <div>
                            {LangImportErrorMsg1}
                          </div>
                        </div>
                        )
                      : (
                        <div className='alert alert-success'>
                          <ol style={{ display: 'inline-block', marginLeft: '-25px' }}>
                            {s.importResult.messages.map((msg, index) => {
                              return (
                                <li key={index}>{msg}</li>
                              )
                            })}
                          </ol>
                        </div>
                        )}
                    <button onClick={() => p.handleClose()} type='button' className='btn btn-light'>{LangImportDone}</button>
                  </div>
                  )
                : (
                  <div>
                    <button disabled={s.onSubmit} type='submit' className='btn btn-primary'>{(s.onSubmit) ? SpinnerButton : null} {LangProcess}</button>
                  &nbsp;
                    <button disabled={s.onSubmit} onClick={() => p.handleClose()} type='button' className='btn btn-light'>{LangImportCancel}</button>
                  </div>
                  )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default connect(mapStateToProps)(VisitScheduleImportComponent)
