import React from 'react'
import { LangActionConfirm, LangPermitNotNow, LangPermitYesSure } from '../../constant/languange'
import { SpinnerButton } from '../../helpers/Spinner'
import { connect } from 'react-redux'
import { mapStateToProps } from '../../helpers/Common'

function ModalConfirmComponent ({ redux, onSubmit, onClose, loader, caption, btnConfirmColor, errorMessage, successMessage, ...props }) {
  return (
    <div>
      <p>{caption ?? LangActionConfirm}</p>
      <hr />
      {errorMessage !== '' && (<div className='alert alert-danger'>{errorMessage}</div>)}
      {successMessage && (<div className='alert alert-success'>{successMessage}</div>)}
      {!successMessage && (
        <>
          <button disabled={loader} onClick={() => onSubmit()} className={`btn btn-${btnConfirmColor || 'primary'} mr-2 mb-2`}>{(loader) ? SpinnerButton : null} {LangPermitYesSure}</button>
          <button disabled={loader} onClick={() => onClose()} type='button' className={`btn ${redux.darkModeOn ? 'btn-dark' : 'btn-light'} mb-2`}>{LangPermitNotNow}</button>
        </>
      )}
    </div>
  )
}

export default connect(mapStateToProps)(ModalConfirmComponent)
