import { faEllipsisH, faFileUpload, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Action, DataEmployees, Division, LangBulkAction, LangExportExcel, LangImportExcel, Name, PhoneNumber, Search,
  LangLeaveUpdate,
  LangLeaveTransactionHistory,
  LangDeleteEmployee,
  LangActiveEmployee,
  LangPayrollDateRangeInfo,
  ChangeHistories,
  LangStartDeleted,
  LangEndDeleted,
  LangDeletedReason,
  LangBirthDate,
  StartWork2
} from '../../constant/languange'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  PaginationDiv,
  SpinnerLoadFirst,
  displayDate,
  axiosCompany,
  mapDispatchToProps,
  moment,
  axiosCompanyFile
} from '../../helpers/Common'
import { InputRangeDatePicker, ModalApp } from '../../helpers/Input'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import PersonnelBreadcrumbs from '../../layout/breadcrumb/personnel'
import PersonnelExportLayout from './PersonnelExportComponent'
import PersonnelImportLayout from './PersonnelImportComponent'
import PersonnelHistoryLayout from './PersonnelHistoryComponent'
import PersonnelBulkActionComponent from './PersonnelBulkActionComponent'
import PersonnelItemComponent from './PersonnelItemComponent'
import ModalAdminActivityLogsComponent from '../admin/ModalAdminActivityLogsComponent'
import PersonnelLeaveUpdateComponent from './PersonnelLeaveUpdateComponent'
import PersonnelLeaveHistoryComponent from './PersonnelLeaveHistoryComponent'
import PersonnelDeleteComponent from './PersonnelDeleteComponent'

function PersonnelDeletedComponent({ redux, setState, ...props }) {
  const history = useHistory()
  const location = useLocation()

  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0
    },
    stats: {
      total: 0,
      total_document_expired: 0,
      total_document_in_reminder: 0,
      total_doesnt_have_group: 0,
      total_doesnt_have_worktime: 0
    }
  })
  let [filter, setFilter] = useState({
    keyword: '',
    group_id: '',
    deleted_date_start: '',
    deleted_date_end: '',
    page: 1
  })
  const [importRefreshOnClose, setImportRefreshOnClose] = useState(false)
  const [currentData, setCurrentData] = useState(null)
  const [control, setControl] = useState({ showLoad: false, showModal: '', showAlert: '', onExport: false })
  const { id = null } = useParams()

  useEffect(() => {
    if (window.performance.navigation.type === 1) {
      console.log('clear filter')
      window.localStorage.removeItem('filterPersonnel')
    }
    if (history.action === 'POP' || location.state) {
      // eslint-disable-next-line
      filter = { ...JSON.parse(window.localStorage.getItem('filterPersonnel')) }
      setFilter({ ...filter })
    }
    getPersonnelsService()
  }, [])

  const getPersonnelsService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get('personnels/deleted', { params: filter }).then((ress) => {
      setData(data => {
        data = ress.data
        return { ...data }
      })
      setControl(control => ({ ...control, showLoad: false, showModal: '' }))
    }).catch(e => {
      setControl(control => ({ ...control, showLoad: false }))
    })

    window.scrollTo(0, 0)
  }

  const handleRoute = (path) => {
    history.push(path)
  }

  const handleKeydown = (e) => {
    if (e.key === 'Enter') {
      filter.page = 1
      window.localStorage.setItem('filterPersonnel', JSON.stringify(filter))
      getPersonnelsService()
    }
  }
  const handlePage = (page) => {
    filter.page = page
    window.localStorage.setItem('filterPersonnel', JSON.stringify(filter))
    getPersonnelsService()
  }

  const handleSearch = () => {
    filter.page = 1
    window.localStorage.setItem('filterPersonnel', JSON.stringify(filter))
    getPersonnelsService()
  }

  const handleChangeGroup = (e) => {
    filter.group_id = e.target.value
    filter.page = 1
    getPersonnelsService()
  }

  const handleDateChange = (value) => {
    /**
     * cek apakah lebih dari 60 hari, jika ya paksa maks 60 hari
     */
    let startDate = value.startDate
    let showAlert = ''
    const endDateSub = moment(value.endDate).subtract(2, 'month')
    if (moment(value.startDate).isBefore(endDateSub)) {
      startDate = endDateSub.format('YYYY-MM-DD')
      showAlert = 'date-range'
    }

    setControl(currControl => ({ ...currControl, showAlert }))
    filter.deleted_date_start = startDate
    filter.deleted_date_end = value.endDate
    filter.page = 1
    getPersonnelsService()
  }

  const handleExport = () => {
    const filterExport = { ...filter }
    delete filterExport.page

    let url = '/personnels/export-deleted?'
    Object.keys(filterExport).forEach((key, idx) => {
      if (filterExport[key]) {
        url += `${key}=${filterExport[key]}&`
      }
    })

    setControl(control => ({ ...control, onExport: true }))
    axiosCompanyFile().post(url)
      .then(res => {
        const url = window.URL.createObjectURL(new window.Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'deleted-employee.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
        setControl(control => ({ ...control, onExport: false }))
      })
  }

  return (
    <>
      <PersonnelBreadcrumbs title={DataEmployees} id={id} routeType='personnel' subRoutes={id ? ['list', 'detail', 'edit'] : ['list', 'deleted']} />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='card'>
              <div className='card-header'>
                <div className='row'>
                  <div className='col-12 col-xl-10'>
                    <div className='d-flex flex-column flex-md-row'>
                      <div className='mr-sm-2 mb-2 px-0' style={{ maxWidth: '330px' }}>
                        <InputRangeDatePicker
                          startDate={filter.deleted_date_start}
                          endDate={filter.deleted_date_end}
                          placeholderStartDate={LangStartDeleted}
                          placeholderEndDate={LangEndDeleted}
                          onChange={handleDateChange}
                        />
                        {control.showAlert === 'date-range' ? (<div className='alert alert-info outline small py-1 px-2 mt-2 mb-0'>{LangPayrollDateRangeInfo}</div>) : null}
                      </div>
                      <div className='mr-sm-2 mb-2 px-0'>
                        <select value={filter.group_id} className='form-control ' style={{ maxWidth: 250 }} onChange={handleChangeGroup}>
                          <option value=''>--{Division}--</option>
                          {redux.groups.map((obj) => {
                            return (
                              <option key={obj.id} value={obj.id}>{obj.name}</option>
                            )
                          })}
                        </select>
                      </div>
                      <div className='mb-2'>
                        <div className='input-group'>
                          <input type='search' className='form-control' placeholder={Search} value={filter.keyword} onKeyDown={handleKeydown} onChange={(e) => { setFilter({ ...filter, keyword: e.target.value }) }} style={{ maxWidth: 250 }} />
                          <div className='input-group-append' disabled={control.showLoad} id='button-addon2' onClick={handleSearch}>
                            <span className={`input-group-text ${redux.darkModeOn ? 'bg-dark border-dark' : 'bg-light text-dark'}`}>
                              {control.showLoad ? SpinnerButton : <FontAwesomeIcon icon={faSearch} />}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-xl-2'>
                    <div className='d-md-flex flex-column flex-sm-row flex-xl-wrap justify-content-xl-end'>
                      {!redux.profile.supervisor_groups && (
                        <>
                          <button data-tip={LangActiveEmployee} className='btn btn-primary mb-2 mr-2 px-3' type='button' onClick={() => { history.push('/personnels') }}>
                            <FontAwesomeIcon icon={faUsers} />
                          </button>
                          <button data-tip={LangExportExcel} disabled={control.onExport} className='btn btn-primary mb-2 mr-2 px-3' type='button' onClick={handleExport}>
                            {control.onExport ? SpinnerButton : <FontAwesomeIcon icon={faFileUpload} />}
                          </button>
                        </>
                      )}
                      {/* add other right menu here */}
                      <ReactTooltip place='bottom' />
                    </div>
                  </div>
                </div>
              </div>

              {control.showLoad
                ? SpinnerLoadFirst
                : (
                  <div className='table-responsive'>
                    <table className='table table-striped table-bordered'>
                      <thead>
                        <tr>
                          <th className='text-center align-middle' scope='col'>{Name}</th>
                          <th className='text-center align-middle' scope='col'>{LangDeletedReason}</th>
                          <th className='text-center align-middle' scope='col'>Email & {PhoneNumber}</th>
                          <th className='text-center align-middle' scope='col'>{LangBirthDate}</th>
                          <th className='text-center align-middle' scope='col'>{StartWork2}</th>
                          <th className='text-center align-middle' scope='col' style={{ minWidth: '100px' }}>{Action}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.data.map((obj, index) => {
                          return (
                            <tr key={obj.id}>
                              <td style={{ minWidth: 250, maxWidth: 250 }}>
                                <PersonnelItemComponent listType='deleted-users' data={obj} key={'personnel-' + obj.id} />
                              </td>
                              <td style={{ minWidth: 220, maxWidth: 220 }}>
                                {obj.deleted_reason}
                              </td>
                              <td style={{ minWidth: 150, maxWidth: 150 }}>
                                {obj.email}
                                <br />
                                {obj.phone_no}
                              </td>
                              <td style={{ minWidth: 150, maxWidth: 150 }}>
                                {obj.birth_date && displayDate(obj.birth_date, 'DD MMM YYYY')}
                              </td>
                              <td style={{ minWidth: 150, maxWidth: 150 }}>
                                {obj.start_work && displayDate(obj.start_work, 'DD MMM YYYY')}
                              </td>
                              <td>
                                <ReactTooltip place='bottom' />
                                <a style={{ cursor: 'pointer' }} id='moreDropdown' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' className={`btn-light btn-sm mr-2 mb-2 ${redux.darkModeOn ? 'text-light' : ''}`}><FontAwesomeIcon icon={faEllipsisH} /></a>
                                <div className='dropdown-menu shadow' aria-labelledby='moreDropdown'>
                                  <a className='dropdown-item' style={{ cursor: 'pointer' }} onClick={() => { setControl({ ...control, showModal: 'activity-log' }); setCurrentData(obj) }}>{ChangeHistories}</a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}

              <div className='card-footer'>
                <PaginationDiv data={data} onChange={handlePage} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {control.showModal === 'leave-transaction-history' && (
        <ModalApp
          title={LangLeaveTransactionHistory}
          handleClose={() => { setControl({ ...control, showModal: '' }) }}
          body={<PersonnelLeaveHistoryComponent currentData={currentData} />}
        />
      )}

      {control.showModal === 'leave-update' && (
        <ModalApp
          title={LangLeaveUpdate}
          handleClose={() => { setControl({ ...control, showModal: '' }) }}
          body={<PersonnelLeaveUpdateComponent
            currentData={currentData}
            handleClose={() => { setControl({ ...control, showModal: '' }) }}
            handleEdit={() => { handleRoute(`/personnels/${currentData.id}/edit`) }}
            getPersonnelsService={getPersonnelsService}
          />}
        />
      )}

      {control.showModal === 'activity-log' && (
        <ModalApp
          title={ChangeHistories}
          size='xl'
          handleClose={() => { setControl({ ...control, showModal: '' }) }}
          body={<ModalAdminActivityLogsComponent type='Personnel' id={currentData?.id} />}
        />
      )}

      {control.showModal === 'delete' && (
        <ModalApp
          title={LangDeleteEmployee}
          handleClose={() => { setControl({ ...control, showModal: '' }) }}
          size='md'
          body={<PersonnelDeleteComponent
            currentData={currentData}
            handleClose={() => { setControl({ ...control, showModal: '' }) }}
            getPersonnelsService={getPersonnelsService}
          />}
        />
      )}

      {(control.showModal === 'bulk_action') && (
        <ModalApp
          title={LangBulkAction}
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })); getPersonnelsService() }}
          body={<PersonnelBulkActionComponent onClose={() => { setControl(control => ({ ...control, showModal: '' })); getPersonnelsService() }} />}
        />
      )}
      {(control.showModal === 'history') && (
        <ModalApp
          title={ChangeHistories}
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={<PersonnelHistoryLayout id={currentData.id} />}
        />
      )}
      {(control.showModal === 'export') && (
        <ModalApp
          title={LangExportExcel}
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={<PersonnelExportLayout onClose={() => { setControl(control => ({ ...control, showModalExport: false })) }} />}
        />
      )}
      {(control.showModal === 'import') && (
        <ModalApp
          title={LangImportExcel}
          handleClose={() => {
            if (importRefreshOnClose) { getPersonnelsService() }
            setControl(control => ({ ...control, showModal: '' }))
          }}
          body={
            <PersonnelImportLayout
              onUpdate={() => setImportRefreshOnClose(true)}
              handleClose={() => {
                if (importRefreshOnClose) { getPersonnelsService() }
                setControl(control => ({ ...control, showModal: '' }))
              }}
            />
          }
        />
      )}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonnelDeletedComponent)
