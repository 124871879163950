import { LangPerattendance, LangPermonth, LangSalaryType, Name } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const Components = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle w-50'>{Name}</th>
            <th scope='col' className='text-center align-middle w-50'>Nominal</th>
            <th scope='col' className='text-center align-middle w-50'>{LangSalaryType}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.name}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.nominal}</td>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.period === 1 ? LangPermonth : item?.period === 2 ? LangPerattendance : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(Components)
