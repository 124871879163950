import { Name } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const Personnels = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center' style={{ maxHeight: 160, overflow: 'auto' }}>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>{Name}</th>
          </tr>
        </thead>
        <tbody>
          {val.map((item, i) => (
            <tr key={i}>
              <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{item?.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(Personnels)
