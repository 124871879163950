import React from 'react'
import { Modal } from 'react-bootstrap'
import { cookies } from './Common'

class ModalDelete extends React.Component {
  render () {
    return (
      <>
        <Modal style={{ marginTop: '15%' }} animation={false} show={this.props.show} onHide={this.props.onHide}>
          <Modal.Header closeButton={this.props.closeButton}>
            <Modal.Title> {cookies.get('lang') === 'EN' ? ('Are you sure you want to permanently delete this data ?') : ('Apakah Anda yakin ingin menghapus data ini secara permanen?')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.btn}
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default ModalDelete
