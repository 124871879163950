import React from 'react'
import { AsyncSelectInput, InputTimePicker } from '../../helpers/Input'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  axiosCompany
} from '../../helpers/Common'
import {
  Close,
  Process,
  LangClient,
  LangTimeStart,
  LangTimeEnd,
  LangAction,
  SelectClient,
  LangAdd,
  LangClientVisitMustOnSpot
} from '../../constant/languange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Success } from '../../helpers/Message'

class VisitScheduleSelectClientComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      clients: [],
      groupId: '',
      errors: {},
      onSubmit: false,
      errorMessage: '',
      responseData: {}
    }
  }

  componentDidMount() {
    const clients = this.props.currentData.filter(item => item.client).map((item) => ({
      client: {
        label: item.client.name,
        value: item.client_id,
        data: item.client
      },
      id: item.client_id,
      start_time: item.start_time,
      end_time: item.end_time,
      client_visit_must_on_spot: item.client_visit_must_on_spot === 1
    }))
    if (clients.length === 0) {
      this.setState({
        clients: [{
          client: null,
          id: null,
          start_time: null,
          end_time: null
        }]
      })
    } else {
      this.setState({ clients: clients })
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({ onSubmit: true, errors: {}, errorMessage: '' })

    const data = {
      date: this.props.focusDate.format('YYYY-MM-DD'),
      // client_ids: this.state.clients.map(item => item.value),
      clients: this.state.clients.map(item => ({
        id: item.id ?? '',
        start_time: item.start_time ?? '',
        end_time: item.end_time ?? '',
        client_visit_must_on_spot: item.client_visit_must_on_spot ? +item.client_visit_must_on_spot : 0
      }))
    }

    axiosCompany().put(`client-visit-schedules/${this.props.focusPersonnelId}`, data)
      .then((res) => {
        this.setState({ onSubmit: false, responseData: res.data })
        this.props.onUpdate()
        setTimeout(() => {
          this.props.handleClose()
        }, 2000)
      }).catch((err) => {
        this.setState({ errorMessage: err.response.data.message, errors: err.response.data.errors, onSubmit: false })
        // this.setState({ onSubmit: false })
      })
  }

  loadClientOptions = (inputValue) => {
    return new Promise((resolve) => {
      axiosCompany()
        .get(`client-visit-clients?page=null&keyword=${inputValue}`)
        .then((result) => {
          resolve(result.data.map((item) => {
            const label = item.name

            return { label, value: item.id, data: item }
          }))
        })
    })
  }

  onInputClientChange = (payload, i) => {
    this.setState(currState => {
      let newState = JSON.parse(JSON.stringify(currState))
      newState.clients[i] = {
        ...newState.clients[i],
        client: payload ? {
          label: payload?.label ?? '',
          value: payload?.value ?? '',
          data: payload?.data ?? ''
        } : null,
        id: payload?.value ?? '',
        client_visit_must_on_spot: payload?.data.client_visit_must_on_spot
      }
      return newState
    })
  }

  onInputTimeChange = (payload, i, type) => {
    this.setState(currState => {
      let newState = JSON.parse(JSON.stringify(currState))
      newState.clients[i] = {
        ...newState.clients[i],
        [type]: payload
      }
      return newState
    })
  }

  handleAddClient = (e) => {
    e.preventDefault()
    this.setState(currState => {
      let newState = JSON.parse(JSON.stringify(currState))
      newState.clients.push({
        client: null,
        id: null,
        start_time: null,
        end_time: null
      })
      return newState
    })
  }

  handleDeleteClient = (e, i) => {
    e.preventDefault()
    this.setState(currState => {
      let newState = JSON.parse(JSON.stringify(currState))
      newState.clients.splice(i, 1)
      return newState
    })
  }

  render() {
    const s = this.state
    const p = this.props

    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        <div className='form-group row'>
          <div className='col-sm-12 mt-2'>
            <table class='table table-responsive-lg table-bordered'>
              <thead>
                <tr>
                  <th className='text-center txt-dark align-middle' style={{ minWidth: 200 }}>{LangClient} <span className='text-danger'>*</span></th>
                  <th className='text-center txt-dark align-middle' style={{ minWidth: 121 }}>{LangTimeStart}</th>
                  <th className='text-center txt-dark align-middle' style={{ minWidth: 135 }}>{LangTimeEnd}</th>
                  <th className='text-center txt-dark align-middle' style={{ minWidth: 155 }}>{LangClientVisitMustOnSpot}</th>
                  <th className='text-center txt-dark align-middle'>{LangAction}</th>
                </tr>
              </thead>
              <tbody>
                {
                  s.clients?.map((item, i) => {
                    return (
                      <tr key={`client-${i}`}>
                        <td width={'45%'}>
                          <AsyncSelectInput
                            key={`select-client-${i}`}
                            placeholder={SelectClient}
                            isLoading={false}
                            isClearable
                            loadOptions={this.loadClientOptions}
                            value={s.clients[i].client}
                            onChange={(value) => this.onInputClientChange(value, i)}
                          />
                          {(s.errors?.[`clients.${i}.id`]) ? <div className='invalid-feedback d-block'>{s.errors?.[`clients.${i}.id`]}</div> : null}
                        </td>
                        <td width={'20%'}>
                          <InputTimePicker
                            key={`start-time-${i}`}
                            error={s.errors?.start_time}
                            value={s.clients[i].start_time}
                            placeholder={LangTimeStart}
                            timeIntervals={30}
                            formatReturn={'HH:mm'}
                            onChange={(value) => this.onInputTimeChange(value, i, 'start_time')}
                          />
                          {(s.errors?.[`clients.${i}.start_time`]) ? <div className='invalid-feedback d-block'>{s.errors?.[`clients.${i}.start_time`]}</div> : null}
                        </td>
                        <td width={'20%'}>
                          <InputTimePicker
                            key={`end-time-${i}`}
                            error={s.errors?.end_time}
                            placeholder={LangTimeEnd}
                            value={s.clients[i].end_time}
                            timeIntervals={30}
                            formatReturn={'HH:mm'}
                            onChange={(value) => this.onInputTimeChange(value, i, 'end_time')}
                          />
                          {(s.errors?.[`clients.${i}.end_time`]) ? <div className='invalid-feedback d-block'>{s.errors?.[`clients.${i}.end_time`]}</div> : null}
                        </td>
                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                          {
                            item.client ?
                              item.client.data?.latitude && item.client.data?.longitude && item.client.data?.radius ?
                                <div className='custom-control custom-switch' style={{ lineHeight: 1.8 }}>
                                  <input type='checkbox' className='custom-control-input' style={{ cursor: 'pointer' }} id={`clientVisitMustOnSpot${i}`} checked={Object.keys(item).indexOf('client_visit_must_on_spot') !== -1 ? item.client_visit_must_on_spot : item.client.data.client_visit_must_on_spot} onChange={(e) => this.onInputTimeChange(e.target.checked, i, 'client_visit_must_on_spot')} />
                                  <label className='custom-control-label' htmlFor={`clientVisitMustOnSpot${i}`} style={{ cursor: 'pointer' }}>&nbsp;</label>
                                </div>
                                : ''
                              : ''
                          }
                          {(s.errors?.[`clients.${i}.client_visit_must_on_spot`]) ? <div className='invalid-feedback d-block'>{s.errors?.[`clients.${i}.client_visit_must_on_spot`]}</div> : null}
                        </td>
                        <td width={'15%'}><button className='btn btn-light btn-sm mt-1' onClick={(e) => this.handleDeleteClient(e, i)}><FontAwesomeIcon icon={faTimes} /></button></td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <td colSpan={4}><button className='btn btn-primary btn-sm' onClick={this.handleAddClient}>{LangAdd}</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangSpesificClient}</label>
          <div className='col-sm-9'>
            <AsyncSelectInput
              isLoading={false}
              isClearable
              isMulti
              loadOptions={loadClientOptions}
              value={s.clients}
              onChange={(value) => this.setState({ clients: value })}
            />
            {(s.errors.personnel_id) ? <div className='invalid-feedback d-block'><strong>{s.errors.personnel_id}</strong></div> : null}
          </div>
        </div> */}
        <div className='form-group row'>
          <div className='col-sm-12'>
            {s.responseData.success ? <Success /> : null}
            {s.errorMessage ? <div className='alert alert-danger'>{s.errorMessage}</div> : null}
          </div>
        </div>
        <hr />
        <div className='form-group row'>
          <div className='col-sm-12 d-flex justify-content-end'>
            <button disabled={s.onSubmit} type='submit' className='btn btn-primary mr-2'>{(s.onSubmit) ? SpinnerButton : null} {Process}</button>
            <button onClick={p.handleClose} disabled={s.onSubmit} type='button' className='btn btn-light'>{Close}</button>
          </div>
        </div>
      </form>
    )
  }
}

export default connect(mapStateToProps)(VisitScheduleSelectClientComponent)
