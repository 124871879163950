import { LangTitleTour1, LangTitleTour2, LangTitleTour3, LangTitleTour4, LangTitleTour5, LangTitleTour6 } from '../constant/languange'

const tasklist = [
  {
    codeName: 'groups_count',
    name: LangTitleTour1,
    type: 'todo',
    link: '/manage/group'
  },
  {
    codeName: 'work_and_shift_patterns_count',
    name: LangTitleTour2,
    type: 'muted',
    link: '/work/day'
  },
  {
    codeName: 'personnels_count',
    name: LangTitleTour3,
    type: 'muted',
    link: '/personnels'
  },
  {
    codeName: 'work_and_shift_times_count',
    name: LangTitleTour4,
    type: 'muted',
    link: '/personnel/work/time'
  },
  {
    codeName: 'spots_count',
    name: LangTitleTour5,
    type: 'muted',
    link: '/attendance/spot'
  },
  {
    codeName: 'is_complete_profile',
    name: LangTitleTour6,
    type: 'muted',
    link: '/company/setting'
  }
]

export default tasklist
