import React, { useState } from 'react'
import {
  connect,
  mapStateToProps,
  SpinnerButton,
  axiosCompany,
  objectToFormData
} from '../../helpers/Common'
import {
  LangProcess,
  LangImportant,
  LangChooseFile,
  LangMaxSize,
  LangImportCancel,
  LangImportDone,
  LangImportShiftHelp4,
  LangImportShiftHelp5,
  LangImportShiftHelp6,
  LangImportClaimEmployeeHelp3
} from '../../constant/languange'
import { useParams } from 'react-router-dom'

const ReimbursementImportEmployeeComponent = ({ redux, handleClose, handleDownloadFormat, onAfterImport }) => {
  const params = useParams()
  // state
  const [form, setForm] = useState({ file: '' })
  const [control, setControl] = useState({ onSubmit: false })
  const [responseData, setResponseData] = useState({})

  // handler
  const handleChangeFile = (e) => {
    if (e.target.files[0]) {
      setForm(currForm => ({ ...currForm, file: e.target.files[0] }))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setControl(currControl => ({ ...currControl, onSubmit: true }))

    const payload = objectToFormData(form)
    axiosCompany().post(`/reimbursements/${params.id}/import-excel`, payload)
      .then(res => {
        setResponseData(currResponseData => res.data)
        setControl(control => ({ ...control, onSubmit: false }))
        onAfterImport()
      }).catch(err => {
        setResponseData(currResponseData => err.response.data)
        setControl(control => ({ ...control, onSubmit: false }))
      })
  }

  return (
    <div>
      <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'}`}>
        <b>{LangImportant.toUpperCase()}!</b>
        <ul style={{ listStyleType: 'circle', paddingLeft: '40px' }}>
          <li>{LangImportClaimEmployeeHelp3}. <a onClick={handleDownloadFormat} className='text-primary' style={{ cursor: 'pointer' }}>{LangImportShiftHelp4}.</a></li>
          <li>{LangImportShiftHelp5}.</li>
          <li>{LangImportShiftHelp6}.</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='form-group row'>
          <label className='col-sm-3 col-form-label text-lg-right'>{LangChooseFile} <b className='text-danger'>*</b></label>
          <div className='col-sm-4'>
            <input
              type='file'
              accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              onChange={handleChangeFile}
            />
            <div className='help'>{LangMaxSize}: 500 KB</div>
            {(responseData.errors?.file) && <div className='invalid-feedback d-block'><strong>{responseData.errors?.file}</strong></div>}
          </div>
        </div>
        <hr />
        <div className='form-group row'>
          <div className='col-sm-9 offset-md-3'>
            {responseData.message && (
              <div className='alert alert-danger'>{responseData.message}</div>
            )}

            {responseData.success
              ? (
                <>
                  <div className='alert alert-success'>{responseData.messages}</div>
                  <button onClick={handleClose} type='button' className='btn btn-light'>{LangImportDone}</button>
                </>)
              : (
                <div>
                  <button disabled={control.onSubmit} type='submit' className='btn btn-primary mr-2'>{(control.onSubmit) ? SpinnerButton : null} {LangProcess}</button>
                  <button disabled={control.onSubmit} onClick={handleClose} type='button' className='btn btn-light'>{LangImportCancel}</button>
                </div>)}
          </div>
        </div>
      </form>
    </div>

  )
}

export default connect(mapStateToProps)(ReimbursementImportEmployeeComponent)
