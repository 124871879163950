import React from 'react'
import Content from './Content'
import { LangTitleTour6 } from '../../constant/languange'

const Sixth = (props) => {
  const data = {
    title: LangTitleTour6,
    steps: [<>Klik menu Pengaturan &rarr; Perusahaan dan lengkapi  informasi Bidang Usaha, Alamat, Provinsi, Kabupaten/Kota, Kecamatan & Kelurahan.</>],
    button: 'Mulai Lengkapi Data Perusahaan',
    nextStep: 7,
    skipTo: '/dashboard',
    isDone: props.stats.is_complete_profile
  }

  return <Content {...props} data={data} />
}

export default Sixth
