import React from 'react'
import {
  connect,
  mapStateToProps,
  cookies
} from '../../helpers/Common'

const isLangEN = cookies.get('lang') === 'EN'

export default connect(mapStateToProps)((props) => {
  if (props.redux.personnelsQuota && props.redux.personnelsCount && +props.redux.personnelsCount > +props.redux.personnelsQuota) {
    return (
      <div className='container-fluid'>
        <div className='page-title pb-0'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='alert alert-secondary inverse alert-dismissible fade show'>
                <i className='icon-alert' />
                {(isLangEN) ? (
                  <p>The number of employees you input exceeds the specified quota. Please remove the employee or contact the <a href='https://api.whatsapp.com/send?phone=6282133336949' target='_blank' rel='noopener noreferrer'>Kerjoo team</a> for more information.</p>
                ) : (
                  <p>Jumlah karyawan yang Anda input melebihi kuota yang ditentukan. Silakan hapus karyawan atau hubungi <a href='https://api.whatsapp.com/send?phone=6282133336949' target='_blank' rel='noopener noreferrer'>tim Kerjoo</a> untuk informasi lebih lanjut.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
})
