import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { LangChanged, LangEmpty, LangNo, LangNotSetYet, LangPermitDeduction, LangYes } from '../../constant/languange'

function HistoryItemChangeFieldComponent ({ item, ...props }) {
  const itemValue = (value, children) => {
    // special case for permit component
    const payrollDeduction = [LangYes, LangNo]
    if (item.label === LangPermitDeduction) {
      return value == null || typeof value === 'string' || typeof value === 'number'
        ? <u>{payrollDeduction[value]}</u>
        : <span>{payrollDeduction[value]}</span>
    }

    // common
    return value == null || typeof value === 'string' || typeof value === 'number'
      ? <u>{children}</u>
      : <span>{children}</span>
  }

  return (
    <div>
      {item.value
        ? (
          <p className='mb-0'>
            {LangChanged} <b>{item?.label}: </b>
            {itemValue(
              item?.value?.before,
              <>
                {item.value
                  ? item?.value?.before !== undefined
                      // ? item?.value?.before ? typeof item?.value?.before === 'object' ? payrollDeduction[item?.value?.before?.is_exclude_deduction] : item?.value?.before : LangEmpty
                      ? item?.value?.before ?? LangEmpty
                      : LangNotSetYet
                  : LangNotSetYet}
              </>
            )}
            <span> <FontAwesomeIcon icon={faArrowRight} /> </span>
            {itemValue(
              item?.value?.after,
              <>
                {item.value
                  ? item?.value?.after !== undefined
                      // ? item?.value?.after ? typeof item?.value?.after === 'object' ? payrollDeduction[item?.value?.after?.is_exclude_deduction] : item?.value?.before : LangEmpty
                      ? item?.value?.after ?? LangEmpty
                      : LangNotSetYet
                  : LangNotSetYet}
              </>
            )}
          </p>
          )
        : null}
    </div>
  )
}

export default HistoryItemChangeFieldComponent
