import React, { useEffect, useState } from 'react'
import { GoogleMap, Marker, Circle, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { SearchLocation } from '../constant/languange'

const containerStyle = {
  width: '100%',
  height: '500px'
}

const libraries = [
  'places'
]

const Map = ({ ...props }) => {
  const [state, setState] = useState({
    markerPosition: {
      lng: props.lat ? Number(props.lng) : 106.845599,
      lat: props.lat ? Number(props.lat) : -6.2087634
    },
    places: '',
    address: ''
  })
  const [searchBox, setSearchBox] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.REACT_APP_APIKEY_GOOGLE}&loading=async`,
    libraries: libraries
  })

  useEffect(() => {
    onTrigger()
  }, [state])

  const onTrigger = () => {
    props.parentCallback(state)
  }

  const handleMarker = (event) => {
    const newLat = event.latLng.lat()
    const newLng = event.latLng.lng()
    setState({
      markerPosition: {
        lat: parseFloat(newLat),
        lng: parseFloat(newLng)
      }
    })
  }

  const onLoad = ref => {
    setSearchBox(ref)
  }

  const onPlacesChanged = (e) => {
    const tempat = searchBox.getPlaces()

    tempat.map(({ geometry: { location } }) => {
      const funcAddress = async () => {
        const lat = location.lat()
        const lng = location.lng()

        setState({
          markerPosition: {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
          }
        })
      }

      funcAddress()

      return true
    })
  }

  return (
    isLoaded ?
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={state.markerPosition}
        zoom={17}
      >

        <div style={{ position: 'absolute', top: 60, left: 10 }}>

          {props.searchLocation
            ? (
              <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>

                <input
                  type='text' placeholder={SearchLocation}
                  style={{
                    boxSizing: 'border-box',
                    border: '1px solid transparent',
                    width: '240px',
                    height: '32px',
                    padding: '0 12px',
                    borderRadius: '3px',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                    fontSize: '14px',
                    outline: 'none',
                    textOverflow: 'ellipses'
                  }}
                />

              </StandaloneSearchBox>
            )
            : (
              <div />
            )}

        </div>
        <Marker position={state.markerPosition} draggable={props.draggable} onDragEnd={handleMarker}>
          <Circle
            center={{ lat: Number(state.markerPosition.lat), lng: Number(state.markerPosition.lng) }}
            radius={Number(props.radius)} options={{ strokeColor: '#ff0000' }}
          />
        </Marker>
        <></>
      </GoogleMap>
      : <></>
  )
}

export default React.memo(Map)
