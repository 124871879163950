import React from 'react'
import { isLangEN } from '../../constant/languange'

const OrderAgreement = () => {
  return isLangEN
    ? (
      <p className='mt-3' style={{ fontSize: '11px' }}>
        By continuing, you agree to Kerjoo's <a href='https://kerjoo.com/syarat-ketentuan/' target='_blank' rel='noopener noreferrer'>Terms & Conditions of Service <i className='fa fa-external-link' /></a> and <a href='https://kerjoo.com/kebijakan-privasi/' target='_blank' rel='noopener noreferrer'>Privacy <i className='fa fa-external-link' /></a>.
      </p>
      )
    : (
      <p className='mt-3' style={{ fontSize: '11px' }}>
        Dengan melanjutkan, Anda menyetujui <a href='https://kerjoo.com/syarat-ketentuan/' target='_blank' rel='noopener noreferrer'>S&K Layanan <i className='fa fa-external-link' /></a> dan <a href='https://kerjoo.com/kebijakan-privasi/' target='_blank' rel='noopener noreferrer'>Privasi <i className='fa fa-external-link' /></a> Kerjoo.
      </p>
      )
}

export default OrderAgreement
