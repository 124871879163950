import { useEffect, useState } from 'react'
import { SpinnerButton, axiosCompany, loadPersonnelOptions } from '../../helpers/Common'
import { Close, LangChooseEmployee, Save, SpotAssignSpecificDivision, SpotAssignSpecificPersonnel } from '../../constant/languange'
import { AsyncSelectInput } from '../../helpers/Input'
import { useParams } from 'react-router-dom'

const ReimbursementAddEmployeeComponent = ({ handleClose, onAfterAdd, assignedData }) => {
  const params = useParams()
  // state
  const [control, setControl] = useState({ onSubmit: false, onLoad: false })
  const [form, setForm] = useState({
    personnel_id: [],
    group_id: []
  })
  const [responseData, setResponseData] = useState({})
  const [groupList, setGroupList] = useState([])

  useEffect(() => {
    getGroupList()
    setForm((currForm) => ({
      ...currForm,
      personnel_id: assignedData
    }))
    // eslint-disable-next-line
  }, [])


  // handler
  const handleSubmit = async (e) => {
    let submitForm = {
      group_id: form.group_id,
      personnel_id: form.personnel_id.map(a => a.value)
    }
    if (submitForm.group_id.length === 0) delete submitForm.group_id
    if (submitForm.personnel_id.length === 0) delete submitForm.personnel_id
    setControl(currControl => ({ ...currControl, onSubmit: true }))

    if (submitForm.group_id && submitForm.personnel_id) {
      await axiosCompany().post(`/reimbursements/${params.id}/personnels`, { group_id: submitForm.group_id })
        .then(res => {
          setResponseData(curResponseData => res.data)
        })
        .catch(err => {
          setResponseData(curResponseData => err.response.data)
        })
      await axiosCompany().post(`/reimbursements/${params.id}/personnels`, { personnel_id: submitForm.personnel_id })
        .then(res => {
          setResponseData(curResponseData => res.data)
          onAfterAdd()
        })
        .catch(err => {
          setResponseData(curResponseData => err.response.data)
        })
    } else {
      await axiosCompany().post(`/reimbursements/${params.id}/personnels`, submitForm)
        .then(res => {
          setResponseData(curResponseData => res.data)
          onAfterAdd()
        })
        .catch(err => {
          setResponseData(curResponseData => err.response.data)
        })
    }
    setControl(currControl => ({ ...currControl, onSubmit: false }))
  }

  const getGroupList = () => {
    setControl(currControl => ({ ...currControl, onLoad: true }))
    axiosCompany().get(`groups`)
      .then(res => {
        setControl(currControl => ({ ...currControl, onLoad: false }))
        setGroupList(res.data.data)
      })
  }

  const handleEmployeeChange = (name, payload) => {
    if (name === 'personnel_id') {
      setForm(currForm => ({ ...currForm, personnel_id: payload }))
    }
    if (name === 'group_id') {
      setForm(currForm => {
        const newGroupId = [...currForm.group_id];
        if (currForm.group_id.indexOf(payload) !== -1) {
          newGroupId.splice(newGroupId.indexOf(payload), 1)
        } else {
          newGroupId.push(payload)
        }
        return ({ ...currForm, group_id: newGroupId })
      })
    }
  }

  return (
    <form>
      <h5># {SpotAssignSpecificDivision}</h5>
      {
        control.onLoad && <div className='spinner-border text-primary' role='status'><span className='sr-only'>Loading...</span></div>
      }
      {
        !control.onLoad && groupList.length && groupList.map((item) => {
          return (
            <div key={item.id} className={`checkbox ml-2`}>
              <input type='checkbox' id={`checkbox${item.id}`} value={item.id} checked={form.group_id.indexOf(item.id) !== -1} onChange={() => handleEmployeeChange('group_id', item.id)} />
              <label htmlFor={`checkbox${item.id}`}>{item.name}</label>
            </div>
          )
        })
      }
      {responseData.errors?.group_id && (<div className='invalid-feedback d-block'>{responseData.errors?.group_id[0]}</div>)}

      <h5 className='mt-3'># {SpotAssignSpecificPersonnel}</h5>
      <AsyncSelectInput
        isMulti
        isLoading={false}
        isClearable
        placeholder={LangChooseEmployee}
        loadOptions={loadPersonnelOptions}
        value={form.personnel_id}
        onChange={(value) => handleEmployeeChange('personnel_id', value)}
      />
      {responseData.errors?.personnel_id && (<div className='invalid-feedback d-block'>{responseData.errors?.personnel_id[0]}</div>)}
      {/* error message */}
      <div className='form-group row'>
        <div className='col-12'>
          {responseData.message && (<div className='alert alert-danger my-2 w-100'>{responseData.message}</div>)}
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='offset-3 col-sm-9'>
          <div className='d-flex'>
            <button disabled={control.onSubmit} className='btn btn-primary mb-2 mr-2' type='button' onClick={handleSubmit}>{control.onSubmit && SpinnerButton} {Save}</button>
            <button className='btn btn-light mb-2' type='button' onClick={handleClose}>{Close}</button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ReimbursementAddEmployeeComponent
