import { LangCanceled, LangLeaveApproved, LangLeaveRejected, LangLeaveRequest, LangProcessed } from './languange'

export const statusName = {
  reject: LangLeaveRejected,
  approve: LangLeaveApproved,
  pending: LangLeaveRequest,
  cancel: LangCanceled,
  processed: LangProcessed
}

export const COOKIES_PERMISSION_APPROVAL = 'COOKIES_PERMISSION_APPROVAL'
