import React, { useEffect } from 'react'

import { connect, mapStateToProps, mapDispatchToProps, cookies, axiosCompany, checkIsCompleted } from '../helpers/Common'
import TourGuideIndicator from './components/TourGuideIndicator'

const NewTourProvider = ({ redux, setState }) => {
  // effect
  useEffect(() => {
    setState({ notFirstLogin: +cookies.get((`notFirstLogin-${redux.profile.id}`)) })
    setState({ isStopTour: +cookies.get((`isStopTour-${redux.profile.id}`)) })
    // eslint-disable-next-line
  }, [])

  // fetch data stats
  useEffect(() => {
    if (!redux.isStopTour && !redux.profile.supervisor_groups) {
      axiosCompany().get('/company/stats')
        .then((res) => {
          setState({ stats: res.data })
        })
    }
    // eslint-disable-next-line
  }, [redux.isUpdateStats])

  // menentukan fitur tour guide on atau off saat pertama login
  useEffect(() => {
    if (+cookies.get(`isStopTour-${redux.profile.id}`) || checkIsCompleted(redux.stats)) {
      if (cookies.get(`tourPage-${redux.profile.id}`) === 'close-tour' || cookies.get(`tourPage-${redux.profile.id}`) === undefined) {
        setState({ isStopTour: true })
        cookies.set(`isStopTour-${redux.profile.id}`, 1, { maxAge: (86400 * 365 * 5) })
      }
    } else {
      setState({ isStopTour: false })
      cookies.set(`isStopTour-${redux.profile.id}`, 0, { maxAge: (86400 * 365 * 5) })
    }
    // eslint-disable-next-line
  }, [redux.stats])

  // render welcome saat login
  useEffect(() => {
    // setState({ isUpdateStats: redux.isUpdateStats + 1 })
    if (!haveLoginBefore(redux.stats) && redux.notFirstLogin !== 1 && !checkIsCompleted(redux.stats)) {
      setState({ openIndicator: true })
    }
    // eslint-disable-next-line
  }, [redux.stats])

  // function
  const haveLoginBefore = (stats) => {
    if (stats) {
      const loginBefore = +stats.groups_count > 0 || +stats.work_and_shift_patterns_count > 0 || +stats.personnels_count > 0 || +stats.work_and_shift_times_count > 0 || +stats.spots_count > 0 || +stats.is_complete_profile > 0
      return loginBefore
    } else {
      return true
    }
  }

  return (
    <>
      {!redux.isStopTour && !redux.profile.supervisor_groups ? <TourGuideIndicator /> : ''}
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(NewTourProvider)
