import React, { lazy } from 'react'
import { Route } from 'react-router'
import PersonnelDeletedComponent from '../components/personnel/PersonnelDeletedComponent'

const PersonnelDetailComponent = lazy(() => import('../components/personnel/PersonnelDetailComponent'))
const PersonnelComponent = lazy(() => import('../components/personnel/PersonnelComponent'))
const PersonnelFormComponent = lazy(() => import('../components/personnel/PersonnelFormComponent'))
const PersonnelDocComponent = lazy(() => import('../components/personnel/PersonnelDocumentsComponent'))

const PersonnelRoute = () => {
  return (
    <>
      <Route exact path='/personnels' component={PersonnelComponent} />
      <Route path='/personnels/create' component={PersonnelFormComponent} />
      <Route path='/personnels/deleted' component={PersonnelDeletedComponent} />
      <Route exact path='/personnels/:id/document' component={PersonnelDocComponent} />
      <Route exact path='/personnels/:id/detail' component={PersonnelDetailComponent} />
      <Route exact path='/personnels/:id/edit' component={PersonnelFormComponent} />
    </>
  )
}

export default PersonnelRoute
