import { LangActive, LangAdded, LangAutomaticRenewalPeriod, LangDeleted, LangMonth, LangNo, LangRemainingLeave, LangYes } from '../../../../constant/languange'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const AutoReloadAnnualLeave = ({ redux, val }) => {
  return (
    <div className='table-responsive my-3 text-center'>
      <table className={`table table-sm ${redux.darkModeOn ? 'table-dark bg-dark' : ''}`}>
        <thead>
          <tr>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>{LangActive}</th>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>{LangAutomaticRenewalPeriod}</th>
            <th scope='col' className='text-center align-middle' style={{ minWidth: 150 }}>{LangRemainingLeave}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{val.enabled === 1 ? LangYes : LangNo}</td>
            <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{val.period_month} {LangMonth}</td>
            <td className={`${redux.darkModeOn ? 'border-secondary' : ''}`}>{val.previous_annual_leave === 'add' ? LangAdded : LangDeleted}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default connect(mapStateToProps)(AutoReloadAnnualLeave)
