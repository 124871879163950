import React, { useEffect, useState } from 'react'
import { DateChangeHistories, DescChangeHistories } from '../../constant/languange'
import { apiUrl, axiosCompany, displayDate, PaginationDiv, SpinnerLoadFirst, axios, moment } from '../../helpers/Common'
import { InputRangeDatePicker } from '../../helpers/Input'
import PersonnelHistoryItemComponent from './PersonnelHistoryItemComponent'

function PersonnelHistoryComponent ({ id, ...props }) {
  const [control, setControl] = useState({ showLoad: false })
  const [personnelId, setPersonnelId] = useState([])
  const [filter] = useState({
    page: 1,
    date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
    date_to: moment().format('YYYY-MM-DD')
  })

  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0
    }
  })

  useEffect(() => {
    getHistoryService()
    getPersonnelId()
    // eslint-disable-next-line
  }, [])

  const getHistoryService = () => {
    setControl({ ...control, showLoad: true })
    axiosCompany().get(`personnels/${id}/logs`, { params: filter }).then((ress) => {
      setData({ ...data, ...ress.data })
      setControl({ showLoad: false })
    }).catch(e => {
      setControl({ ...control, showLoad: false })
    })
  }

  const getPersonnelId = () => {
    axios.get(apiUrl + '/reference/personnel_identities')
      .then((result) => {
        setPersonnelId([...result.data])
      })
  }

  const handleDateChange = (value) => {
    filter.date_from = value.startDate
    filter.date_to = value.endDate
    getHistoryService()
  }

  const handlePage = (page) => {
    filter.page = page
    getHistoryService()
  }

  return (
    <>
      {
        control.showLoad
          ? (<div className=''>{SpinnerLoadFirst}</div>)
          : (
            <>
              <div className='mb-4'>
                <InputRangeDatePicker
                  startDate={filter.date_from}
                  endDate={filter.date_to}
                  onChange={handleDateChange}
                />
              </div>

              <div className='table-responsive'>
                <table className='table table-striped table-bordered'>
                  <thead>
                    <tr>
                      <th scope='col' className='border-top-0' style={{ minWidth: '160px' }}>{DateChangeHistories}</th>
                      <th scope='col' className='border-top-0' style={{ minWidth: '250px' }}>{DescChangeHistories}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                    data.data.map((item, i) => (
                      <tr key={i}>
                        <td scope='row'>{displayDate(item.created_at, 'DD MMM YYYY @ HH:mm')}</td>
                        <td><PersonnelHistoryItemComponent data={item} personnelId={personnelId} /></td>
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
              </div>
            </>
            )
      }
      <div className='px-3 my-3'>
        <PaginationDiv data={data} onChange={handlePage} />
      </div>
    </>
  )
}

export default PersonnelHistoryComponent
