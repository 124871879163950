import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { LangAlreadySent, LangExceptionReceiver, LangReceiver, LangSentAt, Name } from '../../constant/languange'
import { axiosCompany, connect, displayDate, mapStateToProps, PaginationDiv, ShowImage } from '../../helpers/Common'
import { SpinnerLoadFirst } from '../../helpers/Spinner'

function BroadcastReceiverComponent ({ redux, broadcastData = null, ...props }) {
  const reciverType = [LangAlreadySent, LangReceiver, LangExceptionReceiver]
  // const sentStatus = [{ color: 'info', name: cookies.get('lang') === 'EN' ? LangPaused : 'Tertunda' }, { color: 'success', name: LangSent }]
  const [activeTab, setActiveTab] = useState(0)
  const [control, setControl] = useState({ showLoad: false })
  const [filter] = useState({ page: 1 })
  const [data, setData] = useState({
    data: [],
    meta: {
      total: 0
    }
  })

  useEffect(() => {
    filter.page = 1
    // get list sent broadcast when active tab is 'already sent' in tab index 0 else get detail broadcast to get receiver & except receiver list
    activeTab !== 0 ? getDetailBroadcastsService() : getSentBroadcastsService()
    // eslint-disable-next-line
  }, [activeTab])

  const getDetailBroadcastsService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get(`broadcasts/${broadcastData.id}`).then((ress) => {
      setData(data => {
        data.data = activeTab === 1 ? ress.data.target_detail : ress.data.exclude_detail
        data.meta = { total: 1 }
        return { ...data }
      })
      setControl(control => ({ ...control, showLoad: false }))
    }).catch(e => {
      setControl(control => ({ ...control, showLoad: false }))
    })
  }

  const getSentBroadcastsService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get(`broadcasts/${broadcastData.id}/sents`, { params: filter }).then((ress) => {
      setData(data => {
        data = ress.data
        return { ...data }
      })
      setControl(control => ({ ...control, showLoad: false }))
    }).catch(e => {
      setControl(control => ({ ...control, showLoad: false }))
    })
  }

  const handlePage = (page) => {
    filter.page = page
    getSentBroadcastsService()
  }

  return (
    <>
      <Tabs
        className='mb-3'
        activeKey={activeTab}
        onSelect={(key) => {
          setActiveTab(+key)
        }}
      >
        {
          reciverType.map((item, i) => (
            <Tab id='tab-reciver' key={i} transition={false} eventKey={i} title={item}>
              {control.showLoad
                ? SpinnerLoadFirst
                : (
                  <div className='table-responsive' style={{ maxHeight: '60vh' }}>
                    <table className={`table table-striped table-bordered ${redux.darkModeOn ? 'bg-dark' : null}`}>
                      <thead>
                        <tr>
                          <th scope='col' style={{ minWidth: '150px' }}>{Name}</th>
                          {activeTab === 0 && (
                            <>
                              <th scope='col'>{LangSentAt}</th>
                              {/* <th scope='col' style={{ minWidth: '150px' }}>Status</th> */}
                            </>)}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.data.map((item, i) => {
                            const subData = item?.personnel ? item.personnel : item
                            return (
                              <tr key={i}>
                                <td>
                                  <div className='media align-items-center'>
                                    <ShowImage className='mr-3 img-30 rounded-circle' url={subData?.picture_url?.s ? subData?.picture_url?.s : null} defaultpictureonload={1} />
                                    <div className='media-body'>
                                      <h6 className='mb-0 font-weight-normal'>{subData.name}</h6>
                                      <p className='mb-0'>{subData?.position ? `${subData.position} | ` : ''}{subData?.group ? subData.group.name : ''}</p>
                                    </div>
                                  </div>
                                </td>
                                {activeTab === 0 && (
                                  <>
                                    <td>{item.sent_at && displayDate(item.sent_at, 'DD MMM YYYY @ HH:mm')}</td>
                                    {/* <td><h6 className='mb-0'><span className={`badge badge-${sentStatus[item?.received]?.color} font-weight-normal`}>{sentStatus[item?.received]?.name}</span></h6></td> */}
                                  </>
                                )}
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                  )}
              <div className='mt-3'>
                <PaginationDiv data={data} onChange={handlePage} />
              </div>
            </Tab>
          ))
        }
      </Tabs>
    </>
  )
}

export default connect(mapStateToProps)(BroadcastReceiverComponent)
