import { Female, LangBulkAction1, LangBulkAction2, LangBulkAction3, LangBulkAction4, LangBulkAction5, LangBulkAction6, Male } from './languange'

export const bulkActions = [
  { name: LangBulkAction1, val: 'annual_leave_set' },
  { name: LangBulkAction3, val: 'annual_leave_add' },
  { name: LangBulkAction2, val: 'annual_leave_subtract' },
  { name: LangBulkAction4, val: 'change_group' },
  { name: LangBulkAction6, val: 'send_verification_email' },
  { name: LangBulkAction5, val: 'mass_delete' }
]

export const massDeleteAction = {
  deleted_reason: ''
}

export const changeGroupAction = {
  change_group_value: ''
}

export const setAnunalLeaveAction = {
  set_value: '',
  set_expired_at: ''
}

export const addAnnualLeaveAction = {
  add_value: '',
  add_expired_month: ''
}

export const substractAnnualLeaveAction = {
  subtract_value: '',
  subtract_expired_month: ''

}

export const genderType = {
  male: Male,
  female: Female
}
