import React from 'react'
import { EmployeID, EmployeIdentityID, EmployeIDFile, Female, Gender, Group, LangChanged, LangCreatedBy, LangDeletedBy, LangEditedBy, LangEmployee, LangEmpty, LangFrom, LangLeaveExpiredAt, LangLeaveRemainingDay, LangPosition, LangTo, Male, Name, Password, PhoneNumber, ProfilePicture, StartWork, TimeZone } from '../../constant/languange'
import { displayDate } from '../../helpers/Common'

export const PersonnelHistoryItemComponent = ({ data, personnelId = [], ...props }) => {
  const {
    name = null,
    gender = null,
    group = null,
    position = null,
    leave_expired_at = null,
    leave_remaining_day = null,
    phone_no = null,
    picture = null,
    pid_file = null,
    pid = null,
    pid_identity_id = null,
    start_work = null,
    user = null
  } = data.data

  const genderName = (gender) => {
    return gender === 'male' ? Male : Female
  }

  const personnelIdName = (id) => {
    return personnelId.find(item => item.id === +id).name
  }

  return (
    <>
      {data.action_name === 'modify' && (
        <div>
          {name && (
            <p className='mb-0'>
              {LangChanged} <b>{Name}</b> {LangFrom.toLowerCase()} <u>{name?.before}</u> {LangTo.toLowerCase()} <u>{name?.after}</u>
            </p>
          )}
          {gender && (
            <p className='mb-0'>
              {LangChanged} <b>{Gender}</b> {LangFrom.toLowerCase()} <u>{genderName(gender?.before)}</u> {LangTo.toLowerCase()} <u>{genderName(gender?.after)}</u>
            </p>
          )}
          {phone_no && (
            <p className='mb-0'>
              {LangChanged} <b>{PhoneNumber}</b> {LangFrom.toLowerCase()} <u>{phone_no?.before}</u> {LangTo.toLowerCase()} <u>{phone_no?.after}</u>
            </p>
          )}
          {group && (
            <p className='mb-0'>
              {LangChanged} <b>{Group}</b> {LangFrom.toLowerCase()} <u>{group?.before ? group.before.name : LangEmpty}</u> {LangTo.toLowerCase()} <u>{group?.after ? group.after.name : LangEmpty}</u>
            </p>
          )}
          {position && (
            <p className='mb-0'>
              {LangChanged} <b>{LangPosition}</b> {LangFrom.toLowerCase()} <u>{position?.before ? position.before : LangEmpty}</u> {LangTo.toLowerCase()} <u>{position?.after ? position.after : LangEmpty}</u>
            </p>
          )}
          {user?.attr && (
            <p className='mb-0'>
              {LangChanged} <b>{TimeZone}</b> {LangFrom.toLowerCase()} <u>{user?.attr.before.timezone}</u> {LangTo.toLowerCase()} <u>{user?.attr.after.timezone}</u>
            </p>
          )}
          {pid_identity_id && (
            <p className='mb-0'>
              {LangChanged} <b>{EmployeIdentityID}</b> {LangFrom.toLowerCase()} <u>{pid_identity_id?.before ? personnelIdName(pid_identity_id?.before) : LangEmpty}</u> {LangTo.toLowerCase()} <u>{pid_identity_id?.after ? personnelIdName(pid_identity_id?.after) : LangEmpty}</u>
            </p>
          )}
          {pid && (
            <p className='mb-0'>
              {LangChanged} <b>{EmployeID}</b> {LangFrom.toLowerCase()} <u>{pid?.before ? pid.before : LangEmpty}</u> {LangTo.toLowerCase()} <u>{pid?.after ? pid.after : LangEmpty}</u>
            </p>
          )}
          {start_work && (
            <p className='mb-0'>
              {LangChanged} <b>{StartWork}</b> {LangFrom.toLowerCase()} <u>{start_work?.before ? displayDate(start_work.before, 'DD MMM YYYY') : LangEmpty}</u> {LangTo.toLowerCase()} <u>{start_work?.after ? displayDate(start_work.after, 'DD MMM YYYY') : LangEmpty}</u>
            </p>
          )}
          {leave_remaining_day && (
            <p className='mb-0'>
              {LangChanged} <b>{LangLeaveRemainingDay}</b> {LangFrom.toLowerCase()} <u>{leave_remaining_day?.before ? leave_remaining_day.before : LangEmpty}</u> {LangTo.toLowerCase()} <u>{leave_remaining_day?.after ? leave_remaining_day.after : LangEmpty}</u>
            </p>
          )}
          {leave_expired_at && (
            <p className='mb-0'>
              {LangChanged} <b>{LangLeaveExpiredAt}</b> {LangFrom.toLowerCase()} <u>{leave_expired_at?.before ? displayDate(leave_expired_at?.before, 'DD MMM YYYY') : LangEmpty}</u> {LangTo.toLowerCase()} <u>{leave_expired_at?.after ? displayDate(leave_expired_at?.after, 'DD MMM YYYY') : LangEmpty}</u>
            </p>
          )}
          {user?.email && (
            <p className='mb-0'>
              {LangChanged} <b>Email</b> {LangFrom.toLowerCase()} <u>{user?.email.before}</u> {LangTo.toLowerCase()} <u>{user?.email.after}</u>
            </p>
          )}
          {user?.password && (
            <p className='mb-0'>
              {LangChanged} <b>{Password}</b>
            </p>
          )}
          {picture && (
            <p className='mb-0'>
              {LangChanged} <b>{ProfilePicture}</b>
            </p>
          )}
          {pid_file && (
            <p className='mb-0'>
              {LangChanged} <b>{EmployeIDFile}</b>
            </p>
          )}
          <p className='mb-0'>{LangEditedBy} <i>{data?.by_user?.name}</i></p>
        </div>
      )}
      {data.action_name === 'add' && (
        <>
          <p className='mb-0'>{LangEmployee} <u>{name}</u></p>
          <p className='mb-0'>{LangCreatedBy} <i>{data?.by_user?.name}</i></p>
        </>
      )}
      {data.action_name === 'delete' && (
        <div>
          <p className='mb-0'>{LangEmployee} <u>{name}</u></p>
          <p className='mb-0'><span className='text-danger'>{LangDeletedBy}</span> <i>{data?.by_user?.name}</i></p>
        </div>
      )}
    </>
  )
}

export default PersonnelHistoryItemComponent
