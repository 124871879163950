import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { EditData, LangPrint } from '../../constant/languange'
import { axiosCompany, connect, cookies, getImageSrc, mapStateToProps, SpinnerButton, SpinnerLoadFirst } from '../../helpers/Common'
import { ModalApp } from '../../helpers/Input'
import ReactToPrint from 'react-to-print'
import EditInfoPrintComponent from './EditInfoPrintComponent'
import CalendarPaperComponent from './CalendarPaperComponent'

function CalendarPrintComponent ({ redux, ...props }) {
  const calenderComponentRef = useRef()

  const [control, setControl] = useState({ showLoad: false, showModal: '' })
  const [data, setData] = useState({})
  const [holiday, setHoliday] = useState([])
  const [currentYear, setCurrentYear] = useState(+cookies.get('calendar-year') || 2024) // change this state for another year calender
  const [currentHeaderBgId, setCurrentHeaderBgId] = useState(cookies.get('calendar-header-bg-id') || 'l2')
  const [uploadedHeaderBgUrl, setUploadedHeaderBgUrl] = useState('')

  // effect
  useEffect(() => {
    getDataService()
  }, [])

  useEffect(() => {
    getHolidays()
    // eslint-disable-next-line
  }, [currentYear])

  useEffect(() => {
    if (data?.image_header_url) {
      getUploadedHeaderImage(data.image_header_url.l)
    }
  }, [data])

  // handler
  const handleUpdateData = (updatedData, print = false) => {
    setControl(control => ({ ...control, showModal: '' }))
    setData(updatedData)
    print && window.print()
  }

  // function
  const getUploadedHeaderImage = async (url) => {
    const src = await getImageSrc(url)
    setUploadedHeaderBgUrl(src)
  }

  const getHolidays = () => {
    axiosCompany().get(`holidays?year=${currentYear}&page=null`)
      .then(res => {
        setHoliday(currHoliday => res.data)
      }
      )
  }

  const getDataService = async () => {
    setControl(control => ({ ...control, showLoad: true }))
    try {
      const res = await axiosCompany().get('company/calendar-setting')
      setData({ ...res.data })
      setControl(control => ({ ...control, showLoad: false }))
    } catch (e) {
      setControl(control => ({ ...control, showLoad: false }))
    }
  }

  return (
    <>
      <div className='container-fluid '>
        <div className='row  '>
          <div className='col-sm-12 '>
            <div className='row d-print-none'>
              <div className='col mt-3'>
                <div className='text-center'>
                  <ReactToPrint
                    trigger={() => <button disabled={control.showLoad} className='btn btn-primary my-3 ml-3 ml-sm-0'>{control.showLoad && SpinnerButton} {LangPrint}</button>}
                    content={() => calenderComponentRef.current}
                  />
                  <button disabled={control.showLoad} className={`btn ${redux.darkModeOn ? 'btn-dark' : 'btn-light'} btn-sm my-3 ml-3`} onClick={() => { setControl(control => ({ ...control, showModal: 'edit' })) }}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                </div>
              </div>
            </div>
            <div ref={calenderComponentRef}>
              {control.showLoad
                ? SpinnerLoadFirst
                : <CalendarPaperComponent holiday={holiday} currentYear={currentYear} currentHeaderBgId={currentHeaderBgId} uploadedHeaderBgUrl={uploadedHeaderBgUrl} control={control} data={data} />}
            </div>
          </div>
        </div>
      </div>

      {control.showModal === 'edit' && (
        <ModalApp
          title={EditData}
          handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
          body={
            <EditInfoPrintComponent
              handleClose={() => { setControl(control => ({ ...control, showModal: '' })) }}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
              currentHeaderBgId={currentHeaderBgId}
              setCurrentHeaderBgId={setCurrentHeaderBgId}
              uploadedHeaderBgUrl={uploadedHeaderBgUrl}
              initData={data}
              onSave={handleUpdateData}
              calenderComponentRef={calenderComponentRef}
            />
          }
        />
      )}

    </>
  )
}

export default connect(mapStateToProps)(CalendarPrintComponent)
