import React, { useEffect, useState } from 'react'
import { Close, LangExceptionReceiver, LangMessage, LangMessageTitle, LangNotificationMessage, LangReceiver, LangSave, LangSpesificReceiver, LangTitle, Required } from '../../constant/languange'
import { AsyncSelectInput, Form } from '../../helpers/Input'
import { connect, axiosCompany, loadDivisionOptions, loadPersonnelOptions, SpinnerButton, mapStateToProps } from '../../helpers/Common'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { categoryReciver } from '../../constant/BroadcastConstant'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Success } from '../../helpers/Message'
import htmlToDraft from 'html-to-draftjs'

function BroadcastFormComponent ({ redux, handleClose, onUpdate, editData = null, ...props }) {
  const initData = {
    title: '',
    message_title: '',
    message_title_en: '',
    message_content_draft: EditorState.createEmpty(),
    message_content_en_draft: EditorState.createEmpty(),
    message_content: '',
    message_content_en: '',
    message_content_notif: '',
    message_content_notif_en: '',
    target_type: '',
    target: null,
    target_id: [],
    except: null,
    except_id: []
  }
  const [data, setData] = useState(initData)
  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false })

  useEffect(() => {
    // editData ? setEditData(editData) : setData(initData)
    editData ? getBroadcastDetailService() : setData(initData)
    // eslint-disable-next-line
  }, [editData])

  const handleSubmit = (e) => {
    e.preventDefault()
    editData ? updateBroadcastService() : postBroadcastService()
  }

  const getBroadcastDetailService = () => {
    setControl(control => ({ ...control, showLoad: true }))
    axiosCompany().get(`broadcasts/${editData.id}`).then((ress) => {
      setEditData({ ...ress.data })
      setControl(control => ({ ...control, showLoad: false }))
    }).catch(e => {
      setControl(control => ({ ...control, showLoad: false }))
    })
  }

  const postBroadcastService = () => {
    setControl({ ...control, showLoad: true })
    formData()
    axiosCompany().post('broadcasts', data).then(ress => {
      setData(initData)
      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setTimeout(() => {
        setControl({ ...control, showSuccessAlert: false })
        onUpdate()
      }, 1500)
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const updateBroadcastService = () => {
    setControl({ ...control, showLoad: true })
    formData()
    axiosCompany().put(`broadcasts/${data.id}`, data).then(ress => {
      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
      setTimeout(() => {
        setControl({ ...control, showSuccessAlert: false })
        onUpdate()
      }, 1500)
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const setEditData = (editData) => {
    editData.message_title_en = editData.message_title_en || ''
    editData.message_content_en = editData.message_content_en || ''
    editData.message_content_notif_en = editData.message_content_notif_en || ''

    setData(data => {
      data = editData
      data.target = parseToOptions(data.target_detail)
      data.except = parseToOptions(data.exclude_detail)
      data.message_content_draft = data.message_content !== '' ? EditorState.createWithContent(parseToDraft(data.message_content)) : EditorState.createEmpty()
      data.message_content_en_draft = data.message_content_en !== '' ? EditorState.createWithContent(parseToDraft(data.message_content_en)) : EditorState.createEmpty()
      return { ...data }
    })
  }

  const formData = () => {
    data.target_id = data.target ? takeOptionsValue(data.target) : []
    data.except_id = data.except ? takeOptionsValue(data.except) : []
    data.message_content = data.message_content_draft.getCurrentContent().hasText() ? parseToHtml(data.message_content_draft) : ''
    data.message_content_en = data.message_content_en_draft.getCurrentContent().hasText() ? parseToHtml(data.message_content_en_draft) : ''
    delete data.message_content_draft
    delete data.message_content_en_draft
  }

  const parseToOptions = (options = []) => {
    return options.map((item, i) => {
      let label = item.name
      if (item.group) {
        label += ' - ' + item.group.name
      }
      return { label, value: item.id }
    })
  }

  const takeOptionsValue = (options = []) => {
    return options.map((item, i) => {
      return item.value
    })
  }

  const parseToDraft = (content) => {
    const contentBlock = htmlToDraft(content)
    if (contentBlock) {
      return ContentState.createFromBlockArray(contentBlock.contentBlocks)
    }
    return []
  }

  const parseToHtml = (content) => {
    return draftToHtml(convertToRaw(content.getCurrentContent()))
  }

  const handleCloseForm = () => {
    setError({ fields: [], message: '' })
    setControl({ ...control, showSuccessAlert: false, showLoad: false })
    handleClose()
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setData(data => {
      data[name] = value
      return { ...data }
    })
  }

  const handleReceiverSelect = (e) => {
    const { name, value } = e.target
    setData(data => {
      data[name] = value
      data.target = null
      data.except = null
      data.except_id = []
      data.target_id = []
      return { ...data }
    })
  }

  return (
    <form className='form' onSubmit={(e) => handleSubmit(e)}>
      <div className='row'>
        <div className='col-md'>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangTitle} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
            <div className='col-sm-9'>
              <Form.Control className={errors.fields.title ? 'is-invalid' : ''} name='title' value={data.title} onChange={(e) => { handleInputChange(e) }} />
              {errors.fields.title ? (<p style={{ color: 'red' }}>{errors.fields.title[0]}</p>) : (null)}
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangReceiver} <span style={{ color: 'red', fontSize: 20 }}>*</span></label>
            <div className='col-sm-9'>
              <Form.Control className={errors.fields.target_type ? 'is-invalid' : ''} value={data.target_type} name='target_type' as='select' onChange={(e) => { handleReceiverSelect(e) }}>
                <option value=''>{`-- ${LangReceiver} --`}</option>
                {
                  categoryReciver.filter((item) => {
                    return (!redux.profile.supervisor_groups || (redux.profile.supervisor_groups && item.value !== 'all'))
                  }).map((item, i) => (<option key={i} value={item.value}>{item.label}</option>))
                }
              </Form.Control>
              {errors.fields.target_type ? (<p style={{ color: 'red' }}>{errors.fields.target_type[0]}</p>) : (null)}
              {(data.target_type !== '') && (
                <>
                  {
                    (data.target_type !== 'all') && (
                      <div className='form-group row mt-3'>
                        <label className='col-sm-3 col-form-label'>{LangSpesificReceiver}</label>
                        <div className='col-sm-9'>
                          {
                            data.target_type === 'groups' && (
                              <AsyncSelectInput
                                isLoading={false}
                                isClearable
                                isMulti
                                loadOptions={loadDivisionOptions}
                                value={data.target}
                                onChange={(value) => { setData({ ...data, target: value }) }}
                              />
                            )
                          }
                          {
                            (data.target_type === 'personnels' || data.target_type === 'all') && (
                              <AsyncSelectInput
                                isLoading={false}
                                isClearable
                                isMulti
                                loadOptions={loadPersonnelOptions}
                                value={data.target}
                                onChange={(value) => { setData({ ...data, target: value }) }}
                              />
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                  {(data.target_type !== 'personnels') && (
                    <div className='form-group row mt-3'>
                      <label className='col-sm-3 col-form-label'>{LangExceptionReceiver}</label>
                      <div className='col-sm-9'>
                        {/* {
                        data.target_type === 'groups' && (
                          <AsyncSelectInput
                            isLoading={false}
                            isClearable
                            isMulti
                            loadOptions={loadDivisionOptions}
                            value={data.except}
                            onChange={(value) => { setData({ ...data, except: value }) }}
                          />
                        )
                      } */}
                        {
                          (data.target_type === 'groups' || data.target_type === 'all') && (
                            <AsyncSelectInput
                              isLoading={false}
                              isClearable
                              isMulti
                              loadOptions={loadPersonnelOptions}
                              value={data.except}
                              onChange={(value) => { setData({ ...data, except: value }) }}
                            />
                          )
                        }
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <hr />
          <br />
          <h5>{LangMessage}</h5>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangMessageTitle}<span style={{ color: 'red', fontSize: 20 }}>*</span></label>
            <div className='col-sm-9'>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-id mr-2' />
                <div className='flex-grow-1'>
                  <Form.Control className={errors.fields.message_title ? 'is-invalid' : ''} name='message_title' value={data.message_title} onChange={(e) => { handleInputChange(e) }} />
                  {errors.fields.message_title ? (<p style={{ color: 'red' }}>{errors.fields.message_title[0]}</p>) : (null)}
                </div>
              </div>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-us mr-2' />
                <div className='flex-grow-1'>
                  <Form.Control className={errors.fields.message_title_en ? 'is-invalid' : ''} name='message_title_en' value={data.message_title_en} onChange={(e) => { handleInputChange(e) }} />
                  {errors.fields.message_title_en ? (<p style={{ color: 'red' }}>{errors.fields.message_title_en[0]}</p>) : (null)}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangNotificationMessage}<span style={{ color: 'red', fontSize: 20 }}>*</span></label>
            <div className='col-sm-9'>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-id mr-2' />
                <div className='flex-grow-1'>
                  <Form.Control className={errors.fields.message_content_notif ? 'is-invalid' : ''} name='message_content_notif' value={data.message_content_notif} onChange={(e) => { handleInputChange(e) }} />
                  {errors.fields.message_content_notif ? (<p style={{ color: 'red' }}>{errors.fields.message_content_notif[0]}</p>) : (null)}
                </div>
              </div>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-us mr-2' />
                <div className='flex-grow-1'>
                  <Form.Control className={errors.fields.message_content_notif_en ? 'is-invalid' : ''} name='message_content_notif_en' value={data.message_content_notif_en} onChange={(e) => { handleInputChange(e) }} />
                  {errors.fields.message_content_notif_en ? (<p style={{ color: 'red' }}>{errors.fields.message_content_notif_en[0]}</p>) : (null)}
                </div>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-sm-3 col-form-label'>{LangMessage}<span style={{ color: 'red', fontSize: 20 }}>*</span></label>
            <div className='col-sm-9'>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-id mr-2' />
                <div className='flex-grow-1'>
                  <Editor
                    editorState={data.message_content_draft}
                    onEditorStateChange={(e) => { setData(data => ({ ...data, message_content_draft: e })) }}
                    editorClassName={`main-editor ${errors.fields.message_content ? 'editor-is-invalid' : ''}`}
                    toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                      link: { inDropdown: true },
                      inline: { inDropdown: true }
                    }}
                  />
                  {errors.fields.message_content ? (<p style={{ color: 'red' }}>{errors.fields.message_content[0]}</p>) : (null)}
                </div>
              </div>
              <div className='d-flex mb-2'>
                <i className='flag-icon flag-icon-us mr-2' />
                <div className='flex-grow-1'>
                  <Editor
                    editorState={data.message_content_en_draft}
                    onEditorStateChange={(e) => { setData(data => ({ ...data, message_content_en_draft: e })) }}
                    editorClassName={`main-editor ${errors.fields.message_content_en ? 'editor-is-invalid' : ''}`}
                    toolbar={{
                      options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'link', 'remove', 'history'],
                      link: { inDropdown: true },
                      inline: { inDropdown: true }
                    }}
                  />
                  {errors.fields.message_content_en ? (<p style={{ color: 'red' }}>{errors.fields.message_content_en[0]}</p>) : (null)}
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className='form-group row'>
            <div className='col-sm-12'>
              {control.showSuccessAlert && <Success />}
              {(errors.message !== '') ? <div className='alert alert-danger'> <p style={{ fontSize: 18, textAlign: 'center' }}>{errors.message}</p></div> : null}
              <p style={{ color: 'red', fontSize: 15 }}>*{Required}</p>
              <button disabled={control.showLoad} className='btn btn-primary mr-2' type='submit'>{(control.showLoad) ? SpinnerButton : ''} {LangSave}</button>
              <button disabled={control.showLoad} className='btn btn-light' type='button' onClick={() => handleCloseForm()}>{Close}</button>
            </div>
          </div>

        </div>
      </div>
    </form>
  )
}

export default connect(mapStateToProps)(BroadcastFormComponent)
