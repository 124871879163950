import { AttendanceDays, LangTotalLeaveDays, LangTotalNotClockInDays, LangTotalOvertimeHoursHolidayCalendar, LangTotalOvertimeHoursSaturday, LangTotalOvertimeHoursSunday, LangTotalOvertimeHoursWeekday, LangTotalPermitDays, LangTotalUnfullfiledLeaveHours, LangTotalWorkDaySchedule, WorkHours, Hours, Day, LangMonth, LangWorkHoursNotFulfilled2, isLangEN } from './languange'

export const salaryRuleInfo = isLangEN
  ? `THJ = total work schedule days
TJK = total worked hours
THK = total days of attendance
THC = total days off
THA = total days absent
TJLN = total overtime hours on normal days
TJLS = total overtime hours Saturday
TJLM = total overtime hours on Sunday
TJLH = total overtime hours on holiday calendar
TJI = total hours of leave hours have not been replaced
TD = total late penalty
TP = total deduction (that is set on the interface)

GP = hourly salary (Rp)
GLN = overtime salary per hour on weekdays (Rp)
GLS = overtime salary per hour on Saturday (Rp)
GLM = overtime salary per hour on Sunday (Rp)
GLH = overtime salary per hour on holiday calendar (Rp)
GH = salary per day (Rp)
GM = salary per month (Rp)
PI = deduction leave per hour (Rp)

B = total bonus (Rp)
PN = overtime income (TJLN x GLN) + (TJLS x GLS) + (TJLM x GLM) + (TJLH x GLH)

If the employee's salary is hourly, then:
(GP x TJK) + PN + B - ((PI x TJI) + TD + TP)

If the employee's salary per day:
(GH x (THK + THC)) + PN + B - ((PI x TJI) + TD + TP)

If employee salary per month:
(((THK + THC) / THJ) x GM) + PN + B - ((PI x TJI) + TD + TP)`
  : `THJ = total hari jadwal kerja
TJK = total jam kerja
THK = total hari kehadiran dimana terdapat presensi masuk
THC = total hari cuti
THA = total hari tidak masuk
TJLN = total jam lembur hari biasa
TJLS = total jam lembur sabtu
TJLM = total jam lembur minggu
TJLH = total jam lembur kelender libur
TJI = total jam izin jam belum diganti
TD = total denda keterlambatan
TP = total potongan (yang diatur via interface)

GP = gaji per jam (Rp)
GLN = gaji per jam lembur hari biasa (Rp)
GLS = gaji per jam lembur sabtu (Rp)
GLM = gaji per jam lembur minggu (Rp)
GLH = gaji per jam lembur kalender libur (Rp)
GH = gaji per hari (Rp)
GM = gaji per bulan (Rp)
PI = potongan izin jam (Rp)

B = total bonus (Rp)
PN = pendapatan lembur (TJLN x GLN) + (TJLS x GLS) + (TJLM x GLM) + (TJLH x GLH)

Jika gaji karyawan per jam, maka:
(GP x TJK) + PN + B - ((PI x TJI) + TD + TP)

Jika gaji karyawan per hari:
(GH x (THK + THC)) + PN + B - ((PI x TJI) + TD + TP)

Jika gaji karyawan per bulan:
(((THK + THC) / THJ) x GM) + PN + B - ((PI x TJI) + TD + TP)
`

export const getWorktimeList = (item) => ([
  {
    label: WorkHours,
    value: item.total_work_hours
  },
  {
    label: LangTotalUnfullfiledLeaveHours,
    value: item.total_unfulfilled_leave_in_hour,
    dates_index: 'unfulfilled_leave_in_hour_dates',
    type: 'hour'
  },
  {
    label: LangTotalOvertimeHoursWeekday,
    value: item.total_overtime_normal_day_hours,
    dates_index: 'overtime_normal_day_dates',
    type: 'hour'
  },
  {
    label: LangTotalOvertimeHoursSaturday,
    value: item.total_overtime_saturday_hours,
    dates_index: 'overtime_saturday_dates',
    type: 'hour'
  },
  {
    label: LangTotalOvertimeHoursSunday,
    value: item.total_overtime_sunday_hours,
    dates_index: 'overtime_sunday_dates',
    type: 'hour'
  },
  {
    label: LangTotalOvertimeHoursHolidayCalendar,
    value: item.total_overtime_holiday_hours,
    dates_index: 'overtime_holiday_dates',
    type: 'hour'
  },
  {
    label: AttendanceDays,
    value: item.total_work_days,
    dates_index: 'work_dates'
  },
  {
    label: LangTotalNotClockInDays,
    value: item.total_not_present_days,
    dates_index: 'not_present_dates'
  },
  {
    label: LangTotalWorkDaySchedule,
    value: item.total_schedule_days,
    dates_index: 'schedule_dates'
  },
  {
    label: LangTotalLeaveDays,
    value: item.total_leave_days,
    dates_index: 'leave_dates'
  },
  {
    label: LangTotalPermitDays,
    value: item.total_permit_days,
    dates_index: 'permit_dates'
  },
  {
    label: LangWorkHoursNotFulfilled2,
    value: item.total_uncompleted_worktime_hours,
    dates_index: 'total_uncompleted_worktime_days',
    type: 'hour'
  }
])

export const salaryType = [Hours, Day, LangMonth]
export const overtimeType = [Hours, Day]
