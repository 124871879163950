import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Close, Division, LangEmployee, LangFilterGroup, LangProcess, LangTargetCount } from '../../constant/languange'
import { axiosCompany, axiosDownloadFile, mapStateToProps, SpinnerButton } from '../../helpers/Common'

function PersonnelExportComponent ({ redux, onClose, ...props }) {
  const [data, setData] = useState({ group_id: '', target_count: 0 })
  const [errors, setError] = useState({ fields: [], message: '' })
  const [control, setControl] = useState({ showSuccessAlert: false, showLoad: false })

  useEffect(() => {
    getPersonnelsService()
    // eslint-disable-next-line
  }, [])

  const getExportService = () => {
    setControl({ ...control, showLoad: true })
    axiosCompany().post('personnels/export', data, { responseType: 'blob' }).then(ress => {
      const nameFile = `personnel-${data.group_id !== '' ? redux.groups.find((item) => +item.id === +data.group_id).name.toLowerCase() : 'all'}`
      axiosDownloadFile(ress.data, nameFile, 'xlsx')

      setError({ fields: [], message: '' })
      setControl({ ...control, showSuccessAlert: true, showLoad: false })
    }).catch(e => {
      setError(error => {
        error.fields = e.response.data.errors ? e.response.data.errors : { 0: [e.response.statusText] }
        error.message = e.response.data.message ? e.response.data.message : ''
        return { ...error }
      })
      setControl({ ...control, showLoad: false })
    })
  }

  const getPersonnelsService = () => {
    setControl({ ...control, showLoad: true })
    axiosCompany().get('personnels?', { params: { group_id: data.group_id } }).then((ress) => {
      setData(data => {
        data.target_count = ress.data?.meta?.total
        return { ...data }
      })
      setControl({ ...control, showLoad: false })
    }).catch(e => {
      setControl({ ...control, showLoad: false })
    })
  }

  const handleSelectChange = (e) => {
    setData({ ...data, group_id: e.target.value })
    data.group_id = e.target.value
    getPersonnelsService()
  }

  const handleExport = () => {
    getExportService()
  }

  return (
    <>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangFilterGroup}</label>
        <div className='col-sm-5'>
          <select className='form-control' value={data.group_id} onChange={(e) => { handleSelectChange(e) }}>
            <option value=''>--{Division}--</option>
            {redux.groups.map((obj) => {
              return (
                <option key={obj.id} value={obj.id}>{obj.name}</option>
              )
            })}
          </select>
        </div>
      </div>
      <div className='form-group row'>
        <label className='col-sm-3 col-form-label'>{LangTargetCount}</label>
        <div className='col-sm-9 pt-1'>
          {data.target_count} {LangEmployee}
        </div>
      </div>
      <hr />
      <div className='form-group row'>
        <div className='col-9 offset-3'>
          {errors.message ? <div className='alert alert-danger'> <p style={{ fontSize: 18, textAlign: 'center' }}>{errors.message}</p></div> : null}

          <button disabled={control.showLoad} className='btn  btn-primary mr-2' onClick={handleExport}> {(control.showLoad) ? SpinnerButton : ''} {LangProcess}</button>
          <button disabled={control.showLoad} className='btn  btn-light' type='button' onClick={() => { onClose() }}>
            {Close}
          </button>

        </div>
      </div>
    </>

  )
}

export default connect(mapStateToProps)(PersonnelExportComponent)
