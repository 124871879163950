import React from 'react'
import ReactDOM from 'react-dom'

const CustomModal = ({ content }) => {
  document.body.style.overflow = 'hidden'

  const modal = (
    <div style={{ inset: '0', zIndex: '10', background: '#23232366' }} className='position-fixed'>
      <div style={{ height: '100vh', overflow: 'auto' }} className='d-flex justify-content-center align-items-start p-2'>
        <div className='my-5 w-100'>{content}</div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(modal, document.getElementById('modal-custom-hook'))
}

export default CustomModal
