import React, { useRef, useEffect } from 'react'
import {
  cookies,
  connect,
  mapStateToProps,
  myStorage,
  moment,
  axiosCompany
} from '../helpers/Common'

/**
 * untuk cek apakah ada aktivitas dalam sekian menit
 * jika tidak ada, maka dilogoutkan
 */
export default connect(mapStateToProps)(({ redux }) => {
  const periodicCheckTime = useRef
  const storeKey = 'lastTimeCheck'
  const timeoutMinute = 60
  const momentFormat = 'YYYY-MM-DD HH:mm:ss'

  useEffect(() => {
    const unsubscribe = () => {
      clearInterval(periodicCheckTime.current)
      myStorage.removeItem(storeKey)
    }

    const removeCookies = () => {
      cookies.remove('annual_label_id')
      cookies.remove('annual_label_en')
      cookies.remove('token')
    }

    const periodicCheck = () => {
      periodicCheckTime.current = setInterval(() => {
        /**
         * bandingkan
         */
        if (moment(myStorage.getItem(storeKey), momentFormat).add(timeoutMinute, 'm').isBefore()) {
          unsubscribe()

          axiosCompany().post('auth/logout').then(() => {
            removeCookies()
            window.location.reload()
          })
        }
      }, 60000)
    }

    const myListener = () => {
      clearInterval(periodicCheckTime.current)
      myStorage.setItem(storeKey, moment().format(momentFormat))

      periodicCheck()
    }

    /**
     * jika posisi login
     */
    if (redux.authed) {
      /**
       * register listener
       */
      ['click', 'load', 'scroll'].forEach((event) => {
        window.addEventListener(event, myListener)
      })

      /**
       * untuk pertama kali
       */
      myListener()
    } else {
      unsubscribe()
    }

    return () => {
      unsubscribe()
    }
  }, [redux.authed, periodicCheckTime])

  return <></>
})
