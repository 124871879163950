import React, { lazy } from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import { mapStateToProps } from '../../helpers/Common'

const AttendanceApprovalPage = lazy(() => import('../pages/AttendanceApprovalPage'))
const ManageUserPage = lazy(() => import('../pages/ManageUserPage'))
const LandingPage = lazy(() => import('../pages/LandingPage'))
const EditUserPage = lazy(() => import('../pages/EditUserPage'))
const AddUserPage = lazy(() => import('../pages/AddUserPage'))
const AttendanceSyncDataPage = lazy(() => import('../pages/AttendanceSyncDataPage'))

const PintuKerjooRoutes = ({ redux }) => {
  // if (typeof redux.addons === 'object' && redux.addons !== null && 'pintu_kerjoo' in redux.addons) {
  if (redux.enableFeaturePintuKerjoo) {
    return (
      <>
        <Route exact path='/pintu-kerjoo/manage-users' component={ManageUserPage} />
        <Route exact path='/pintu-kerjoo/manage-users/edit' component={EditUserPage} />
        <Route exact path='/pintu-kerjoo/manage-users/add' component={AddUserPage} />
        <Route exact path='/pintu-kerjoo/attendance-approval' component={AttendanceApprovalPage} />
        <Route exact path='/pintu-kerjoo/attendance-sync-data' component={AttendanceSyncDataPage} />
      </>
    )
  } else {
    return (
      <>
        <Route exact path='/pintu-kerjoo' component={LandingPage} />
        <Route path='/pintu-kerjoo/*' component={LandingPage} />
      </>
    )
  }

}

export default connect(mapStateToProps)(PintuKerjooRoutes)
