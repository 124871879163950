export class ConfigDB {
	static data = {
	  settings: {
	    layout_type: 'ltr',
	    sidebar: {
	      type: 'compact-wrapper',
	      body_type: 'sidebar-icon'
	    },
	    sidebar_setting: 'default-sidebar'
	  },
	  color: {
	    layout_version: 'light',
	    color: 'color-1',
	    primary_color: '#7366ff',
	    secondary_color: '#f73164',
	    mix_background_layout: 'light-only'
	  },
	  router_animation: 'fadeIn'
	}
}
export default ConfigDB
