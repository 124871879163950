import React from 'react'
import { LangSteps31 } from '../../../constant/languange'
import Content from '../Content'

const Third1 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps31],
    button: 'Mulai Tambah Karyawan',
    nextStep: 4,
    skipTo: '/personnel/work/time',
    isDone: props.stats.personnels_count
  }

  return <Content {...props} data={data} />
}

export default Third1
