import React from 'react'
import { LangSteps42 } from '../../../constant/languange'
import Content from '../Content'

const Fourth2 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps42],
    button: 'Mulai Atur Jadwal Kerja',
    nextStep: 5,
    skipTo: '/attendance/spot',
    isDone: props.stats.work_and_shift_times_count
  }

  return <Content {...props} data={data} />
}

export default Fourth2
