import React from 'react'
import { LangSteps22 } from '../../../constant/languange'
import Content from '../Content'

const Second2 = (props) => {
  const data = {
    title: '',
    steps: [LangSteps22],
    button: 'Mulai Atur Pola Kerja',
    nextStep: 3,
    skipTo: '/personnels',
    isDone: props.stats.work_and_shift_patterns_count
  }

  return <Content {...props} data={data} />
}

export default Second2
