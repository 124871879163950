import ReactHtmlParser from 'react-html-parser'
import { connect, mapStateToProps } from '../../../../helpers/Common'

const Message = ({ redux, val }) => {
  return (
    <div className={`alert ${redux.darkModeOn ? 'alert-dark' : 'alert-light'} my-2`}>
      {val ? ReactHtmlParser(val) : ''}
    </div>
  )
}

export default connect(mapStateToProps)(Message)
