import React from 'react'
import BroadcastComponent from '../components/broadcast/BroadcastComponent'

function Broadcast () {
  return (
    <>
      <BroadcastComponent />
    </>
  )
}

export default Broadcast
