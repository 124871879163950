import React, { useState, useEffect } from 'react'
import {
  connect,
  mapStateToProps
} from '../../helpers/Common'

export default connect(mapStateToProps)(({ redux }) => {
  const [count, setCount] = useState(0)

  useEffect(() => {
    if (Object.keys(redux.notifications).length) {
      setCount(redux.notifications.unlock_limit_attenance_request + redux.notifications.attendance_review + redux.notifications.attendance_edit_request + (redux.notifications.whitelist_device ?? 0) + (redux.notifications.pintu_kerjoo_sync_error ?? 0) + (redux.notifications.update_profile_picture ?? 0))
    }
    // eslint-disable-next-line
  }, [redux.notifications.unlock_limit_attenance_request, redux.notifications.attendance_review, redux.notifications.attendance_edit_request, redux.notifications.whitelist_device, redux.notifications.pintu_kerjoo_sync_error, redux.notifications.update_profile_picture])

  if (count <= 0) return <></>

  return (
    <label className='badge badge-warning position-static m-0 py-1' style={{ fontSize: '9px' }}>{count}</label>
  )
})
