import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { LangRosaFromKerjoo } from '../../constant/languange'
import botImage from '../../assets/images/tour-guide/bot_kerjoo.jpg'

const Content = (props) => {
  return (
    <div style={{ minWidth: '300px' }}>
      <div>
        <div style={{ margin: '10px 0' }} className='d-flex align-items-center px-3'>
          <img style={{ width: 35 }} className='rounded-circle mr-2' src={botImage} alt='bot kerjoo' />
          {LangRosaFromKerjoo}
        </div>
        <hr className='m-0' />
      </div>
      <div className='p-3'>
        <h6 className='text-primary m-0'>{props.data.title}</h6>
        {props.data.steps.length === 1
          ? (<p className='pr-3 m-0'>{props.data.steps[0]}</p>)
          : (
            <ul className='pr-3 m-0' style={{ listStyleType: 'disc' }}>
              {props.data.steps.map((step, i) => <li key={i}>{step}</li>)}
            </ul>)}
        {props.data.isDone > 0 &&
          (
            <div className='d-flex flex-column my-4 justify-content-center align-items-center text-success'>
              <FontAwesomeIcon size='5x' icon={faCheckCircle} />
            </div>
          )}
        {/* <div className='d-flex justify-content-end mt-3'>
        <a type='button' className='btn btn-sm btn-primary' style={{ borderRadius: '4px' }} onClick={() => props.closeTour()}>Ok</a>
      </div> */}
      </div>
    </div>
  )
}

export default Content
