import React from 'react'
import Content from './Content'
import { LangTitleTour5 } from '../../constant/languange'

const Fifth = (props) => {
  const data = {
    title: LangTitleTour5,
    steps: [
      <>Klik menu Kelola Karyawan &rarr; Lokasi Kehadiran</>,
      'Klik Tambah Lokasi & tentukan karyawan yang ditugaskan setelah lokasi ditambahkan.'
    ],
    button: 'Mulai Atur Lokasi Kehadiran',
    nextStep: 6,
    skipTo: '/company/setting',
    isDone: props.stats.spots_count
  }

  return <Content {...props} data={data} />
}

export default Fifth
