import React, { lazy } from 'react'
import { Route } from 'react-router'

const GroupComponent = lazy(() => import('../components/group/GroupComponent'))
const GroupLatePunsihmentComponent = lazy(() => import('../components/group/GroupLatePunishmentListComponent'))

const GroupRoute = () => {
  return (
    <>
      <Route exact path='/manage/group' component={GroupComponent} />
      <Route exact path='/manage/group/:id/punishment' component={GroupLatePunsihmentComponent} />
    </>
  )
}

export default GroupRoute
