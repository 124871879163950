import React from 'react'
import Content from './Content'
import { LangTitleTour2} from '../../constant/languange'

const Second3 = (props) => {
  const data = {
    title: LangTitleTour2,
    steps: [
      <>Klik menu Pola Kerja &rarr; Hari Karja untuk pola kerja</>,
      'Tambah Pola Kerja',
      'Klik menu Shift untuk pola kerja karyawan shift',
      'Tambah Pola Kerja'
    ],
    button: 'Mulai Atur Pola Kerja',
    nextStep: 3,
    skipTo: '/personnels',
    isDone: props.stats.work_and_shift_patterns_count
  }

  return <Content {...props} data={data} />
}

export default Second3
