import React, { lazy } from 'react'
import { Route } from 'react-router'

const PositionComponent = lazy(() => import('../components/position/PositionComponent'))
const PositionVisualizationComponent = lazy(() => import('../components/position/PositionVisualizationComponent'))
const PositionPrintComponent = lazy(() => import('../components/position/PositionPrintComponent'))

const PositionRoute = () => {
  return (
    <>
      <Route exact path='/manage/position' component={PositionComponent} />
      <Route exact path='/manage/position/visualization' component={PositionVisualizationComponent} />
      <Route exact path='/manage/position/visualization/print' component={PositionPrintComponent} />
    </>
  )
}

export default PositionRoute
