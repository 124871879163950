import { LangAdditionalFamilyMembers, LangBPJSKesehatanNumber, LangBPJSKetenagakerjaanNumber, LangEmpty, LangFamilyMembers, LangParticipationDate, LangRegisteredSalary } from '../../../../constant/languange'
import { connect, mapStateToProps, toRupiah } from '../../../../helpers/Common'

const BPJSConfig = ({ redux, val }) => {
  return (
    <div className='border p-3'>
      <div className='font-weight-bold'>BPJS Ketenagakerjaan</div>
      <ol className='d-flex flex-column mb-0 font-weight-bold' type='a'>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangParticipationDate}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_tk_date ? val.bpjs_tk_date : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangBPJSKetenagakerjaanNumber}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_tk_number ? val.bpjs_tk_number : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangRegisteredSalary}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_tk_wages ? `Rp${toRupiah(val?.bpjs_tk_wages)}` : LangEmpty}
            </div>
          </div>
        </li>
      </ol>
      <div className='font-weight-bold mt-2'>BPJS Kesehatan</div>
      <ol className='d-flex flex-column mb-0 font-weight-bold' type='a'>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangParticipationDate}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_kesehatan_date ? val.bpjs_kesehatan_date : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangBPJSKesehatanNumber}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_kesehatan_number ? val.bpjs_kesehatan_number : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangRegisteredSalary}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_kesehatan_wages ? `Rp${toRupiah(val?.bpjs_kesehatan_wages)}` : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangFamilyMembers}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_kesehatan_family_member ? val?.bpjs_kesehatan_family_member : LangEmpty}
            </div>
          </div>
        </li>
        <li>
          <div className='row form-group d-flex align-items-center mb-0'>
            <label className='col-sm-5 col-form-label'>{LangAdditionalFamilyMembers}: </label>
            <div className='col-sm-6 font-weight-normal'>
              {val.bpjs_kesehatan_additional_family ? val?.bpjs_kesehatan_additional_family : LangEmpty}
            </div>
          </div>
        </li>
      </ol>
    </div>
  )
}

export default connect(mapStateToProps)(BPJSConfig)
